import { emitter } from '@utils/global-events'


export default function requestErrorHandler(error) {
  if (error?.response?.data?.message) {
    emitter.emit('showGlobalAlert', {
      message: error.response.data.message,
      type: 'error',
    })
  }
}
