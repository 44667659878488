<script>
import {
  ChecklistStepStatus,
  ChecklistStepType,
  FileExportFormat,
  DocumentType,
} from '@enums'

import CreateDocumentContent from '@components/checklist/create-document-content'
import FillRepoDataContent from '@components/checklist/fill-repo-data-content'

import {
  debugGetters,
} from '@state/helpers'
import { Document } from '@state/models/document'
import DocumentBlueprint from '@state/models/document-blueprint'
import FolderLink from '@state/models/folder-link'

import replaceBrand from '@utils/brand-placeholder-filter'


export default {
  name: 'FolderChecklistStep',
  components: {
    CreateDocumentContent,
    FillRepoDataContent,
  },
  props: {
    status: Object,
    editable: Boolean,
    optional: Boolean,
    lastStep: Boolean,
    active: Boolean,
    value: Number,
  },
  emits: ['finished'],
  data() {
    return {
      ChecklistStepStatus,
      ChecklistStepType,
    }
  },
  computed: {
    ...debugGetters,
    currentLocale() {
      return this.$store.getters['auth/currentLocale']
    },
    currentFolder() {
      return this.$store.getters['files/currentFolder']
    },
    currentFolderDocumentsFast() {
      const folderLinkDocumentIds = FolderLink.allFast()
        .filter((fl) => fl.folder_id === this.currentFolder.id)
        .map((fl) => fl.document_id)

      return Document.allFast()
        .filter((d) => folderLinkDocumentIds.includes(d.id))
        .map(d => ({
          ...d,
          document_blueprint: DocumentBlueprint.allFast().find(db => db.id === d.document_blueprint_id)
        }))
    },
    document() {
      const d = this.currentFolderDocumentsFast.find(
        (d) =>
          d.document_blueprint.uuid ===
          this.status.checklist_step.data['document_blueprint_uuid']
      )
      if (d) {
        return Document
          .query()
          .whereId(d.id)
          .with('document_blueprint')
          .with('repository_edit_document_version')
          .with('document_blueprint.current_blueprint_version')
          .first()
      }
    },
    stepDescription() {
      return replaceBrand(this.status.checklist_step.description_translations[this.currentLocale])
    },
  },
  watch: {
    active(v, ov) {
      if (v && !ov) {
        if (!this.document) {
          console.warn('Document not found')
        } else {
          this.document.getCurrentProgress()
        }
      }
    },
  },
  mounted() {
  },
  methods: {
    getCurrentStepContent(type) {
      switch (type) {
        case ChecklistStepType.QUESTION:
          return FillRepoDataContent

        case ChecklistStepType.DOCUMENT_CREATED:
          return CreateDocumentContent

        case ChecklistStepType.DOCUMENT_FINISHED:
        case ChecklistStepType.TRAINING_FINISHED:
        case ChecklistStepType.GROUP:
        case ChecklistStepType.TASK:
        case ChecklistStepType.GUIDE:
        case ChecklistStepType.HEADLINE:
        case ChecklistStepType.LINK:
        case ChecklistStepType.USER_DATA:
        case ChecklistStepType.COMPANY_PERSON_SET:
          return 'Not implemented yet'

        default:
          return 'Missing component for step type'

      }
    },
    setStatus({ checklistStepStatus, skipEmitTaskFinish = false, fromManualButtonClick = false }) {
      if (
        checklistStepStatus === ChecklistStepStatus.FINISHED
        && this.status.checklist_step.data?.finish_task_on_step_finish
        && !fromManualButtonClick
      ) {
        // manual click required to finish task
        return
      }

      if (this.status.status !== checklistStepStatus) {
        this.status.sync(null, { status: checklistStepStatus })

        if (checklistStepStatus === ChecklistStepStatus.FINISHED && this.document.document_type === DocumentType.DOCUMENT) {

          let exportOptions = {
            fileType: FileExportFormat.PDF,
            saveToPrimaryFolder: true,
            exportSync: true
          }

          if (this.document.document_blueprint?.current_blueprint_version?.data?.preselected_export_options?.length > 0){
            exportOptions = {
              ...exportOptions,
              ...this.document.document_blueprint.current_blueprint_version.data.preselected_export_options
            }
          }

          this.document.createExport(exportOptions).then(async () => {
            await this.$store.getters['files/currentFolder'].refreshContent(
              this.$store.getters['navbar/selectedCompany'],
              false,
              ['File'],
            )
          })
        }
      }

      if (!skipEmitTaskFinish && checklistStepStatus === ChecklistStepStatus.FINISHED) {
        const finishTask = this.status.checklist_step.data?.finish_task_on_step_finish || false
        this.$emit('finished', { finishTask })
      }
    },
  },
}
</script>

<template>
  <v-stepper-vertical-item
    :complete="status.status === ChecklistStepStatus.FINISHED"
    :editable="editable"
    :title="status.checklist_step.name_translations[currentLocale]"
    :subtitle="debug ? 'progress:' + document?.current_progress  : null"
    :value="value"
    edit-icon="fas fa-check"
  >
    <div class="white-space-pre-line">
      {{ stepDescription }}
    </div>
    <component
      :is="getCurrentStepContent(status.checklist_step.type)"
      :status="status"
      :editable="editable"
      :document="document"
      @set-status="setStatus"
    />
    <template #next>
      <div class="d-flex justify-end mt-4 pb-4">
        <PlanitButton
          color="primary"
          :disabled="
            status.status !== ChecklistStepStatus.FINISHED &&
            !status.checklist_step.optional
          "
          :loading="$store.getters['syncQueue/isLoading']"
          @click="setStatus({ checklistStepStatus: ChecklistStepStatus.FINISHED, fromManualButtonClick: true })"
        >
          {{ lastStep ? $t('components.checklist.finish') : $t('components.checklist.continue') }}
        </PlanitButton>
      </div>
    </template>
  </v-stepper-vertical-item>
</template>
