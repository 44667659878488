import PlanitModel from './planit-model'
// import User from './user'
import store from '@state/store'

import { app } from '@src/main'

export default class Address extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),
      street_line_1: this.attr(),
      street_line_2: this.attr(),
      postal_code: this.attr(),
      city: this.attr(),
      state: this.attr(),
      country: this.attr(),

      phone: this.attr(),
      mobile: this.attr(),
      email: this.attr(),
      additional: this.attr(),

      user_id: this.attr(),
      company_id: this.attr(),
      // address_id: this.attr(),
      // user: this.belongsTo(User, 'user_id'),
    }
  }

  getMultilineText(locale) {
    const $t = store.getters['navbar/$t']

    let retString = ''

    if (this.street_line_1) {
      retString += this.street_line_1
    }

    if (this.street_line_2) {
      retString += '<br>' + this.street_line_2
    }

    if (this.postal_code) {
      retString += '<br>' + this.postal_code
    }

    if (this.city) {
      retString += ' ' + this.city
    }

    if (this.state) {
      retString += '<br>' + this.state
    }

    if (this.country) {
      retString += '<br>' + this.country
    }

    if (this.phone || this.mobile || this.email || this.additional) {
      if (this.phone) {
        retString += '<br>' + $t('generic.phone', locale) + ': ' + this.phone
      }

      if (this.mobile) {
        retString += '<br>' + $t('generic.mobile', locale) + ': ' + this.mobile
      }

      if (this.email) {
        retString += '<br>' + $t('generic.email', locale) + ': ' + this.email
      }

      if (this.additional) {
        retString += '<br>' + this.additional
      }
    }

    return retString
  }
}

Address.entity = 'addresses'
