import PlanitModel from './planit-model'
import User from './user'
import Address from './address'
import Order from './order'
import Promotion from './promotion'


export default class Basket extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      user_id: this.attr(),
      user: this.belongsTo(User, 'user_id'),

      billing_address_id: this.attr(),
      billing_address: this.belongsTo(Address, 'billing_address_id'),

      data: this.attr(),

      total_paid: this.attr(),
      currency: this.attr(),

      payment_state: this.attr(),
      payment_method: this.attr(),

      submitted_at: this.attr(),
      paid_at: this.attr(),
      paid_country: this.attr(),
      paid_ip: this.attr(),

      invoice_number: this.attr(),

      orders: this.hasMany(Order, 'basket_id'),

      total_price: this.attr(),

      promotion_id: this.attr(),
      promotion: this.belongsTo(Promotion, 'promotion_id'),

    }
  }

  getReferenceNumber() {
    return this.uuid.split('-')[4].slice(6)
  }

}

Basket.entity = 'baskets'
