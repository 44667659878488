<script>
import {
  debounce,
  orderBy,
} from 'lodash'
import {
  parseISO,
} from 'date-fns'

import PlanitInput from '@components/form/input'
import DateTimePicker from '@components/form/date-time-picker.vue'
import PlanitAlert from '@components/planit-alert'

import {
  RolloutState,
  RolloutMailType,
  FileExportStatus,
  CompanyLinkType,
} from '@enums'

import RolloutFileAssociation from '@state/models/rollout-file-association'
import File from '@state/models/file'
import Company from '@state/models/company'
import CompanyLink from '@state/models/company-link'

import {
  tomorrowMidnightISO,
  dateToISOString,
} from '@utils/date-helpers'


export default {
  name: 'RolloutMailOnly',
  components: {
    PlanitInput,
    PlanitAlert,
    DateTimePicker,
  },
  props: {
    rollout: { type: Object, required: true },
    withExtraOptions: { type: Boolean, default: false },
    showPrivacyPolicySelect: { type: Boolean, default: false },
  },
  emits: ['privacy-policy-selected'],
  data() {
    return {
      orderBy,

      CompanyLinkType,
      RolloutState,
      RolloutMailType,
      FileExportStatus,

      emailSuccess: false,
      filesAsAttachment: null,
      testEmail: '',
      emailPreviewLoading: false,

      uploading: false,
      uploadFiles: [],
      uploadError: null,
      showDragOverlay: false,

      selectedPrivacyPolicyCompanyLink: null,
      dateFilter: tomorrowMidnightISO()
    }
  },
  watch: {
    selectedPrivacyPolicyCompanyLink(v, ov) {
      if (v) {
        this.$emit('privacy-policy-selected', v.id)
        if (ov) {
          this.reloadPreview()
        }
      }
    }
  },
  async beforeMount() {
    if (this.showPrivacyPolicySelect) {
      await this.company.refreshSubResource(CompanyLink)
      this.selectedPrivacyPolicyCompanyLink = this.privacyPolicyCompanyLinks?.[0]
    }
  },
  computed: {
    isValidEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(this.testEmail)
    },
    expirationDate: {
      get() {
        return (this.rollout.link_expiration_date && parseISO(this.rollout.link_expiration_date)) || null
      },
      async set(val) {
        await this.rollout.updateAttr({ link_expiration_date: dateToISOString(val, { withTime: false }) })
        await this.rollout.sync('link_expiration_date')
      },
    },
    currentFolder() {
      return this.$store.getters['files/currentFolder']
    },
    filesInFolder() {
      return File.query().where('folder_id', this.currentFolder.id).all()
    },
    company() {
      return Company.find(this.rollout.company_id)
    },
    privacyPolicyCompanyLinks() {
      return CompanyLink.query()
        .where('company_id', this.rollout.company_id)
        .where('company_link_type', CompanyLinkType.PRIVACY_POLICY)
        .all()
    },
  },
  methods: {
    sendTestMail() {
      const button = document.querySelector('.hover-disabled')
      button.classList.add('disable-hover')

      if (!this.emailSuccess) {
        this.rollout.sendMail(RolloutMailType.TEST, this.testEmail)
      }
      this.emailSuccess = true

      setTimeout(() => {
        this.emailSuccess = false
      }, 4000)
    },
    addFiles(files) {
      for (const file of files) {
        this.uploadFiles.push({
          name: file.name,
          icon: File.getIconByExtension(file.name),
          data: file,
          state: 'Pending',
          disabled: File.isFileAllowed(file.name),
        })
      }
    },
    onFileChange(event) {
      console.log("event", event.target.files)
      if (!event.target.files.length) {
        return
      }
      this.addFiles(event.target.files)
      console.log("uploadFiles", this.uploadFiles)
      if (!this.uploading) {
        this.upload()
      }
    },
    async upload() {
      this.uploading = true
      this.uploadError = null

      const pendingFiles = this.uploadFiles.filter((file) => file.state === 'Pending')

      if (pendingFiles.length === 0) {
        this.uploading = false
        return
      }

      const file = pendingFiles[0]
      const setUploadProgress = (value) => {
        file.uploadProgress = value
      }
      setUploadProgress(1)
      const formData = new FormData()
      formData.append('file', file.data, file.name)
      formData.append('rollout_id', this.rollout.id)

      const onUploadProgress = (e) => {
        const value = Math.max(1, Math.round((100 * e.loaded) / e.total)) // minimum: 1
        setUploadProgress(value)
      }
      const thenHandler = (r) => {
        file.state = 'Uploaded'
        this.uploading = false
        this.uploadFiles = this.uploadFiles.filter((f) => f.state !== 'Uploaded')
        if (this.uploadFiles.length > 0) {
          this.upload()
        }
      }
      const catchHandler = (r) => {
        const uploadError = r.response.data?.message
        if (uploadError) {
          this.uploadError = uploadError
        }

        this.uploadFiles.splice(this.uploadFiles.indexOf(file), 1)
        this.uploading = false
        if (this.uploadFiles.length > 0) {
          this.upload()
        }
      }
      console.log("form_data", formData)
      await RolloutFileAssociation.uploadFile(formData, onUploadProgress).then(
        thenHandler,
        catchHandler
      )
    },
    getIconByExtension(filename) {
      if (!filename) {
        return 'fal fa-file'
      }
      return File.getIconByExtension(filename)
    },
    deleteAssociation(association) {
      association.delete()
    },
    async attachFileToRollout(v) {
      this.filesAsAttachment = v
      const data = {
        file_id: v,
        rollout_id: this.rollout.id,
      }
      await this.rollout.attachFile(data)
      this.filesAsAttachment = null
    },
    reloadPreview: debounce(async function () {
      let newUrl = `/api/rollouts/${this.rollout.id}/preview-email`

      if (this.selectedPrivacyPolicyCompanyLink) {
        newUrl += '?selected_privacy_policy_company_link_id=' + this.selectedPrivacyPolicyCompanyLink.id
      }

      // Rollout.$find(this.rollout.id)
      if (this.$refs.myIframe) { // might trigger after component is unloaded
        this.$refs.myIframe.src = newUrl
      }

    }, 500),
  },
}
</script>

<template>
  <div>
    <v-row>
      <v-col cols="6">
        <div>
          <h3 class="mb-4">
            {{ $t('rollouts.email_settings') }}
          </h3>
          <div
            v-for="(recipient, i) in rollout.recipients"
            :key="i"
          >
            <PlanitInput
              :model="recipient"
              attr="email"
              :disabled="rollout.state !== RolloutState.DRAFT"
              class="mb-4"
              highlight
              :label="$t('rollouts.recipient')"
              hide-details
            >
            </PlanitInput>
          </div>
          <PlanitInput
            :model="rollout"
            :disabled="!rollout.with_custom_email || rollout.state === RolloutState.COMPLETED"
            attr="email_subject"
            :label="$t('rollouts.subject')"
            class="mb-4"
            highlight
            hide-details
          >
          </PlanitInput>
          <PlanitInput
            :model="rollout"
            :disabled="!rollout.with_custom_email || rollout.state === RolloutState.COMPLETED"
            attr="email_sender_name"
            :label="$t('rollouts.sender_title')"
            highlight
            hide-details
            :on-change="reloadPreview"
          >
          </PlanitInput>
          <div
            style="max-height: 40rem; overflow-y: auto"
            class="mb-4 pt-6"
          >
            <PlanitInput
              :model="rollout"
              :disabled="!rollout.with_custom_email || rollout.state === RolloutState.COMPLETED"
              attr="email_message"
              :label="$t('rollouts.body')"
              type="textarea"
              :rows="2"
              variant="outlined"
              dense
              markdown
              highlight
              hide-details
              :on-change="reloadPreview"
            ></PlanitInput>
          </div>

          <div v-if="showPrivacyPolicySelect" class="mt-6">
            <PlanitSelect
              :modelValue="selectedPrivacyPolicyCompanyLink"
              :label="$t('rollouts.privacy_policy_to_be_used_in_email')"
              :items="privacyPolicyCompanyLinks"
              :disabled="rollout.state === RolloutState.COMPLETED"
              return-object
              item-title="href"
              item-value="id"
              variant="outlined"
            ></PlanitSelect>
          </div>

          <div v-if="withExtraOptions" class="mt-6">
            <h3 class="mb-2">{{ $t('rollouts.attachments') }}</h3>
            <div v-if="rollout.state !== RolloutState.COMPLETED" class="d-flex">
              <PlanitButton
                color="primary"
                tag="label"
                for="file-attachment"
                class="mb-2 mr-2"
                variant="outlined"
              >
                <PlanitIcon start icon=" far fa-file-upload "></PlanitIcon>

                {{ $t('rollouts.attach_file')}}
              </PlanitButton>
              <PlanitSelect
                :modelValue="filesAsAttachment"
                :items="filesInFolder"
                item-value="id"
                item-title="filename"
                :label="$t('rollouts.select_attachments')"
                class="mb-4"
                variant="outlined"
                density="compact"
                hide-selected
                hide-details
                @update:model-value="attachFileToRollout"
              ></PlanitSelect>
              <input
                id="file-attachment"
                ref="file"
                type="file"
                class="uploadinput"
                multiple
                @change.stop="onFileChange"
              />
            </div>
            <div
              v-for="(file, i) in uploadFiles"
              :key="i + file.name"
              class="d-flex align-center mb-2 ml-2"
            >
              <PlanitIcon
                class="mr-2"
                :icon="getIconByExtension(file.name)"
              />
              <div class="table-text flex-grow-1">
                <div class="mb-1">{{ file.name }}</div>
                <v-progress-linear
                  :model-value="file.uploadProgress"
                  class="mr-4"
                ></v-progress-linear>
              </div>
            </div>
            <div
              v-for="(association, i) in orderBy(rollout.file_associations, 'created', 'desc')"
              :key="'uploaded-' + i"
              class="d-flex align-center mb-2 ml-2 pb-2"
            >
              <PlanitIcon
                class="mr-2"
                :icon="getIconByExtension(association.file.filename)"
              />
              <div class="table-text flex-grow-1">
                <div v-if="association.file.export_status !== FileExportStatus.FINISHED">
                  <div class="mb-1">{{ association.file.document_name }}</div>
                  <v-progress-linear
                    color="primary"
                    indeterminate
                    class="mr-4"
                  ></v-progress-linear>
                </div>
                <div v-else>
                  <div>
                    <a
                      :href="association.file.link"
                      target="_blank"
                      >{{ association.file.filename }}</a
                    >
                  </div>
                  <div>{{ association.file.getFormattedFileSize() }}</div>
                </div>
              </div>
              <PlanitButton
                variant="text"
                size="small"
                :disabled="rollout.state === RolloutState.COMPLETED"
                color="default"
                class="icon-btn px-2"
                @click.prevent="deleteAssociation(association)"
              >
                <PlanitIcon size="small" icon=" fal fa-trash-alt "></PlanitIcon>

              </PlanitButton>
            </div>

            <h3 class="mt-6 mb-2">{{ $t('rollouts.data_access_settings') }}</h3>

            <PlanitInput
              :model="rollout"
              attr="email_as_password"
              :label="$t('rollouts.email_as_password')"
              :disabled="rollout.state !== RolloutState.DRAFT"
              class="pl-2 mb-2"
              type="checkbox"
              highlight
              hide-details
            ></PlanitInput>
            <div>{{ $t('rollouts.password_info') }}</div>

            <DateTimePicker
              :disabled="rollout.state === RolloutState.COMPLETED"
              type="date"
              v-model:value="expirationDate"
              :label="$t('rollouts.expiration_date')"
              :min="dateFilter"
              class="mb-2 mt-4"
            ></DateTimePicker>
            <div>{{ $t('rollouts.expiration_info') }}</div>

          </div>
          <div class="mt-6">
            <h3 class="mb-2">
              {{ $t('rollouts.test_mail') }}
            </h3>

            <div class="d-flex">
              <PlanitTextField
                v-model="testEmail"
                :disabled="rollout.state === RolloutState.COMPLETED"
                :label="$t('rollouts.recipient')"
                type="email"
                required
              ></PlanitTextField>
              <PlanitButton
                :disabled="!isValidEmail || rollout.state === RolloutState.COMPLETED"
                class="hover-disabled ml-2"
                :color="emailSuccess ? 'success' : 'primary'"
                @click="sendTestMail"
              >
                <PlanitIcon
                  v-if="emailSuccess"
                  class="ml-1"
                 icon="fal fa-check
                "/>
                {{ emailSuccess ? $t('rollouts.mail_success') : $t('rollouts.send_testmail') }}
              </PlanitButton>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="6">
        <div style="height: 100%;" class="d-flex flex-column">
          <h3 class="mb-2">
            {{ $t('generic.preview') }}
          </h3>
          <div class="flex-grow-1">
            <iframe
              id="custom-email-preview-iframe"
              ref="myIframe"
              :src="`/api/rollouts/${rollout.id}/preview-email`"
              width="100%"
              style="height: 100%; max-height: 55rem; border: 1px solid rgba(0, 0, 0, 0.12)"
            ></iframe>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
