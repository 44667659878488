// src/plugins/vue-i18n.js
import * as messages from '@i18n'

import { createI18n } from 'vue-i18n'

const i18nLegacy = createI18n({
  legacy: true,
  locale: window.lang ? window.lang : 'en',
  fallbackLocale: 'en',
  messages,
})

export default i18nLegacy
