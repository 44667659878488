import PlanitModel from './planit-model'
import User from './user'


export default class Changelog extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      title: this.attr(), // old
      text: this.attr(), // old

      published: this.attr(),
      published_at: this.attr(),

      title_translations: this.attr(),
      text_translations: this.attr(),
      subtitle_translations: this.attr(),

      changelog_type: this.attr(),
      image: this.attr(),

      slug: this.attr(),

      author_id: this.attr(),
      author: this.belongsTo(User, 'author_id'),

    }
  }

  /* getSlug() {
    return this.published ?
      (new Date(this.published_at)).toISOString().slice(0, 10) :
      (new Date(this.created)).toISOString().slice(0, 10)
  } */

  getRouteTo() {
    return {
      name: 'changelog.slug',
      params: {
        slug: this.slug,
      },
    }
  }

  getTitle(locale) {
    if (this.title_translations[locale]) {
      return this.title_translations[locale]

    }

    const nextNotEmpty = Object.keys(this.title_translations).find(
      l => !!this.title_translations[l]
    )

    if (nextNotEmpty) {
      return this.title_translations[nextNotEmpty]
    }

  }

  getText(locale) {
    if (this.text_translations[locale]) {
      return this.text_translations[locale]

    }

    const nextNotEmpty = Object.keys(this.text_translations).find(
      l => !!this.text_translations[l]
    )

    if (nextNotEmpty) {
      return this.text_translations[nextNotEmpty]
    }

  }

}

Changelog.entity = 'changelogs'
