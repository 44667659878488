import PlanitModel from './planit-model'
import Recipient from './recipient'
import RolloutFileAssociation from './rollout-file-association'
import Reminder from './reminder'
import Company from './company'

export default class Rollout extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),
      name: this.attr(),

      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),

      state: this.attr(),
      recipients : this.hasMany(Recipient, 'rollout_id'),
      creator: this.attr(),
      sender: this.attr(),
      file_associations : this.hasMany(RolloutFileAssociation, 'rollout_id'),
      description: this.attr(),
      reminder_id: this.attr(),
      reminder: this.belongsTo(Reminder, 'reminder_id'),
      with_confirmation: this.attr(),

      link_expiration_date: this.attr(),

      with_custom_email: this.attr(),
      email_sender_name: this.attr(),
      email_subject: this.attr(),
      email_message: this.attr(),
      files_as_attachments: this.attr(),
      email_as_password: this.attr(),
      document_id: this.attr(),
      sent_at: this.attr(),
    }
  }

  addRecipient(recipient) {
    this.recipients.push(recipient)
  }

  async sendMail(rolloutMailType, testEmail = null, selectedPrivacyPolicyCompanyLinkId = null) {
    const data = {
      testEmail: testEmail,
      rolloutMailType: rolloutMailType,
    }

    if (selectedPrivacyPolicyCompanyLinkId) {
      data.selected_privacy_policy_company_link_id = selectedPrivacyPolicyCompanyLinkId
    }

    await this.constructor.api().patch(this.url() + '/send',  data)

  }

  getPreviewEmail() {
    return this.constructor.api().get(this.url() + '/preview-email')
  }

  async attachFile(data) {
    return await RolloutFileAssociation.api().patch(this.url() + '/attach-file', data)
  }

}

Rollout.entity = 'rollouts'
