<script>
import { navbarGetters, navbarActions } from '@state/helpers'

import ReviewMain from '@components/review-system/review-main'
import Checklist from '@components/checklist'
import PrimaInfoCenter from '@components/info-center/prima-info-center'
import ScrollContainer from '@components/scroll-container'
import ChecklistGuide from '@components/checklist/checklist-guide'
import store from '../state/store'


import { DocumentType } from '@enums'

export default {
  name: 'RightDrawer',
  components: {
    Checklist,
    ReviewMain,
    PrimaInfoCenter,
    ScrollContainer,
    ChecklistGuide,
  },
  props: {
    activeListChecklist: {
      type: Object,
      default: null,
    },
    currentDocumentId: {
      type: Number,
      default: null,
    },
    activeChecklistGuide: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...navbarGetters,
    showInfoCenter() {
      return store.getters['global/showInfoCenter']
    },
    navDrawerStyles() {
      const styles = {}

      if (this.hasVerySmallScreen) {
        styles['padding-top'] = this.navbarHeight
      } else {
        styles.top = this.navbarHeight + ' !important'
      }
      // styles['z-index'] = '200 !important'

      styles['right'] = store.getters['navbar/rightAppPanelWidth'] + 'px'

      return styles
    },
    backgroundLightColor() {
      return store.getters['global/backgroundLightColor']
    },
    infoCenterSearchString() {
      return store.getters['global/infoCenterSearchString']
    },
    currentLocale() {
      return store.getters['auth/currentLocale']
    },
    showGuideChecklistDrawer() {
      return store.getters['navbar/showGuideChecklistDrawer']
    },
    currentFolder() {
      return store.getters['files/currentFolder']
    },
    parentFolder() {
      return store.getters['files/parentFolder']
    },
    linkedDocumentBlueprints() {
      return this.$store.getters['files/linkedDocumentBlueprints']
    },
    canEdit() {
      return (
        !!this.currentFolder &&
        (
          this.isAdmin ||
          this.myCompanyRole?.is_documents_admin ||
          this.myCompanyRole?.can_edit_documents
        ) &&
        !store.getters['navbar/dpdReadOnly']
      )
    },
    company() {
      return this.$store.getters['files/company']
    },
    createdDocuments() {
      return this.$store.getters['files/createdDocuments']
    },
    repoDocuments() {
      return this.$store.getters['files/repoDocuments']
    },
    checklistGuideName() {
      return this.$store.getters['currentDocument/currentChecklistGuide']?.name_translations[this.currentLocale]
    },
  },
  async beforeMount() {
    if (this.showGuideChecklistDrawer) {
      const promises = []
      promises.push(this.currentFolder.refreshContent(this.company, false, ['Document'], [], { document_type: DocumentType.DOCUMENT }))
      promises.push(this.currentFolder.refreshContent(this.company, false, ['Document'], [], { document_type: DocumentType.REPOSITORY_ITEM, include_blueprint: true }))
      await Promise.all(promises)

      this.loading = false
    }
  },
  async created() {
  },
  methods: {
    ...navbarActions,
    handleClose() {
      if (this.activeListChecklist) {
        this.setShowChecklistDrawer({value: false})
      }
      if (this.currentDocumentId) {
        let rightDrawerClosed = JSON.parse(window.localStorage.getItem('rightDrawerClosed')) || {}
        rightDrawerClosed[this.currentDocumentId] = true
        window.localStorage.setItem('rightDrawerClosed', JSON.stringify(rightDrawerClosed))

        this.setShowApprovalDrawer(false)
      }
      this.setShowApprovalDrawer(false)
      this.setShowGuideChecklistDrawer(false)

    },
  },
}
</script>

<template>
  <v-navigation-drawer
    id="rightDrawer"
    ref="rightDrawer"
    :model-value="true"
    location="right"
    floating
    :style="[{ 'max-height': 'calc(100% - ' + navbarHeight + ') !important' }, navDrawerStyles, { 'width': rightDrawerWidth }]"
    disable-resize-watcher
    disable-route-watcher
    :color="hasVerySmallScreen ? 'white' : backgroundLightColor"
    :temporary="hasVerySmallScreen"
    :mobile-breakpoint="0"
    :mobile="false"
  >
    <ScrollContainer>
      <div
        class="d-flex flex-column"
      >
        <div class="flex-grow-1">
          <div
            v-if="!!activeListChecklist"
            class="fill-height"
          >
            <Checklist
              :checklist="activeListChecklist"
              @setShowRightDrawer="handleClose()"
            ></Checklist>
          </div>
          <div v-if="!activeListChecklist && currentDocumentId">
            <ReviewMain @setShowRightDrawer="handleClose()"></ReviewMain>
          </div>
          <div v-if="!!showInfoCenter" class="pa-4">
            <PrimaInfoCenter
              :locale="currentLocale"
              :searchString="infoCenterSearchString"
            ></PrimaInfoCenter>
          </div>
          <div v-if="!loading && showGuideChecklistDrawer" class="pa-4">
            <div class="d-flex mb-2">
              <h2 v-if="checklistGuideName">{{ checklistGuideName }}</h2>
              <PlanitButton
                variant="text"
                class="icon-btn ml-auto"
                @click="handleClose()"
              >
                <PlanitIcon
                  size="small"
                  color="grey-darken-1"
                 icon="fas fa-chevron-double-right
                "/>
              </PlanitButton>
            </div>
            <ChecklistGuide
              :folder-id="currentFolder.id"
              :repoDocuments="repoDocuments"
              :can-edit="canEdit"
              in-right-drawer
            ></ChecklistGuide>
          </div>
        </div>
      </div>
    </ScrollContainer>
  </v-navigation-drawer>
</template>
