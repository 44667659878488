import PlanitModel from './planit-model'
import { InviteTargetType } from '@enums'
import Invite from '@state/models/invite'
import TaskCompanyRoleAssociation from '@/src/state/models/task-company-role-association'
import ApprovalComment from '@state/models/approval-comment'

import Task from '@state/models/task'

export default class Approval extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      requires_writer: this.attr(),
      requires_reviewer: this.attr(),
      requires_approver: this.attr(),

      document_id: this.attr(),
      tasks : this.hasMany(Task, 'approval_id'),

      has_tasks: this.attr(),
    }
  }

  postOrPatch(data, config) {
    const shouldSave = config?.save !== undefined ? config.save : true
    return super.postOrPatch(data, { ...config, save: false }).then((r) => {
      if (shouldSave) {
        r.save()
      }

      return r
    })
  }

  async refreshContent({ updateTasks = false, updateComments = false, all = false } = {}) {
    const promise = []
    if (updateTasks || all) {
      const old_tasks = Task.query().where((task) => task.approval_id === this.id).with('task_company_role_associations').all()
      promise.push(this.refreshSubResource(Task).then((result) => {
        result.response.data.forEach((task) => {
          const new_ids = task.task_company_role_associations.map((tcra) => tcra.id)
          const old_task = old_tasks.find((old_task) => old_task.id === task.id)
          old_task.task_company_role_associations.forEach((old_tcra) => {
            if (!new_ids.includes(old_tcra.id)) {
              TaskCompanyRoleAssociation.delete(old_tcra.id)
            }
          })
        })
        return result
      }))
    }
    if (updateComments || all) {
      promise.push(this.refreshSubResource(ApprovalComment))
    }

    await Promise.allSettled(promise)

  }

  async addTask( data ) {
    return this.createSubResource(Task, {
      approval_id: this.id,
        ...data,
    })
  }
  async addComment(commentData) {
    return this.createSubResource(ApprovalComment, {
      approval_id: this.id,
      ...commentData,
    })
  }

  static async loadMyTasks(company_role_id, params) {
    const data = {
      params: params
    }
    return await Approval.api().get(`approvals/my-tasks/${company_role_id}`, data)
  }
}

Approval.entity = 'approvals'
