import PlanitModel from './planit-model'
import Changelog from './changelog'
import BlogCategory from './blog-category'


export default class BlogCategoryAssociation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      changelog_id: this.attr(),
      changelog: this.belongsTo(Changelog, 'changelog_id'),

      blog_category_id: this.attr(),
      blog_category: this.belongsTo(BlogCategory, 'blog_category_id')
    }
  }
}

BlogCategoryAssociation.entity = 'blog-category-associations'
