import PlanitModel from './planit-model'
import User from './user'
import Section from './section'
import Answer from './answer'
import { SelectGroup, SelectGroupOption } from './select-group'
import { SectionSelectGroupOptionResult } from './section-select-group-option-result'
import { AnswerSectionSelectGroupOptionResultUserData } from './answer-section-select-group-option-result-user-data'


export default class Commit extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      data: this.attr(),

      document_blueprint_version_id: this.attr(),
      sections: this.hasMany(Section, 'commit_id'),
      /* section_document_blueprint_associations: this.hasMany(
        SectionDocumentBlueprintAssociation,
        'commit_id'
      ), */
      deleted_sections: this.hasMany(Section, 'deleted_by_commit_id'),

      select_groups: this.hasMany(SelectGroup, 'commit_id'),
      select_group_options: this.hasMany(SelectGroupOption, 'commit_id'),

      section_select_group_option_results: this.hasMany(SectionSelectGroupOptionResult, 'commit_id'),

      /* section_select_group_option_result_document_blueprint_version_associations: this.hasMany(
        SectionSelectGroupOptionResultDocumentBlueprintVersionAssociation,
        'commit_id'
        ), */

      document_version_id: this.attr(),
      answers: this.hasMany(Answer, 'commit_id'),

      answer_section_select_group_option_result_user_data:
        this.hasMany(
          AnswerSectionSelectGroupOptionResultUserData,
          'commit_id'
        ),

      user_id: this.attr(),
      user: this.belongsTo(User, 'user_id'),
    }
  }
}

Commit.entity = 'commits'
