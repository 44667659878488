// src/filters/currency.js
export default function formatCurrency(value, currency = 'EUR', locale = 'de-DE') {
    /* if (typeof value !== "number") {
        return value
    } */

    var formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
    });

    return formatter.format(value);
}
