const defaultThemes = {
  light: {
    colors: {
      primary: '#001489',
      error: '#EA2F24',
      info: '#086896',
      success: '#0D6E1D',
      warning: '#F3A32C',

      appbar: '#FFFFFF',
      background: '#0057FF',
      'on-background': '#000000', // Ensure this is defined
      planitBlue: '#001489',
      planitDarkBlue: '#0e2362',
      planitDarkGreen: '#0D6E1D',
      plaintLightBlue: '#118fA5',
      planitLightGreen: '#A5F4B2',
      planitYellow: '#e5dc26',
      planitRed: '#c42228',
      planitOrange: '#f0961a',
      primaryDesaturated: '#373b53',
      primaryDesaturatedDisabled: '#969ab0',
    }
  },
  dark: {
    colors: {
      anchor: '#fff',
      info: '#545454',
      background: '#121212', // Example background for dark theme
      'on-background': '#FFFFFF', // Ensure this is defined
    }
  },
  dashboardColors: [
    { color: 'planitBlue', bgColor: 'accent' },
    { color: 'amber darken-1', bgColor: 'amber lighten-5' },
    { color: 'pink darken-3', bgColor: 'pink lighten-5' },
    { color: 'green darken-3', bgColor: 'green lighten-5' },
  ],
};

export default defaultThemes;
