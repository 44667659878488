import store from '@state/store'
import formatDistance from 'date-fns/formatDistance'
import parseISO from 'date-fns/parseISO'

const $t = store.getters['navbar/$t']

export default function formatDateRelative(fromDate, toDate = new Date()) {
  fromDate = parseISO(fromDate)
  toDate = parseISO(toDate)

  let locale
  if (window.lang === 'de') {
    locale = require('date-fns/locale/de').default
  }

  // return formatDistance(fromDate, toDate, { locale }) + (isToday(toDate) ? ' ago' : '')
  return formatDistance(fromDate, toDate, { locale })
}

// für genauere Ausgabe von z.b. x Tage, oder x Wochen oder x Monaten
export function formatRelativeDateDiff(reminderDate, startDate) {
  if (!reminderDate || !startDate) return ''

  const diffInDays = daysBetweenDates(startDate, reminderDate)
  const { months, days } = fullMonthsAndDaysDiff(startDate, reminderDate)

  if (diffInDays < 7) {
    return diffInDays === 1
      ? $t('relativeDate.oneDay')
      : $t('relativeDate.manyDays', { count: diffInDays })
  }

  if (months > 0 && days === 0) {
    return months === 1
      ? $t('relativeDate.oneMonth')
      : $t('relativeDate.manyMonths', { count: months })
  }

  if (diffInDays % 7 === 0) {
    const weeks = diffInDays / 7
    return weeks === 1
      ? $t('relativeDate.oneWeek')
      : $t('relativeDate.manyWeeks', { count: weeks })
  }

  return $t('relativeDate.manyDays', { count: diffInDays })
}

export function daysBetweenDates(startDate, endDate) {
  const start = new Date(startDate)
  const end = new Date(endDate)
  const diffInTime = end.getTime() - start.getTime()
  return Math.ceil(diffInTime / (1000 * 3600 * 24))
}

export function fullMonthsAndDaysDiff(startDate, endDate) {
  const start = new Date(startDate)
  const end = new Date(endDate)

  let yearsDiff = end.getFullYear() - start.getFullYear()
  let monthsDiff = yearsDiff * 12 + (end.getMonth() - start.getMonth())
  let dayOfMonthStart = start.getDate()
  let dayOfMonthEnd = end.getDate()

  let leftoverDays = dayOfMonthEnd - dayOfMonthStart
  if (leftoverDays < 0) {
    monthsDiff -= 1
    const previousMonth = new Date(end.getFullYear(), end.getMonth(), 0)
    leftoverDays = previousMonth.getDate() - dayOfMonthStart + dayOfMonthEnd
  }

  if (monthsDiff < 0) {
    monthsDiff = 0
    leftoverDays = 0
  }

  return { months: monthsDiff, days: leftoverDays }
}


