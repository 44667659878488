<script>

import {
  cloneDeep,
} from 'lodash'

import {
  LicenseType,
} from '@enums'

import defaultThemes from '@plugins/default-themes'

import store from '@state/store'


export default {
  name: 'LicenseLabel',
  components: {},
  props: {
    licenseType: { type: String, required: false, default: null },
    inactive: { type: Boolean, required: false, default: false },
    inverted: { type: Boolean, required: false, default: false },
    density: { type: Boolean, required: false, default: false },
    text: { type: String, required: false, default: null },
    color: { type: String, required: false, default: null },
  },
  data: function () {
    return {
      LicenseType,
    }
  },
  computed: {
    labelStyle() {
      const styles = {
        'padding': this.density ?
          '0px 6px' :
          '2px 8px',
        'border-radius': '4px',
        'font-weight': this.inverted ? 700 : 600,
      }

      if (this.inactive) {
        styles['background-image'] = 'linear-gradient(135deg, #cfcfcf 0%, #b0b0b0 100%)'

      } else if (this.licenseType === LicenseType.FREE) {
        styles['border'] = '1px solid rgb(var(--v-theme-primary))'
        styles['color'] = 'rgb(var(--v-theme-primary))'

      } else if (this.licenseType === LicenseType.BASIC) {
        styles['background-image'] = 'linear-gradient(135deg, #66cd39 0%, #4ba524 100%)'
        styles['color'] = '#193010'

      } else if (this.licenseType === LicenseType.TRAININGS_ONLY) {
        styles['background-image'] = 'linear-gradient(135deg, rgb(57 205 205) 0%, rgb(36 152 165) 100%)'
        styles['color'] = '#fff'

      } else if (
        this.licenseType === LicenseType.RESELLER ||
        this.licenseType === LicenseType.PROFESSIONAL
      ) {
        styles['background-image'] = 'var(--prima-license-reseller-gradient)'
        styles['color'] = 'var(--prima-license-reseller-color)'

      } else if (!this.color) {
        styles['background-image'] = 'linear-gradient(135deg, #2343fb 0%, #0025f5 100%)'
        styles['color'] = '#fff'

      }

      return styles

    },
    freeInvertedClasses() {
      // currently used in navbar
      return store.getters['global/navbarDark'] ?
        'text-white border-white' :
        'text-grey-darken-4 border-grey-darken-4'
    },
    colorClasses() {
      return this.color ? [this.color] : []
    },
  },
  created() {
  },
  methods: {
  },
}
</script>

<template>
  <div
    :class="
      inverted ?
        licenseType === LicenseType.FREE ?
          'border-radius' :
          'white border-radius' :
        ''
    "
    style="display: inline-block;"
  >
    <div
      :style="labelStyle"
      :class="[
        inverted ?
          licenseType === LicenseType.FREE ?
            freeInvertedClasses :
            'text-gradient font-weight-bold' :
          '',
        ...colorClasses,
      ]"
    >
      {{ text || $t('enums.license_type.' + licenseType) }}
    </div>
  </div>
</template>
