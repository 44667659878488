import {
  sortBy,
} from 'lodash'

import {
  TrainingSessionStatus,
} from '@enums'

import CompanyRoleScormStatus from '@state/models/company-role-scorm-status'
import TrainingSession from '@state/models/training-session'
import store from '@state/store'
import formatDate from '@utils/format-date'

export const state = {
  trainingSessions: null,
  selectedTrainingSession: null,
  company_id: null,
  companyRole: null
}

export const mutations = {
  SET_TRAINING_SESSIONS(state, trainingSessions) {
    state.trainingSessions = trainingSessions
  },
  SET_SELECTED_TRAINING_SESSION(state, trainingSession) {
    state.selectedTrainingSession = trainingSession
  },
  SET_COMPANY_ID(state, company_id) {
    state.company_id = company_id
  },
  SET_COMPANY_ROLE(state, companyRole) {
    state.companyRole = companyRole
  }
}

export const getters = {
  companyRole(state) {
    return state.companyRole
  },
  companyRoleScormStatus(state) {
    return CompanyRoleScormStatus
      .query()
      .where('company_role_id', state.companyRole.id)
      .with('scorm_file')
      .get()
  },
  selectedTrainingSession(state) {
    return state.selectedTrainingSession
  },
  trainingSessionIterations(state, getters, rootState) {
    const selectedTrainingSession = state.selectedTrainingSession;
    if (!selectedTrainingSession) return []

    const $t = store.getters['navbar/$t']

    const iterations = []

    // Füge die aktuelle TrainingSession als erste Iteration hinzu
    iterations.push({
      id: 'current',
      name: $t('company.scorm_file.show_current'),
      start: selectedTrainingSession.start_date,
      end: selectedTrainingSession.end_date,
      is_current: true,
    })

    const instances = selectedTrainingSession.training_session_instances || []

    instances.forEach((instance, index) => {
      const startDate = new Date(instance.start_date)
      const endDate = new Date(instance.end_date)
      iterations.push({
        id: `${formatDate1(startDate)} ${$t('company.scorm_file.to')} ${formatDate1(endDate)}`,
        name: `${formatDate(startDate.toISOString())} - ${formatDate(new Date(endDate.getTime() - 1).toISOString())}`,
        start_date: startDate,
        end_date: endDate,
        is_current: false,
      })
    })

    iterations.sort((a, b) => {
      if (a.is_current) return -1
      if (b.is_current) return 1
      return new Date(a.start_date) - new Date(b.start_date)
    })

    return formatIterations(iterations)
  },

  trainingSessions(state, getters) {
    return TrainingSession.query()
    .where('company_id', store.getters['navbar/selectedCompany'].id)
    .with('session_scorm_links.scorm_file')
    .with('training_session_instances')
    .with('training_session_company_role_associations')
    .with('reminders')
    .all()
  },
  trainingSessionWithCurrentAndFinishedWithoutSessionItems(state,  getters) {
    const sessions = getters.trainingSessions
    return [
      {
        id: 'current',
        name: store.getters['navbar/$t']('company.scorm_file.show_active'),
      },
      {
        id: 'completed_without_training_session',
        name: store.getters['navbar/$t']('company.scorm_file.completed_without_training_session'),
      },
      ...sessions,
    ]
  },
  trainingSessionsWithCurrentItems(state, getters) {
    const sessions = getters.trainingSessions

    // sort sessions by status
    const sortedSessions = sortBy(
      sessions,
      (s) => {
        if (s.status === TrainingSessionStatus.ACTIVE) {
          return 0
        } else if (s.status === TrainingSessionStatus.CLOSED) {
          return 1
        } else if (s.status === TrainingSessionStatus.INACTIVE) {
          return 2
        }
      }
    )

    return [{ id: 'current', name: store.getters['navbar/$t']('company.scorm_file.show_current') }, ...sortedSessions]
  },
  trainingSessionWithAllItems(state, getters) {
    const sessions = getters.trainingSessions
    return [{ id: 'all', name: store.getters['navbar/$t']('company.scorm_file.show_all') }, ...sessions]
  }
}

export const actions = {
  async initTrainingSessions({ dispatch, getters }, {company_id, company_role_id, exclude_ended_sessions = false}) {
    TrainingSession.$all({ company_id: company_id, company_role_id: company_role_id, exclude_ended_sessions: exclude_ended_sessions })
  },
  initCompanyRole({ commit }) {
    commit('SET_COMPANY_ROLE', store.getters['navbar/selectedCompany'].getMyCompanyRole())
  },
  setSelectedTrainingSession({ commit }, trainingSession) {
    commit('SET_SELECTED_TRAINING_SESSION', trainingSession)
  },
  async addCompanyRoleToTrainingSession({ dispatch, state }, { company_role, training_session_ids }) {
    return await company_role.addToTrainingSessions(training_session_ids)
  }
}

function formatDate1(date) {
  return date.toISOString().split('T')[0] // Format: YYYY-MM-DD
}

function formatIterations(iterations) {
  return iterations.map(({ id, name }) => ({ id, name })) // Entferne start und end aus der Rückgabe
}
