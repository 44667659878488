import PlanitModel from './planit-model'


export default class ChecklistUserActivation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      checklist_id: this.attr(),
      user_id: this.attr(),

    }
  }

}

ChecklistUserActivation.entity = 'checklist-user-activations'
