import { orderBy } from 'lodash'

import {
  DocumentType,
  FolderType,
  DocumentAvailability,
  PermissionRoleType,
  FileType,
  MenuCategoryUuid,
  DocumentBlueprintType,
} from '@enums'

import store from '@state/store'
import { Document } from '@state/models/document'
import Folder from '@state/models/folder'
import File from '@state/models/file'
import DocumentBlueprint from '@state/models/document-blueprint'
import FolderLink from '@state/models/folder-link'
import PermissionRoleModelAssociation from '@state/models/permission-role-model-association'
import PermissionRole from '@state/models/permission-role'
import FileFolderDocumentBlueprintAssociation from '@state/models/file-folder-document-blueprint-association'
import requestErrorHandler from '@utils/request-error-handler'

export const state = {
  companyId: null,
  company: null,
  documentBlueprint: null,
  folderId: null,
  linkedBlueprintTemplateItems: {},
  linkedBlueprintCustomTemplateItems: {},
  linkedBlueprintSelectedTemplates: {},
  currentFolder: null,
  linkedBlueprintCreateMode: {},
  linkedBlueprintUploadProgress: {},
  linkedBlueprintUploadFiles: {},
  uploadFiles: [],
  createFolderLoading: false,
  folderError: null,
  validNavTreeItems: [],
  moveToTreeItems: [],

  repoDocumentEditModalDocument: null,
}

export const mutations = {
  SET_COMPANY_ID(state, companyId) {
    state.companyId = companyId
  },
  SET_COMPANY(state, company) {
    state.company = company
  },
  SET_DOCUMENT_BLUEPRINT(state, documentBlueprint) {
    state.documentBlueprint = documentBlueprint
  },
  SET_FOLDER_ID(state, folderId) {
    state.folderId = folderId
  },
  SET_CURRENT_FOLDER(state, folder) {
    state.currentFolder = folder
  },
  SET_LINKED_BLUEPRINT_CREATE_MODE(state, { blueprintId, value }) {
    state.linkedBlueprintCreateMode = { ...state.linkedBlueprintCreateMode, [blueprintId]: value }
  },
  SET_UPLOAD_FILES(state, files) {
    state.uploadFiles = files
  },
  SET_LINKED_BLUEPRINT_UPLOAD_FILES(state, { id, file }) {
    state.linkedBlueprintUploadFiles = { ...state.linkedBlueprintUploadFiles, [id]: file }
  },
  SET_LINKED_BLUEPRINT_UPLOAD_PROGRESS(state, { id, progress }) {
    state.linkedBlueprintUploadProgress = { ...state.linkedBlueprintUploadProgress, [id]: progress }
  },
  SET_CREATE_FOLDER_LOADING(state, loading) {
    state.createFolderLoading = loading
  },
  SET_FOLDER_ERROR(state, error) {
    state.folderError = error
  },
  SET_VALID_NAV_TREE_ITEMS(state, items) {
    state.validNavTreeItems = items
  },
  ADD_LINKED_BLUEPRINT_TEMPLATE_ITEM(state, { blueprintId, item }) {
    if (!state.linkedBlueprintTemplateItems[blueprintId]) {
      state.linkedBlueprintTemplateItems[blueprintId] = []
    }
    state.linkedBlueprintTemplateItems[blueprintId].push(item)
  },
  ADD_LINKED_BLUEPRINT_CUSTOM_TEMPLATE_ITEM(state, { blueprintId, item }) {
    if (!state.linkedBlueprintCustomTemplateItems[blueprintId]) {
      state.linkedBlueprintCustomTemplateItems[blueprintId] = []
    }
    state.linkedBlueprintCustomTemplateItems[blueprintId].push(item)
  },
  CLEAR_LINKED_BLUEPRINT_TEMPLATE_ITEMS(state) {
    state.linkedBlueprintTemplateItems = []
  },
  CLEAR_LINKED_BLUEPRINT_CUSTOM_TEMPLATE_ITEMS(state) {
    state.linkedBlueprintCustomTemplateItems = []
  },
  SET_MOVE_TO_TREE_ITEMS(state, items) {
    state.moveToTreeItems = items
  },
  SET_REPO_DOCUMENT_EDIT_MODAL_DOCUMENT(state, repoDocument) {
    state.repoDocumentEditModalDocument = repoDocument
  },
  SET_FILE_UPLOAD_PROGRESS(state, { file, value }) {
    const fileInState = state.uploadFiles.find((f) => f === file)
    if (fileInState) {
      fileInState.uploadProgress = value
    }
  },
  SET_CURRENT_FOLDER_NAME(state, name) {
    state.currentFolder.name = name
  }
}

export const actions = {
  setCompanyId({ commit }, companyId) {
    commit('SET_COMPANY_ID', companyId)
  },
  setCompany({ commit }, company) {
    commit('SET_COMPANY', company)
  },
  setFolderId({ commit }, folderId) {
    commit('SET_FOLDER_ID', folderId)
  },
  setDocumentBlueprint({ commit }, documentBlueprint) {
    commit('SET_DOCUMENT_BLUEPRINT', documentBlueprint)
  },
  setLinkedBlueprintSelectedTemplates({ commit }, { blueprintId, selectedTemplates }) {
    commit('SET_LINKED_BLUEPRINT_SELECTED_TEMPLATES', { blueprintId, selectedTemplates })
  },
setLinkedBlueprintCreateMode({ commit }, { blueprintId, value }) {
    commit('SET_LINKED_BLUEPRINT_CREATE_MODE', { blueprintId, value })
  },
  setLinkedBlueprintUploadFiles({ commit }, { id, file }) {
    commit('SET_LINKED_BLUEPRINT_UPLOAD_FILES', { id, file })
  },
  setLinkedBlueprintUploadProgress({ commit }, { id, progress }) {
    commit('SET_LINKED_BLUEPRINT_UPLOAD_PROGRESS', { id, progress })
  },
  setUploadFiles({ commit }, files) {
    commit('SET_UPLOAD_FILES', files)
  },
  setCreateFolderLoading({ commit }, loading) {
    commit('SET_CREATE_FOLDER_LOADING', loading)
  },
  setFolderError({ commit }, error) {
    commit('SET_FOLDER_ERROR', error)
  },
  setCurrentFolderName({ getters, commit }, name) {
    const data ={
      name: name
    }
    getters.currentFolder.patch(data)
    commit('SET_CURRENT_FOLDER_NAME', name)
  },
  async prepareValidNavTreeItems({ getters, commit }, { selectedFolder, isMoveFile }) {
    const response = await Folder.loadMoveFolderTree(
      selectedFolder.id,
      isMoveFile,
      getters.companyId,
      getters.menuFoldersPermissionRole?.id
    )
    commit('SET_MOVE_TO_TREE_ITEMS', response.data)
  },
  async setCurrentFolder({ commit, getters }) {
    await Folder.$find(getters.folderId)

    const folder = Folder.query()
      .where('id', getters.folderId)
      .with('table_setting_activation')
      .with('permission_role_model_associations')
      .with('checklist_step_folder_statuses')
      .with('checklist_step_folder_statuses.checklist_step')
      .first()

    commit('SET_CURRENT_FOLDER', folder)
  },
  async createFolders(
    { dispatch, getters },
    { newFolderName, existingVendor, createSubfolderTemplates, withGuide, config = {} }
  ) {
    dispatch('setFolderError', null)
    const result = await store.getters['navbar/selectedCompany']
      .createFolder(
        newFolderName,
        getters.currentFolder,
        true, // skipReloadEvent
        existingVendor,
        createSubfolderTemplates,
        config,
        true,
        withGuide
      )
      .catch((error) => {
        dispatch('handleFolderCreationError', { error, newFolderName })
        dispatch('setCreateFolderLoading', false)
        return null
      })
    if (config) {
      await result.save()
    }
    return result
  },
  handleFolderCreationError({ dispatch }, { error, newFolderName }) {
    if (error.response?.data?.message) {
      let errorMessage = error.response.data.message
      if (errorMessage) {
        errorMessage += ` (Folder Name: ${newFolderName})`
      }
      dispatch('setFolderError', errorMessage)
    } else if (error?.response?.data?.name?.length) {
      dispatch('setFolderError', error.response.data.name[0])
    }
  },
  async createDocumentsInFolderPromise(
    { dispatch, getters },
    { folder, newPermissionRoleId, linkedBlueprintSelectedTemplates }
  ) {
    const promises = []
    for (const blueprint of getters.linkedDocumentBlueprints) {
      let template = null
      // create document
      if (
        getters.linkedBlueprintCreateMode[blueprint.id] &&
        getters.linkedBlueprintCreateMode[blueprint.id] === 'document'
      ) {
        if (
          linkedBlueprintSelectedTemplates[blueprint.id] !== 'empty' &&
          linkedBlueprintSelectedTemplates[blueprint.id]
        ) {
          template = linkedBlueprintSelectedTemplates[blueprint.id]
        }

        promises.push(
          dispatch('handleDocumentCreation', {
            blueprint,
            folder,
            template,
            newPermissionRoleId,
          }).catch(requestErrorHandler)
        )
      }
      // upload
      if (getters.linkedBlueprintUploadFiles[blueprint.id]) {
        promises.push(dispatch('uploadDocument', { blueprint: blueprint, newFolder: folder }))
      }
    }
    return promises
  },
  async uploadDocument({ dispatch, getters }, { blueprint, newFolder }) {
    const setUploadProgress = (value) => {
      if (blueprint) {
        dispatch('setLinkedBlueprintUploadProgress', { id: blueprint.id, progress: value })
      }
    }

    setUploadProgress(1)
    const formData = new FormData()

    const uploadFile = getters.linkedBlueprintUploadFiles[blueprint.id]

    formData.append('file', uploadFile, uploadFile.name)

    if (newFolder) {
      formData.append('parent_folder_id', newFolder.id)
    }

    if (blueprint) {
      formData.append('linked_blueprint_id', blueprint.id)
    }

    if (blueprint) {
      dispatch('setLinkedBlueprintUploadFiles', { id: blueprint.id, file: null })
      dispatch('setLinkedBlueprintUploadProgress', { id: blueprint.id, progress: null })
    }
    const onUploadProgress = (e) => {
      const value = Math.max(1, Math.round((100 * e.loaded) / e.total)) // minimum: 1
      setUploadProgress(value)
    }
    return getters.company.uploadDocument(formData, onUploadProgress)
  },
  async handleDocumentCreation({ getters }, { blueprint, folder, template, newPermissionRoleId }) {
    if (!!getters.linkedBlueprintUploadFiles[blueprint.id]) return
    return getters.company.createDocumentFromBlueprint(
      blueprint,
      template,
      null,
      folder.id || null,
      true, // skipReloadEvent
      false,
      newPermissionRoleId
    )
    // .catch((r) => {
    //   if (r.response.data && r.response.data.name) {
    //     this.linkedBlueprintNameErrors[blueprint.id] = r.response.data.name[0]
    //   }
    // })
  },
  async deleteFile(file) {
    await file.delete()
  },
  handleFileChange({ dispatch }, { files, blueprint }) {
    if (!blueprint) {
      dispatch(
        'setUploadFiles',
        [...files].map((file) => {
          return {
            name: file.name,
            icon: File.getIconByExtension(file.name),
            file,
            disabled: File.isFileAllowed(file.name),
            isPrimaExport: file.name.endsWith('.prima'),
          }
        })
      )
    } else {
      dispatch('setLinkedBlueprintUploadFiles', { id: blueprint.id, file: files[0] })
      dispatch('setLinkedBlueprintUploadProgress', { id: blueprint.id, progress: null })
    }
  },
  async prepareFolderTemplates({ dispatch, commit, getters }) {
    commit('CLEAR_LINKED_BLUEPRINT_TEMPLATE_ITEMS')
    commit('CLEAR_LINKED_BLUEPRINT_CUSTOM_TEMPLATE_ITEMS')
    const blueprints = getters.linkedDocumentBlueprints

    for (const blueprint of blueprints) {
      const { templateItems, customTemplateItems } = await dispatch('queryTemplateItems', {
        blueprint,
      })

      templateItems.forEach((item) => {
        if (item.text === '') {
          const translations = item.value.name_translations
          if (translations) {
            item.text = translations[this.current_locale] || translations.en || ''
          }
        }
        commit('ADD_LINKED_BLUEPRINT_TEMPLATE_ITEM', { blueprintId: blueprint.id, item })
      })

      customTemplateItems.forEach((item) =>
        commit('ADD_LINKED_BLUEPRINT_CUSTOM_TEMPLATE_ITEM', { blueprintId: blueprint.id, item })
      )
    }
  },

  async queryTemplateItems({ dispatch }, { blueprint }) {
    const templateItems = []
    const customTemplateItems = []

    const allTemplates = await dispatch('getAllTemplates', { blueprint: blueprint })
    const isEmptyTemplateDisabled =
      blueprint.current_blueprint_version?.data?.disable_empty_template

    if (!isEmptyTemplateDisabled) {
      templateItems.push({
        text: store.getters['navbar/$t']('documents.overview.empty_template'),
        value: 'empty',
      })
    }
    templateItems.push.apply(
      templateItems,
      allTemplates.filter((item) => item.value.document_type === DocumentType.TEMPLATE)
    )

    customTemplateItems.push.apply(
      customTemplateItems,
      allTemplates.filter(
        (item) => item.value.use_as_template === true && !item.value.upgrade_available
      )
    )

    return {
      templateItems,
      customTemplateItems,
    }
  },

  async getAllTemplates({}, { blueprint }) {
    const documents = Document.query()
      .with('company_document_template_allotments')
      .where((document) => {
        if (
          (document.document_type !== DocumentType.TEMPLATE && !document.use_as_template) ||
          document.document_blueprint_id !== blueprint.id ||
          document.availability === DocumentAvailability.UNCONFIRMED
        ) {
          return false
        }

        if (document.availability === DocumentAvailability.NONE) {
          return store.getters['auth/isAdmin']
        }
        return true
      })
      .orderBy('created')
      .all()

    return documents.map((d) => {
      let name = d.document_type === DocumentType.TEMPLATE ? d.name : d.primary_folder_name

      if (d.availability === DocumentAvailability.NONE && store.getters['auth/isAdmin']) {
        name += ' (Superadmin)'
      }

      return { text: name, value: d }
    })
  },
  async openRepoDocumentEditModal({ commit }, repoDocument) {
    commit('SET_REPO_DOCUMENT_EDIT_MODAL_DOCUMENT', repoDocument)
  },
  setFileUploadProgress({ commit }, { file, value }) {
    commit('SET_FILE_UPLOAD_PROGRESS', { file, value })
  }
}

export const getters = {
  companyId: (state) => state.companyId,
  company: (state) => state.company,
  folderId: (state) => state.folderId,
  documentBlueprint: (state) => state.documentBlueprint,
  currentFolder: (state) => state.currentFolder,
  linkedBlueprintCreateMode: (state) => state.linkedBlueprintCreateMode,
  uploadFiles: (state) => state.uploadFiles,
  linkedBlueprintUploadFiles: (state) => state.linkedBlueprintUploadFiles,
  linkedBlueprintUploadProgress: (state) => state.linkedBlueprintUploadProgress,
  linkedBlueprintTemplateItems: (state) => state.linkedBlueprintTemplateItems,
  linkedBlueprintCustomTemplateItems: (state) => state.linkedBlueprintCustomTemplateItems,
  folderError: (state) => state.folderError,
  createFolderLoading: (state) => state.createFolderLoading,
  moveToTreeItems: (state) => state.moveToTreeItems,
  folderLinks: (state, getters) => {
    const currentFolder = getters.currentFolder
    const folderIds = []
    if (currentFolder) {
      folderIds.push(currentFolder.id)

      if (currentFolder.template_folder_id) {
        folderIds.push(currentFolder.template_folder_id)
      }
    }

    if (!currentFolder || !currentFolder.template_folder_id) {
      folderIds.push(...getters.parentFolders.map((f) => f.id))
    }

    return orderBy(
      FolderLink.allFast().filter(
        (folderLink) =>
          !!folderLink.document_blueprint_id && folderIds.indexOf(folderLink.folder_id) !== -1
      ),
      (fl) => fl.menu_position
    )
  },
  topLevelFolder: (state, getters) => {
    if (
      getters.currentFolder?.folder_type === FolderType.BLUEPRINT_MENU_STRUCTURE &&
      getters.parentFolder === null
    ) {
      return getters.currentFolder
    } else {
      const result = getters.parentFolders.filter((f) => {
        const folderType = f?.folder_type
        const parentFolderType = Folder.find(f?.parent_folder_id)?.folder_type

        return (
          folderType === FolderType.BLUEPRINT_MENU_STRUCTURE &&
          parentFolderType === FolderType.MENU_GROUP
        )
      })
      return result[0]
    }
  },
  parentFolders: (state, getters) => {
    const parentFolders = []
    let parentFolder = getters.parentFolder
    while (parentFolder !== null && parentFolder.folder_type !== FolderType.MENU_GROUP) {
      parentFolders.push(parentFolder)
      parentFolder = Folder.query()
        .with('permission_role_model_associations')
        .whereId(parentFolder.parent_folder_id)
        .first()
    }
    return parentFolders
  },
  parentFolder: (state, getters) => {
    if (!getters.currentFolder) {
      return null
    }
    const parentFolder = Folder.query()
      .with('permission_role_model_associations')
      .whereId(getters.currentFolder.parent_folder_id)
      .first()
    if (parentFolder?.folder_type === FolderType.MENU_GROUP) {
      return null
    }
    return parentFolder
  },
  linkedDocumentBlueprintsWithRepositories: (state, getters) => {
    return getters.folderLinks
      .map((folderLink) => {
        const documentBlueprint = DocumentBlueprint.query()
          .whereId(folderLink.document_blueprint_id)
          .with('current_blueprint_version')
          .first()
        return documentBlueprint
      })
      .filter((blueprint) => {
        return blueprint && (
          blueprint.is_available_for_creation_in_company
          || blueprint.document_blueprint_type === DocumentBlueprintType.REPOSITORY
        )
      })
  },
  linkedDocumentBlueprints: (state, getters) => {
    const linkedBlueprints = getters.folderLinks
      .map((folderLink) => {
        const documentBlueprint = DocumentBlueprint.query()
          .whereId(folderLink.document_blueprint_id)
          .with('current_blueprint_version')
          .first()

        return documentBlueprint
      })
      .filter((blueprint) => {
        return blueprint && blueprint.is_available_for_creation_in_company
      })

    return linkedBlueprints
  },
  getFolders: (state, getters) => {
    const doesFolderMatchConditions = (folder) => {
      const isChildOfCurrentFolder = getters.currentFolder
        ? folder.parent_folder_id === getters.currentFolder.id
        : folder.parent_folder_id === null

      const isDataProcessorPermitted =
        !folder.show_only_for_data_processors || getters.company?.is_data_processor

      const isWorksCouncilPermitted =
        !folder.show_only_for_works_council || getters.company?.has_works_council

      const isFolderAccessible =
        folder.company_id === getters.companyId ||
        (folder.folder_type === FolderType.BLUEPRINT_MENU_STRUCTURE &&
          ((!!getters.menuFoldersPermissionRole &&
            !!PermissionRoleModelAssociation.allFast().find(
              (association) =>
                association.folder_id === folder.id &&
                association.permission_role_id === getters.menuFoldersPermissionRole.id
            )) ||
            (!getters.menuFoldersPermissionRole &&
              !PermissionRoleModelAssociation.allFast().find((association) => {
                return (
                  association.folder_id === folder.id &&
                  !!PermissionRole.allFast().find(
                    (role) =>
                      role.permission_role_type === PermissionRoleType.MENU_FOLDERS &&
                      association.permission_role_id === role.id
                  )
                )
              }))))

      return (
        isChildOfCurrentFolder &&
        !folder.hidden &&
        isDataProcessorPermitted &&
        isWorksCouncilPermitted &&
        isFolderAccessible
      )
    }
    return Folder.query().where(doesFolderMatchConditions).all()
  },
  menuFoldersPermissionRole: (state, getters) => {
    const licensePermissionRole = getters.company.getLicensePermissionRole()
    if (licensePermissionRole) {
      const menuFoldersPermissionRole = PermissionRole.allFast().find(
        (pr) =>
          pr.license_permission_role_id === licensePermissionRole.id &&
          pr.permission_role_type === PermissionRoleType.MENU_FOLDERS
      )
      if (menuFoldersPermissionRole) {
        return menuFoldersPermissionRole
      }
    }
  },
  repoDocumentEditModalDocument: (state) => state.repoDocumentEditModalDocument,
  createdDocuments: (state, getters) => {
    let documents = []

    if (getters.currentFolder) {
      const folderLinkDocumentIds = FolderLink.allFast()
        .filter((fl) => fl.folder_id === getters.currentFolder.id)
        .map((fl) => fl.document_id)

      const documentsForCurrentFolder = Document.query()
        .where((d) => folderLinkDocumentIds.includes(d.id))
        .with('document_blueprint')
        .with('document_blueprint.folder_links')
        .with('last_modified_by')
        .all()

      documents = orderBy(
        documentsForCurrentFolder.filter((d) => !!d),
        Document.getSortAttrs()
      )
    }

    // Get the files that should be treated as created documents
    const filesAsDocuments = getters.linkedFiles

    // Combine the documents and files
    const combinedDocuments = [...documents, ...filesAsDocuments]

    return combinedDocuments
  },
  linkedFiles: (state, getters) => {
    return getters.files.filter((f) => {
      const ffdba = FileFolderDocumentBlueprintAssociation.allFast().find(
        (ffdba) => ffdba.folder_id === getters.currentFolder.id && ffdba.file_id === f.id
      )
      if (ffdba) {
        f.linkedBlueprintName = getters.linkedDocumentBlueprints.find(
          (bp) => bp.id === ffdba.document_blueprint_id
        ).name
        f.document_blueprint = getters.linkedDocumentBlueprints.find(
          (bp) => bp.id === ffdba.document_blueprint_id
        )
        return true
      }

      return false
    })
  },
  files: (state, getters) => {
    if (!getters.currentFolder) {
      return []
    }
    return File.query()
      .where((f) => {
        return (
          f.company_id === state.companyId &&
          f.file_type === FileType.UPLOAD &&
          f.folder_id === getters.currentFolder.id
        )
      })
      .all()
  },
  repoDocuments: (state, getters) => {
    return getters.createdDocuments.filter((d) => d.document_type === DocumentType.REPOSITORY_ITEM)
  },
  isDeletionConcept: (state, getters) => {
    return getters.parentFolder?.menu_category_uuid === MenuCategoryUuid.DELETION_CONCEPT
  }
}
