import { emitter } from "@utils/global-events"


export default async function copyToClipboard(string) {
  let success = false

  if (navigator.clipboard && navigator.clipboard.writeText) {
    try {
      await navigator.clipboard.writeText(string)
      success = true

    } catch (err) {
      emitter.emit('showGlobalAlert', {
        type: 'error',
        message: $t('generic.unable_to_copy_to_clipboard'),
      })

    }

  } else {
    // Fallback for browsers without Clipboard API
    const activeElement = document.activeElement // Store the currently focused element
    const hiddenInput = document.createElement('input')
    document.body.appendChild(hiddenInput)

    hiddenInput.value = string
    hiddenInput.focus()
    hiddenInput.select()
    hiddenInput.setSelectionRange(0, 99999)

    try {
      const successful = document.execCommand('copy')
      const msg = successful ? 'successfully' : 'unsuccessfully'
      success = true

    } catch (err) {
      emitter.emit('showGlobalAlert', {
        type: 'error',
        message: $t('generic.unable_to_copy_to_clipboard'),
      })

    } finally {
      document.body.removeChild(hiddenInput)
      if (activeElement) {
        // Restore the focus to the original active element
        activeElement.focus()
      }
    }
  }

  return success ? 'success' : 'failed'

}
