import {
  TrainingSessionStatus,
} from '@enums'

import Company from './company'
import CompanyRoleScormStatus from './company-role-scorm-status'
import PlanitModel from './planit-model'
import Reminder from './reminder'
import SessionScormLink from './session-scorm-link'
import TrainingSessionCompanyRoleAssociation from './training-session-company-role-association'
import TrainingSessionInstance from './training-session-instance'

export default class TrainingSession extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      name: this.attr(),
      description: this.attr(),

      is_relative_interval: this.attr(),
      is_relative_reminder: this.attr(),

      start_date: this.attr(),
      end_date: this.attr(),
      interval: this.attr(),
      interval_unit: this.attr(),

      status: this.attr(),

      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),

      reminders: this.hasMany(Reminder, 'training_session_id'),

      session_scorm_links: this.hasMany(SessionScormLink, 'training_session_id'),
      training_session_company_role_associations: this.hasMany(TrainingSessionCompanyRoleAssociation, 'training_session_id'),

      training_session_instances: this.hasMany(TrainingSessionInstance, 'training_session_id'),

      data: this.attr(),
      error_message: this.attr(),
    }
  }

  async refreshCompanyRoleScormStatus(start_date, end_date, loadFinishedCourses = false, company_role_id = null) {
    await CompanyRoleScormStatus.deleteAll()

    const result = await this.refreshSubResource(CompanyRoleScormStatus, {
      params: {
        start_date: start_date,
        end_date: end_date,
        loadFinishedCourses: loadFinishedCourses,
        company_role_id: company_role_id
      }
    })

    await this.updateAttr( { contentLoaded: true } )

    return result

  }

  async sendMail() {
    const response = await TrainingSession.api().patch( this.url() + '/send-mail')
    return response.data
  }

  async addPermissionRoles(role_ids) {
    const data = {
      role_ids: role_ids,
    }
    const response = await TrainingSession.api().patch( this.url() + '/assign-permission', data)
    return response?.response?.data
  }

  loadCompanyRoleScormStatus(start_date, end_date, loadFinishedCourses = false, company_role_id = null) {
    return TrainingSession.api().get(
      this.url() + '/company-role-scorm-status',
      {
        params: {
          start_date,
          end_date,
          loadFinishedCourses,
          company_role_id,
        }
      }
    )
  }

  async addAndRemoveScormFiles(data) {
    return await TrainingSession.api().patch(this.url() + '/session-scorm-links',  data)
  }
  async patch(data){
    return await TrainingSession.api().patch(this.url(), data)
  }
  async updateInviteParticipants(userIds, emails, shouldSendEmail = false) {
    const data = {
      user_ids: userIds,
      emails: emails,
      should_send_mail: shouldSendEmail,
    }
    return await TrainingSession.api().patch(this.url() + '/assign-participants', data)
  }

  getStatusColor() {
    switch (this.status) {
      case TrainingSessionStatus.ACTIVE:
        return 'primary'
      case TrainingSessionStatus.INACTIVE:
        return 'error'
      case TrainingSessionStatus.CLOSED:
        return 'success-lighten-1'
      default:
        return 'grey-darken-1'
    }
  }
}

TrainingSession.entity = 'training-sessions'
