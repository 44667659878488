import axios from 'axios'

import PlanitModel from './planit-model'

import { app } from '@src/main'
import store from '@state/store'

import setThemeColors from '@utils/theme'

export default class BrandSetting extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      name: this.attr(),
      name_brand: this.attr(),

      logo: this.attr(),
      logo_dark: this.attr(),
      logo_brand: this.attr(),
      logo_brand_dark: this.attr(),
      favicon: this.attr(),

      color_primary: this.attr(),
      color_success: this.attr(),
      color_error: this.attr(),
      color_warning: this.attr(),

      color_login_background: this.attr(),
      color_appbar: this.attr(),
      color_background: this.attr(),

      tos_file: this.attr(),
      tos_text: this.attr(),
      dpi_file: this.attr(),
      dpi_text: this.attr(),
      imprint_file: this.attr(),
      imprint_text: this.attr(),
      training_pricelist_file: this.attr(),

      disable_registration: this.attr(),

      enable_info_center: this.attr(),
      enable_dpd: this.attr(),
      enable_trainings: this.attr(),
      lock_enable_trainings: this.attr(),
      enable_rollouts: this.attr(),

      enable_changelog: this.attr(),

      mail_server: this.attr(),
      mail_port: this.attr(),
      mail_use_tls: this.attr(),
      mail_use_ssl: this.attr(),
      mail_username: this.attr(),
      mail_password: this.attr(),
      mail_sender_name: this.attr(),
      mail_sender_email: this.attr(),

      supported_locales: this.attr(),

      support_email: this.attr(),
      support_phone: this.attr(),
      support_website: this.attr(),

      enable_support_page: this.attr(),

      currency: this.attr(),

      imprint_display_text_translations: this.attr(),
      privacy_policy_display_text_translations: this.attr(),
      terms_display_text_translations: this.attr(),

    }
  }

  setThemeColors() {
    [
      'color_primary',
      'color_success',
      'color_error',
      'color_warning',
      'color_appbar',
      'color_background',
    ].forEach((attr) => {
      let value = this[attr]

      setThemeColors({
        type: attr.split('color_')[1],
        value,
      })
    })
  }

  uploadDocument(attr, formData, onUploadProgress) {
    return axios.request({
      method: 'post',
      url: '/api' + this.url() + '/' + attr,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
    })
  }

  getLocaleSelectItems() {
    const $t = store.getters['navbar/$t']

    return this.supported_locales.map((locale) => {
      return {
        value: locale,
        text: $t('enums.supported_locale.' + locale),
      }
    })
  }

  testMailConnection() {
    return this.constructor.api().patch(this.url() + '/test-connection')
  }

}

BrandSetting.entity = 'brand-settings'
