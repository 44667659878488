import PlanitModel from './planit-model'
import ChecklistStep from './checklist-step'
import Task from './task'

export default class ChecklistStepFolderStatus extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      folder_id: this.attr(),
      status: this.attr(),

      checklist_step_id: this.attr(),
      checklist_step: this.belongsTo(ChecklistStep, 'checklist_step_id'),

      tasks: this.hasMany(Task, 'checklist_step_folder_status_id'),
    }
  }

  static async getBy(folderId) {
    return await ChecklistStepFolderStatus.api().get(this.entity + '/' + folderId)
  }
}

ChecklistStepFolderStatus.entity = 'checklist-step-folder-statuses'
