<script>
import axios from 'axios'
import {
  set,
} from 'lodash'

import store from '@state/store'

import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';


export default {
  name: 'ProfilePicture',
  components: {
    Cropper,
  },
  props: {
    model: { type: Object, required: true },
    attr: { type: String, required: false, default: 'image' },
    editable: { type: Boolean, required: false, default: false },
    width: { type: String, required: false, default: null },
    thumbnail: { type: Boolean, required: false, default: false },
    border: { type: Boolean, required: false, default: false },
    rounded: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    placeholderIcon: { type: String, required: false, default: null },
    placeholderType: { type: String, required: false, default: 'user' }, // or 'company', 'folder', 'blog'
    aspectRatio: { type: Number, required: false, default: 1 },
    dark: { type: Boolean, required: false, default: false },
    uploadAttr: { type: String, required: false, default: null },
    uploadConfig: { type: Object, required: false, default: () => ({}) },
    afterSaveCallback: { type: Function, required: false, default: null },
    removeImageCallback: { type: Function, required: false, default: null },
  },
  emits: ['after-refresh'],
  data: function () {
    return {
      showCropper: false,
      showUpload: false,
      error: null,
      cropImgSrc: null,
      uploadFile: null,
      uploadProgress: null,

      currentCropperCanvas: null,

    }
  },
  computed: {
    imgSrc() {
      if (this.placeholderType === 'blog') {
        return '/blog-images/' + this.getValueDeep(this.model, this.attr) + '.webp'

      } else if (this.placeholderType === 'downloadResource') {
        return '/download-resources-images/' + this.getValueDeep(this.model, this.attr) + '.webp'

      }

      if (this.getValueDeep(this.model, this.attr)) {
        return '/api/images/' + this.getValueDeep(this.model, this.attr) + '.png'
      }

      return require('@/public/user_placeholder.png')

    },
    imgStyles() {
      const styles = {}

      if (this.rounded) {
        styles['border-radius'] = '50%'
      }

      if (this.disabled) {
        styles['-webkit-filter'] = 'grayscale(100%)'
        styles.filter = 'grayscale(100%)'
      }

      return styles
    },
    iconStyles() {
      const styles = {}

      if (!this.getValueDeep(this.model, this.attr)) {
        styles['font-size'] = this.width ? 'max(1rem, calc(' + this.width + ' * 2 / 3))' : 'inherit'
      }

      return styles
    },
    containerStyles() {
      const styles = {}

      if (this.rounded) {
        styles['border-radius'] = '50%'
      }

      if (this.width) {
        styles.width = this.width
        if (this.aspectRatio === 1) {
          styles.height = this.width
        } else {
          styles['min-height'] = '2rem'
        }
      }

      return styles
    },
    imgClasses() {
      if (this.border) {
        return ['img-thumbnail']
      } else if (this.thumbnail) {
        return ['img-thumbnail']
      }

      return []
    },
    currentUser() {
      return store.getters['auth/currentUser']
    },
    placeholderIconComputed() {
      if (this.placeholderIcon) {
        return this.placeholderIcon
      }

      if (this.placeholderType === 'company') {
        if (this.model.is_company_group) {
          return 'fad fa-sitemap'
        }
        return 'fal fa-landmark'

      } else if (this.placeholderType === 'vendor') {
        return 'fal fa-tools'

      } else if (this.placeholderType === 'image') {
        return 'fal fa-image'

      } else if (this.placeholderType === 'blog') {
        return 'fal fa-blog'

      }

      return ''
    },
  },
  created() {},
  methods: {
    handleFileChange(e) {
      try {
        const file = e.target.files[0]
        this.uploadFile = file
        if (file.type.indexOf('image/') === -1) {
          this.uploadFile = null
          this.error = this.$t('components.profile_picture.error_not_image')

          return
        }

        if (typeof FileReader === 'function') {
          const reader = new FileReader()
          reader.onload = (event) => {
            const image = new Image()
            image.onload = async (imageEvent) => {
              // resize image
              const canvas = document.createElement('canvas')
              const ctx = canvas.getContext('2d')
              const maxSize = 1024

              // set to half-size if width and height are not set (svg in firefox)
              let width = image.width || maxSize / 2
              let height = image.height || maxSize / 2

              // square if aspectRatio is 1
              if (this.aspectRatio === 1) {
                if (width > height) {
                  if (width > maxSize) {
                    height *= maxSize / width
                    width = maxSize
                  }
                } else {
                  if (height > maxSize) {
                    width *= maxSize / height
                    height = maxSize
                  }
                }
              }

              // embed in square container
              canvas.width = width > height ? width : height
              canvas.height = height > width ? height : width

              ctx.fillStyle = 'rgba(0, 0, 0, 0)'
              ctx.fillRect(0, 0, canvas.width, canvas.height)

              let posX = 0
              let posY = 0

              // draw centered if not square
              if (width > height) {
                posY = width / 2 - height / 2
              } else if (height > width) {
                posX = height / 2 - width / 2
              }

              const promises = []

              // set svg width and height attributes manually because of longstanding firefox bug
              if (file.name.endsWith('svg')) {
                const parser = new DOMParser()
                // extract svg DOM
                const imageXmlSrc = atob(image.src.split('data:image/svg+xml;base64,')[1])
                const result = parser.parseFromString(imageXmlSrc, 'text/xml')
                const inlineSVG = result.getElementsByTagName('svg')[0]

                // add the attributes Firefox needs. These should be absolute values, not relative
                inlineSVG.setAttribute('width', width + 'px')
                inlineSVG.setAttribute('height', height + 'px')

                // convert the SVG to a data uri
                const svg64 = btoa(new XMLSerializer().serializeToString(inlineSVG))
                const image64 = 'data:image/svg+xml;base64,' + svg64
                const image64El = document.createElement('img')
                image64El.src = image64

                // make sure we wait for onload event
                promises.push(
                  new Promise((resolve) => {
                    image64El.onload = () => {
                      ctx.drawImage(image64El, posX, posY, width, height)
                      resolve()
                    }
                  })
                )
              } else {
                ctx.drawImage(image, posX, posY, width, height)
              }

              await Promise.all(promises)

              this.uploadProgress = null

              this.$nextTick(() => {
                this.showCropper = true
              })

              if (file.name.indexOf('svg') !== -1) {
                this.cropImgSrc = canvas.toDataURL('image/svg+xml')
              } else {
                this.cropImgSrc = canvas.toDataURL()
              }
            }

            image.src = event.target.result
          }

          reader.onprogress = (event) => {
            this.uploadProgress = Math.round((100 * event.loaded) / event.total)
          }

          reader.readAsDataURL(file, file.name)

        } else {
          this.uploadFile = null
          this.error = this.$t('components.profile_picture.error_no_filereader')
        }
      } catch (error) {
        this.uploadFile = null
        this.error = this.$t('components.profile_picture.error_generic')
      }
    },
    async upload() {
      const dataUri = this.currentCropperCanvas.toDataURL()
      const file = this.dataURItoFile(dataUri, this.uploadFile.name)

      const formData = new FormData()
      formData.append('file', file)

      const uploadUrl = '/api' + this.model.url() + '/' + (this.uploadAttr || this.attr)

      const resp = await axios
        .post(uploadUrl, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (e) => {
            // onProgress
            this.uploadProgress = Math.round((100 * e.loaded) / e.total)
          },
          ...this.uploadConfig,
        })
        .catch((e) => {
          this.error = e.response.data.message
          this.uploadProgress = null
          this.uploadFile = null
          this.$refs.file.value = null
        })

      if (resp) {
        const updateData = {}
        set(updateData, this.attr, resp.data.image)

        const result = await this.model.updateAttr(updateData)
        if (result) {
          if (this.afterSaveCallback) {
            await this.afterSaveCallback(result, resp)

          } else {
            if (
              this.model.constructor.entity === 'users' &&
              this.model.id === this.currentUser.id
            ) {
              store.dispatch('auth/validate')
            } else {
              this.model.refresh().then((result) => {
                this.$emit('after-refresh', result)
              })
            }
          }
        }

        this.uploadProgress = null
        this.uploadFile = null
        this.showCropper = false
        this.showUpload = false
        this.$refs.file.value = null
      }

    },
    dataURItoFile(dataURI, name) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString
      byteString =
        dataURI.split(',')[0].indexOf('base64') >= 0
          ? atob(dataURI.split(',')[1])
          : unescape(dataURI.split(',')[1])

      // separate out the mime component
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

      // write the bytes of the string to a typed array
      const ia = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      return new File([ia], name, { type: mimeString })
    },
    async removeImage() {
      const data = {}
      data[this.attr] = null

      let result
      if (this.removeImageCallback) {
        result = await this.removeImageCallback(data)
      } else {
        result = await this.model.sync(null, data)
      }

      if (result) {
        this.showUpload = false
        this.$emit('after-refresh', result)
      }

    },
    overlayClick() {
      this.showUpload = true
      this.$nextTick(() => {
        if (!this.getValueDeep(this.model, this.attr)) {
          this.$refs.file.click()
        }
      })
    },
    onDialogInput(value) {
      if (value === false) {
        this.showUpload = false
        this.showCropper = false
      }
    },
    getValueDeep(data, attr) {
      if (attr.indexOf('.') !== -1) {
        const attrSplit = attr.split('.')
        let ret = data

        if (!ret) {
          return ret
        }

        attrSplit.forEach((a) => {
          if (attrSplit.indexOf(a) === attrSplit.length - 1) {
            ret = ret[a]
          } else {
            if (!ret[a]) {
              ret = {}
            } else {
              ret = ret[a]
            }
          }
        })

        return ret
      } else {
        return data[attr]
      }
    },
    onCropperChange({ coordinates, canvas}) {
      this.currentCropperCanvas = canvas
    },
  },
}
</script>

<template>
  <div>
    <v-hover v-slot="{ isHovering, props }">
      <div
        v-bind="props"
        class="relative-container"
      >
        <div
          v-if="!showCropper"
          :style="containerStyles"
        >
          <img
            v-if="getValueDeep(model, attr) || placeholderType === 'user'"
            :src="imgSrc"
            :class="imgClasses"
            :style="imgStyles"
            class="img-responsive"
          />
          <div
            v-if="!getValueDeep(model, attr) && placeholderType !== 'user'"
            class="d-flex align-center justify-space-around"
            :class="imgClasses"
            style="height: 100%"
          >
            <PlanitIcon
              :color="!dark || thumbnail ? 'primary' : 'white'"
              :style="iconStyles"
              :icon="placeholderIconComputed"
            ></PlanitIcon>
          </div>
        </div>

        <PlanitDialog
          v-if="showCropper || showUpload"
          :model-value="true"
          content-class="bg-white"
          max-width="80rem"
          @update:model-value="onDialogInput"
        >
          <div>
            <div v-if="showCropper">
              <Cropper
                :src="cropImgSrc"
                :stencil-props="{
                  aspectRatio,
                }"
                background-class="bg-white"
                style="height: 50vh; width: 100%;"
                @change="onCropperChange"
              ></Cropper>
            </div>

            <div
              v-if="showUpload"
              class="pa-6"
            >
              <div
                v-if="!showCropper"
                class="d-flex align-center justify-space-around mb-4"
              >
                <img
                  v-if="getValueDeep(model, attr) || placeholderType === 'user'"
                  :src="imgSrc"
                  :class="imgClasses"
                  :style="imgStyles"
                  class="img-responsive"
                  style="max-width: 50%;"
                />
                <div
                  v-if="!getValueDeep(model, attr) && placeholderType !== 'user'"
                  class="d-flex align-center justify-space-around"
                  :class="imgClasses"
                  style="height: 100%"
                >
                  <PlanitIcon
                    :color="!dark ? 'primary' : 'white'"
                    :style="iconStyles"
                    :icon="placeholderIconComputed"
                  ></PlanitIcon>
                </div>
              </div>

              <v-alert
                v-if="error"
                class="mb-2"
                type="error"
              >
                {{ error }}
              </v-alert>

              <v-progress-linear
                v-if="uploadProgress"
                class="mb-2"
                :model-value="uploadProgress"
              ></v-progress-linear>

              <div class="d-flex align-center justify-end">
                <PlanitButton
                  v-if="getValueDeep(model, attr)"
                  variant="text"
                  class="mr-4"
                  @click="removeImage"
                >
                  <PlanitIcon
                    class="mr-1"
                    size="small"
                    icon="fal fa-trash-alt"
                  />
                  {{ $t('components.profile_picture.remove_image') }}
                </PlanitButton>

                <PlanitButton
                  v-if="!uploadFile"
                  color="primary"
                  tag="label"
                  for="file"
                  class="cursor-pointer"
                >
                  <PlanitIcon
                    class="mr-1"
                    size="small"
                    icon="fal fa-file-upload
                  "/>
                  <span>{{ $t('components.profile_picture.update_image') }}</span>
                </PlanitButton>
                <input
                  id="file"
                  ref="file"
                  type="file"
                  class="uploadinput"
                  @change="handleFileChange"
                />

                <PlanitButton
                  v-if="showCropper"
                  color="primary"
                  @click="upload"
                >
                  <PlanitIcon
                    class="mr-1"
                    size="small"
                    icon="fal fa-file-upload
                  "/>
                  <span>{{ $t('generic.upload') }}</span>
                </PlanitButton>
              </div>
            </div>
          </div>
        </PlanitDialog>

        <PlanitButton
          v-if="editable && isHovering && !showUpload"
          :style="containerStyles"
          variant="text"
          class="editable-overlay"
          style="color: #fff"
          @click="overlayClick"
        >
          <PlanitIcon icon="fal fa-edit"></PlanitIcon>

        </PlanitButton>
      </div>
    </v-hover>
  </div>
</template>

<style>
.img-responsive {
  flex: 1 0 0px;
  max-width: 100%;
}

.relative-container {
  position: relative;
}

.editable-overlay {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.3);
}

.cursor-pointer {
  cursor: pointer;
}

.cropper-bg {
  background-repeat: repeat;
}
</style>
