
<script>
export default {
  props: {
    modelValue: {
      type: [String, Number, null],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    prefix: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    prependInnerIcon: {
      type: String,
      default: ''
    },
    appendIcon: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'outlined'
    },
    density: {
      type: String,
      default: 'compact'
    },
    rules: {
      type: Array,
      default: () => []
    },
    counter: {
      type: [Boolean, Number],
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    },
    persistentPlaceholder: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    min: {
      type: [String, Number],
      default: null
    },
  },
  data() {
    return {
      localModelValue: this.modelValue
    }
  },
  emits: ['update:modelValue'],
  methods: {
    updateModelValue(value) {
      if (this.type === 'number') {
        if (isNaN(parseInt(value))) {
          value = null
        }
        if (this.min !== null && value < this.min && value !== null) {
          value = this.min
        }
      }
      this.localModelValue = value
      this.$emit('update:modelValue', value)
    }
  },
  watch: {
    modelValue(v, ov) {
      this.localModelValue = v
    },
  },
}
</script>

<template>
  <v-text-field
    v-bind="$attrs"
    :model-value="localModelValue"
    @update:modelValue="updateModelValue"
    :label="label"
    :placeholder="placeholder"
    :type="type"
    :prefix="prefix"
    :suffix="suffix"
    :prepend-inner-icon="prependInnerIcon"
    :append-icon="appendIcon"
    :clearable="clearable"
    :hide-details="hideDetails"
    :disabled="disabled"
    :readonly="readonly"
    :variant="variant"
    :density="density"
    :persistent-placeholder="persistentPlaceholder"
    :rules="rules"
    :counter="counter"
    :error="error"
    :error-messages="errorMessages"
    :color="color"
  >
    <template v-for="(slot, name) in $slots" :slot="name" v-slot:[name]="slotProps">
      <slot :name="name" v-bind="slotProps || {}"></slot>
    </template>
  </v-text-field>
</template>
