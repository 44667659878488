<script>
import { orderBy } from 'lodash'

import FolderChecklistStep from '@components/checklist/folder-checklist-step'
import PlanitFile from '@components/planit-file'
import BaseCard from '@components/card/base-card'
import PlanitTask from '@components/card/planit-task'

import {
  ChecklistStepStatus,
  ChecklistStepType,
  TaskStatus,
} from '@enums'

import Checklist from '@state/models/checklist'
import ChecklistStepFolderStatus from '@state/models/checklist-step-folder-status'
import { Document } from '@state/models/document'
import FolderLink from '@state/models/folder-link'
import Task from '@state/models/task'
import CompanyLink from '@state/models/company-link'
import { emitterEventMixin } from '@utils/global-events'


export default {
  name: 'ChecklistGuide',
  components: {
    PlanitFile,
    FolderChecklistStep,
    BaseCard,
    PlanitTask,
  },
  props: {
    folderId: Number,
    canEdit: { type: Boolean, required: false, default: false },
    inRightDrawer: { type: Boolean, required: false, default: false },
  },
  mixins: [emitterEventMixin],
  data() {
    return {
      loading: false,

      ChecklistStepStatus,
      ChecklistStepType,
      activeStep: 1,
      step1completed: false,
    }
  },
  async beforeMount() {
    this.loading = true

    const result = await ChecklistStepFolderStatus.$all({ folder_id: this.folderId })

    if (result.response.data?.[0]?.checklist_step?.checklist_id) {
      const checklistResult = await Checklist.$find(result.response.data[0].checklist_step.checklist_id)
      this.$store.dispatch('currentDocument/setCurrentChecklistGuide', checklistResult.entities.checklists[0])
    }

    if (this.currentStep) {
      this.activeStep = this.currentStep.checklist_step.position
    }

    if (this.statuses.some(status => status.checklist_step.data?.rollout?.enabled)) {
      await this.company.refreshSubResource(CompanyLink)
    }

    this.loading = false

    this.$onEvent('queryTask', (taskId) => {
      const thisTaskIds = this.tasks.map(t => t.id)
      if (thisTaskIds.includes(taskId)) {
        this.tasks.find(t => t.id === taskId).refresh()
      }

    })
  },
  computed: {
    completedStepsCount() {
      return this.statuses.filter((s) => s.status === ChecklistStepStatus.FINISHED).length
    },
    currentStep() {
      return this.statuses.find((s) => s.status === ChecklistStepStatus.STARTED)
    },
    company() {
      return this.$store.getters['navbar/selectedCompany']
    },
    currentFolder() {
      return this.$store.getters['files/currentFolder']
    },
    currentFolderDocuments() {
      const folderLinkDocumentIds = FolderLink.allFast()
        .filter((fl) => fl.folder_id === this.currentFolder.id)
        .map((fl) => fl.document_id)

      return Document.query()
        .where((d) => folderLinkDocumentIds.includes(d.id))
        .with('document_blueprint')
        .with('document_blueprint.folder_links')
        .with('last_modified_by')
        .all()
    },
    linkedDocumentBlueprints() {
      return this.$store.getters['files/linkedDocumentBlueprints']
    },
    statuses() {
      const statuses = orderBy(
        ChecklistStepFolderStatus
          .query()
          .where((s) => s.folder_id === this.folderId)
          .with('checklist_step')
          .all(),
        ['checklist_step.position'],
        ['asc']
      )

      return statuses
    },
    tasks() {
      const allChecklistStepFolderStatusIds = this.statuses.map((s) => s.id)
      const tasks = Task
        .query()
        .where(task => allChecklistStepFolderStatusIds.includes(task.checklist_step_folder_status_id))
        .with('reminder')
        .with('reminder.timers')
        .with('task_company_role_associations')
        .with('task_company_role_associations.company_role')
        .with('task_company_role_associations.company_role.user')
        .with('task_company_role_associations.company_role.invite')
        .all()

      return tasks
    },
    showGuideChecklistDrawer() {
      return this.$store.getters['navbar/showGuideChecklistDrawer']
    },
  },
  methods: {
    nextStep({ finishTask = false}) {
      if (finishTask) {
        this.tasks.forEach((task) => {
          const myTaskCompanyRoleAssociation = task.task_company_role_associations.find(
            (tcra) => tcra.company_role.id === this.$store.getters['navbar/myCompanyRole'].id
          )
          const patchData = {
            task_company_role_association_id: myTaskCompanyRoleAssociation?.id,
            status: TaskStatus.DONE,
          }
          task.sync(null, patchData)
        })
      }

      this.activeStep++

    },
  },
}
</script>
<template>
  <div v-if="!loading">
    <div v-if="tasks.length > 0" class="mb-4">
      <div
        v-for="task in tasks"
        :key="task.id"
      >
        <PlanitTask
          :task="task"
          :can-edit="canEdit"
          :flex="!inRightDrawer"
          add-section-bottom-margins
          no-card
          hide-name
          hide-assigned-on
          show-reminders
          hide-chip
          show-assignees
          include-status
        ></PlanitTask>
      </div>
    </div>

    <BaseCard no-padding>
      <v-stepper-vertical
        v-model="activeStep"
        non-linear
        flat
        class="pb-0"
        color="primary"
      >
        <FolderChecklistStep
          v-for="status, index in statuses"
          :key="'status'+ index"
          :status="status"
          :editable="completedStepsCount >= 1 && canEdit"
          :last-step="status === statuses[statuses.length - 1]"
          :active="activeStep === status.checklist_step.position"
          :value="index + 1"
          @finished="nextStep"
        ></FolderChecklistStep>
      </v-stepper-vertical>
    </BaseCard>
  </div>
</template>
