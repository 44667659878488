import PlanitModel from './planit-model'
import TaskCompanyRoleAssociation from '@state/models/task-company-role-association'
import Approval from '@state/models/approval'

export default class ApprovalComment extends PlanitModel {
    static fields() {
        return {
          ...super.fields(),

          text: this.attr(''),
          is_system_message: this.boolean(null),
          system_message_data: this.attr(null),
          is_deleted: this.boolean(null),

          approval_id: this.attr(null),
          approval: this.belongsTo(Approval, 'approval_id'),

          task_company_role_association_id: this.attr(null),
          task_company_role_association: this.belongsTo(TaskCompanyRoleAssociation, 'task_company_role_association_id'),
        }
      }
    }

    ApprovalComment.entity = 'approval-comments'