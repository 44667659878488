import PlanitModel from '@state/models/planit-model'
import Company from '@state/models/company'
import DocsAuditProjectQuestion from '@state/models/audit/docs-audit-project-question'

export default class DocsAuditProject extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      status: this.attr(),

      company: this.belongsTo(Company, 'company_id'),
      company_id: this.attr(),

      docs_audit_project_questions: this.hasMany(DocsAuditProjectQuestion, 'docs_audit_project_id'),

      project: this.attr(),
    }
  }
}

DocsAuditProject.entity = 'audit/docs-audit-projects'
