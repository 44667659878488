import { mapGetters, mapActions } from 'vuex'

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'authRegister', 'setLocale'])
export const authComputed = mapGetters('auth', [
  'loggedIn',
  'currentLocale',
  'currentUser',
  'isAdmin',
  'activeLicensePermissionRole',
  'activeLicenseType',
  'activeLicense',
])

export const userActions = mapActions('users', ['GET_USER_LIST', 'GET_USER'])
export const userComputed = mapGetters('users', ['USER_LIST', 'USER'])

export const syncQueueGetters = mapGetters('syncQueue', [
  'getQueue',
  'queueLength',
  'isLoading',
  'showQueueInfoText',
])
export const syncQueueActions = mapActions('syncQueue', [
  'queue',
  'sync',
  'cancel',
  'replaceModel',
  'handleQueue',
  'handleQueueNow',
])

export const debugGetters = mapGetters('debug', [
  'debug',
  'debugTheme',
  'instanceMode',
  'showDebugInfo',
  'hideDebugFooter',
])
export const debugActions = mapActions('debug', ['setDebug', 'setDebugTheme'])

export const globalGetters = mapGetters('global', [
  'sdbaHidden',
  'canCompanyUseGptFeature',
  'getGptPrcaForCompanyId',
])
export const globalActions = mapActions('global', ['setSdbaHidden', 'setGptPrcaForCompanyId'])

export const infoCenterGetters = mapGetters('infoCenter', ['pages', 'home'])
export const infoCenterActions = mapActions('infoCenter', ['resolvePages'])

export const dsbCommentGetters = mapGetters('dsbComment', ['pages', 'home'])
export const dsbCommentActions = mapActions('dsbComment', ['resolvePages'])

export const navbarGetters = mapGetters('navbar', [
  'isAdmin',
  'companies',
  'selectedCompany',
  'appSelectItems',
  'navbarHeight',
  'navbarPxHeight',
  'footerHeight',
  'navDrawerWidth',
  'rightDrawerWidth',
  'selectedCompanyMeCanEdit',
  'showCompanySelect',
  'navbarLoading',
  'navTreeLoading',
  'contentLoading',
  'showProfile',
  'companyTreeItems',
  'companyTreeOpen',
  'allCompanies',
  'showApprovalDrawer',
  'showChecklistDrawer',
  'hasSmallScreen',
  'hasVerySmallScreen',
  'isDSKDemoAccount',
  'showRightDrawer',
])
export const navbarActions = mapActions('navbar', [
  'setShowApprovalDrawer',
  'setShowChecklistDrawer',
  'setShowNavigationDrawer',
  'setShowGuideChecklistDrawer',
])

export const currentDocumentGetters = mapGetters('currentDocument', [
  'document',
  'pageRouteLoading',
  'folderVendorAssociations',
  'showDocumentResults',
  'resultsTabDisabled',
  'selectGroups',
  'documentVersion',
  'documentBlueprint',
  'sdbas',
  'resultSections',
  'showInResultAnswerSections',
  'topLevelSectionItems',
  'topLevelAnswers',
  'selectedTopLevelAnswer',
  'currentProgress',
  'canEditDocument',
  'canAssignDocument',
  'company',
  'showAllPages',
  'onePageMode',
  'showEmptyOnly',
  'showRequiredOnly',
  'allExpanded',
  'currentPage',
  'documentVersionBlueprintVersion',
  'sortedAndMappedAnswers',
  'filteredAnswerItems',
  'sectionItems',
  'sectionItemsThisPage',
  'mappedSelectGroups',
])
export const currentDocumentActions = mapActions('currentDocument', [
  'setCurrentDocumentId',
  'reloadDocument',
  'setPageRouteLoading',
  'querySelectGroups',
  'setShowAllPages',
  'setShowEmptyOnly',
  'setShowRequiredOnly',
])

export const checklistGetters = mapGetters('checklist', [
  'activeChecklist',
  'activeListChecklist',
  'activeTourChecklist',
])

export const approvalGetters = mapGetters('approval', [
  'approval',
  'taskUsers',
  'approvalComments',
  'taskCompanyRoleAssociations',
  'taskCompanyRoles',
  'tasks',
  'ownerTask',
  'writerTasks',
  'reviewerTasks',
  'approverTasks',
  'currentUserTasks',
])
export const approvalActions = mapActions('approval', [
  'fetchApprovalByDocumentId',
  'fetchApprovalByBlueprintId',
  'createApproval',
  'updateApproval',
  'addComment',
  'deleteComment',
  'fetchComments',
])
