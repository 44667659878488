
<script>

import LicenseLabel from '@components/license-label'
import Promotion from '@state/models/promotion'
import {
  PaymentState,
  OrderType,
} from '@enums'

import store from '@state/store'
import '@utils/filters'


export default {
  name: 'PlanitBasketContent',
  components: {
    LicenseLabel,
  },
  props: {
    basket: { type: Object, required: true },
    hideButtons: { type: Boolean, required: false, default: false },
    promotion: { type: Object, required: false, default: () => ({}) },
  },
  emits: ['basketDelete'],
  data: function () {
    return {
      PaymentState,
      OrderType,
      showCheckoutModal: false,
      fetchedPromotion : null,
    }
  },
  watch: {
    promotion: {
      deep: true,
      async handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fetchedPromotion = newVal
        }
      }
    },
  },
  computed: {
    referenceNumber() {
      return this.basket.getReferenceNumber && this.basket.getReferenceNumber()
    },
    isDie3Basket() {
      return (
        this.basket &&
        this.basket.orders.length > 0 &&
        this.basket.orders.some(o => o.order_type === OrderType.DIE3)
      )
    },
    isAdmin() {
      return store.getters['auth/isAdmin']
    },
    isPromotionValid() {
      return this.promotionData && Object.keys(this.promotionData).length > 0;
    },
    promotionData() {
      return this.fetchedPromotion || this.promotion
    },

  },
  async created() {
    if(this.basket && this.basket.promotion) {
      this.fetchedPromotion = this.basket.promotion
    } else if(this.basket && this.basket.data && this.basket.data.promotionCode) {
      const response =  await Promotion.getPromotionByCodeAndType(this.basket.data.promotionCode, this.getLicenseType() )
      if (response && response.response && response.response.data) {
        this.fetchedPromotion = response.response.data
      }
    }
  },
  methods: {
    async deleteBasket() {
      await this.basket.delete()
      this.$emit('basketDelete')
    },
    async markPaid() {
      await this.basket.sync(null, { payment_state: PaymentState.PAID })
    },
    getLicenseType() {
      return this.isDie3Basket ? OrderType.DIE3 : this.basket.orders[0]?.data?.license_type
    },
    originalPrice() {
      return this.basket.orders.reduce(
        (acc, order) => acc + (order.price_custom * 12),
        0
      )
    },
    reducedPrice() {
      return this.basket.orders.reduce(
        (acc, order) => acc + (order.price_custom * (12 - this.promotionData.free_months)),
        0
      )
    },
  },
}
</script>

<template>
  <div>
    <div
      v-if="basket.created || referenceNumber || basket.payment_state"
      class="d-flex align-center justify-space-between mb-4"
    >
      <div class="font-weight-medium">
        <span class="text-grey-darken-2">
          {{ $filters.formatDate(basket.created) }}<br>
        </span>
        <span class="text-grey">
          {{ referenceNumber }}<br>
        </span>
        <span
         v-if="(!basket.payment_state || isAdmin) && !hideButtons"
         class="text-grey"
        >
          {{ promotionData?.code }}
        </span>
      </div>

      <div v-if="basket.payment_state">
        <PlanitChip
          size="small"
          :color="
            basket.payment_state === PaymentState.PAID ?
              'success' :
            basket.payment_state === PaymentState.SUBMITTED ?
              'primary' :
              'default'
          "
          label
        >
          {{ $t('enums.payment_state.' + basket.payment_state) }}
        </PlanitChip>
      </div>
    </div>

    <div
      v-for="order in basket.orders"
      :key="order.id"
      class="d-flex align-center justify-space-between py-1"
    >
      <h3>
        <span
          v-if="order.data && order.data.license_type"
        >
          <i18n-t
            keypath="admin.licenses.license_text"
          >
            <template #license_type>
              <LicenseLabel
                :license-type="
                  order.data.license_type
                "
              ></LicenseLabel>
            </template>
          </i18n-t>
          <span v-if="order.order_type === OrderType.LICENSE">({{ $t('checkout.one_year_license') }})</span>
        </span>
        <div v-else>
          {{ $t('enums.order_type.' + order.order_type) }}
        </div>
      </h3>
      <!-- Currency als Platzhalter klappt nicht mit i18-plugin deshalb so...-->
      <h3 class="font-weight-regular">
        <template v-if="isPromotionValid">
          <div class="text-right">
            <span> {{ $filters.formatCurrency(0) }}</span>
            <span> {{ $t('checkout.promotion_free_text', { freePromoMonths: promotionData.free_months } ) }} </span><br>
          </div>
          <span> {{ $t('checkout.promotion_free_text_2') }} </span>
          <span> {{ $filters.formatCurrency(order.price_custom) }} </span>
          <span> {{ $t('checkout.per_month') }} </span>
        </template>
        <template v-else>
          {{ $filters.formatCurrency(order.price_custom) }} <span v-if="order.order_type === OrderType.DIE3"> {{ $t('checkout.per_month') }}</span>
        </template>
      </h3>

    </div>

    <v-divider class="mt-1 mb-4"></v-divider>

    <div class="d-flex align-center justify-space-between">
      <h3>{{ $t('checkout.total_amount') }}</h3>

      <h3 v-if="!isPromotionValid" class="text-right">
        {{ $filters.formatCurrency(basket.total_price) }}<span v-if="isDie3Basket"> {{ $t('checkout.per_year') }}</span>
      </h3>
      <h3 v-else class="text-right">
        <s class="text-grey-darken-1">{{ $filters.formatCurrency(originalPrice()) }} </s><br>{{ $filters.formatCurrency(reducedPrice()) }} <span> {{ $t('checkout.per_year_first') }}</span>      </h3>
    </div>

    <div
      v-if="(!basket.payment_state || isAdmin) && !hideButtons"
      class="pt-4 d-flex align-center justify-end flex-wrap"
    >
      <PlanitButton
        v-if="!basket.payment_state || basket.payment_state === PaymentState.SUBMITTED"
        variant="outlined"
        size="small"
        class="mr-4"
        @click="deleteBasket"
      >
        {{ $t('profile.dashboard.cancel_checkout') }}
      </PlanitButton>
      <PlanitButton
        v-if="isAdmin"
        size="small"
        color="error"
        class="mr-4 mb-2"
        @click="markPaid"
      >
        {{ $t('components.planit_basket_content.mark_paid') }}
      </PlanitButton>
      <PlanitButton
        v-if="!basket.payment_state"
        size="small"
        color="primary"
        @click="$store.commit('global/SET_SHOW_CHECKOUT_MODAL', true)"
      >
        {{ $t('profile.dashboard.continue_checkout') }}
      </PlanitButton>
    </div>
  </div>
</template>
