<script>

import format from 'date-fns/format'

import PlanitTextField from '@components/vuetify/planit-text-field'

import {
  dailyDateListFromRange,
} from '@utils/date-helpers'


export default {
  name: 'DateTimePicker',
  components: {
    PlanitTextField,
  },
  props: {
    value: {
      type: [Object, String],
      required: false,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    density: {
      type: String,
      default: 'compact',
    },
    variant: {
      type: String,
      default: 'outlined',
    },
    max: {
      type: [String, Date],
      default: null,
    },
    min: {
      type: [String, Date],
      default: null,
    },
    multiple: {
      type: [Boolean, String],
      default: false,
    },
    type: {
      type: String,
      default: 'date',
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    asString: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:value'],
  data() {
    return {
      showTimePicker: false,
    }
  },
  computed: {
    labelComputed() {
      return this.label || (
        this.type === 'date'
          ? this.$t('components.input.date')
          : this.$t('components.input.time')
      )
    },
    date() {
      const value = typeof this.value === 'string'
        ? new Date(this.value)
        : this.value

      if (this.multiple === 'range') {
        if (value && value.start && value.end) {
          return dailyDateListFromRange(value.start, value.end)
        } else {
          return []
        }
      }

      return value

    },
    time() {
      const value = typeof this.value === 'string'
        ? new Date(this.value)
        : this.value

      return value && (
        value.time || value || ''
      )
    },
    internalTime: {
      get() {
        return this.time && format(this.time, 'HH:mm')
      },
      set(newValue) {
        const [hours, minutes] = newValue.split(':')
        const newTime = new Date(this.value)

        newTime.setHours(hours)
        newTime.setMinutes(minutes)

        this.$emit('update:value', newTime)
      },
    },
  },
  created() {
  },
  methods: {
    updateDate(newValue) {
      if (this.type !== 'date') {
        return
      }

      if (this.multiple === 'range' && Array.isArray(newValue)) {
        const startDate = newValue[0];
        const endDate = newValue[newValue.length - 1]

        this.$emit('update:value', {
          start: startDate,
          end: endDate,
        })

      } else if (!this.multiple) {
        this.$emit('update:value', newValue)

      }
    },
  },
};
</script>

<template>
  <div>
    <v-date-input
      v-if="type === 'date'"
      :model-value="date"
      :placeholder="placeholder"
      :label="labelComputed"
      :density="density"
      :variant="variant"
      :min="min"
      :max="max"
      :multiple="multiple"
      :hide-details="hideDetails"
      :prepend-icon="null"
      :disabled="disabled"
      color="primary"
      prepend-inner-icon="fal fa-calendar-alt"
      style="min-width: 10rem;"
      @update:modelValue="updateDate"
    />
    <!-- <v-time-input
      v-if="type === 'time'"
      :model-value="time"
      :label="labelComputed"
      :placeholder="placeholder"
      :density="density"
      :variant="variant"
      :min="min"
      :max="max"
      :disabled="disabled"
      @update:modelValue="updateTime"
    /> -->
    <!-- time: <pre>{{ time }}</pre> -->
    <PlanitTextField
      v-if="type === 'time'"
      :model-value="internalTime"
      :active="showTimePicker"
      :focus="showTimePicker"
      :label="labelComputed"
      :placeholder="placeholder"
      :disabled="disabled"
      :density="density"
      :hide-details="hideDetails"
      prepend-inner-icon="fal fa-clock"
      readonly
      style="min-width: 7rem;"
    >
      <v-menu
        v-model="showTimePicker"
        :close-on-content-click="false"
        :activator="'parent'"
        transition="scale-transition"
        content-class="border-radius bg-white card-box-shadow"
      >
        <div>
          <v-time-picker
            v-if="showTimePicker"
            v-model="internalTime"
            :disabled="disabled"
            :min="min"
            :max="max"
            format="24hr"
            full-width
            color="primary"
          ></v-time-picker>
          <div class="d-flex align-center justify-end pa-2">
            <PlanitButton
              @click="showTimePicker = false"
            >
              OK
            </PlanitButton>
          </div>
        </div>
      </v-menu>
    </PlanitTextField>
  </div>
</template>

<style scoped>
.v-card {
  max-width: 400px;
  margin: auto;
}
</style>
