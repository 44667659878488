import { app } from '@src/main'
import i18n from '@src/plugins/vue-i18n'

import {
  ChecklistMode,
  PRIMA_ONBOARDING_GUIDE_SHORT_UUID,
  PRIMA_NEXT_STEPS_GUIDE_UUID,
} from '@enums'

import store from '@state/store'
import Checklist from '@state/models/checklist'
import ChecklistUserActivation from '@state/models/checklist-user-activation'
import User from '@state/models/user'


export const state = {
  targetDomLoaded: true,
  openCreateDocumentsInFolderModal: false,
  templateUuid: '',
  foldersLoaded: false,
}

export const mutations = {
  SET_TARGET_DOM_LOADED(state, value) {
    state.targetDomLoaded = value
  },
  SET_OPEN_CREATE_DOCUMENTS_IN_FOLDER_MODAL(state, value) {
    state.openCreateDocumentsInFolderModal = value
  },
  SET_TEMPLATE_UUID(state, value) {
    state.templateUuid = value
  },
  SET_FOLDERS_LOADED(state, value) {
    state.foldersLoaded = value
  }
}

export const getters = {
  $t: () => (key, ...params) => {
    return i18n.global.t(key, ...params);
  },
  currentUser(state) {
    return store.getters['auth/currentUser'] &&
      User.find(store.getters['auth/currentUser'].id)
  },
  checklists(state) {
    return Checklist.query().all()
  },
  companyChecklists(state) {
    const companyId = store.getters['navbar/selectedCompany'].id
    return Checklist.query().where('company_id', companyId).with('checklist_steps').all()
  },
  activatedChecklists(state, getters) {
    return ChecklistUserActivation.allFast().filter(
      (cua) => cua.user_id === getters.currentUser.id
    ).map((cua) => {
      return Checklist.query().whereId(cua.checklist_id).with('checklist_steps').first()
    })
    .filter(Boolean) // make sure checklist is actually loaded
  },
  activeChecklist(state, getters) {
    return getters.currentUser?.active_checklist_id &&
      Checklist
      .query()
      .whereId(getters.currentUser.active_checklist_id)
      // .with('checklist_steps')
      .first()
  },
  activeListChecklist(state, getters) {
    return getters.activeChecklist?.checklist_mode === ChecklistMode.LIST ?
      getters.activeChecklist :
      null
  },
  activeTourChecklist(state, getters) {
    return getters.activeChecklist?.checklist_mode === ChecklistMode.TOUR ?
      getters.activeChecklist :
      null
  },
  hasActiveListChecklist(state, getters) {
    // check status of last item
    return !!getters.activeChecklist &&
      getters.activeChecklist.checklist_mode === ChecklistMode.LIST
  },
  onBoardingChecklist(state, getters) {
    return getters.checklists.find((checklist) => {
      return checklist.uuid === PRIMA_ONBOARDING_GUIDE_SHORT_UUID
    })
  },
  nextStepsChecklist(state, getters) {
    return getters.checklists.find((checklist) => {
      return checklist.uuid === PRIMA_NEXT_STEPS_GUIDE_UUID
    })
  },
  targetDomLoaded(state) {
    return state.targetDomLoaded
  },
  openCreateDocumentsInFolderModal(state) {
    return state.openCreateDocumentsInFolderModal
  },
  templateUuid(state) {
    return state.templateUuid
  },
  foldersLoaded(state) {
    return state.foldersLoaded
  },
  rightDrawerChecklists(state) {
    return Checklist.query().where('checklist_mode', ChecklistMode.LIST).where('published', true).all()
  }
}

export const actions = {
  async init({ state, dispatch }) {

  },
  async refreshActiveChecklist({ getters, dispatch }, { loadFolders = true } = {}) {
    if (getters.currentUser?.active_checklist_id) {
      const params = {}
      if (store.getters['navbar/selectedCompany']) {
        params.company_id = store.getters['navbar/selectedCompany'].id
      }

      const result = await Checklist.$find(
        getters.currentUser.active_checklist_id,
        { params },
      ).catch((error) => {
        if (error.response.status === 403 || error.response.status === 404) {
          dispatch('cancelActiveChecklist')
        }
      })

      if (result) {
        await getters.activeChecklist.loadFolders()
        await getters.activeChecklist.initStepActive()
      }

    }
  },
  async refreshChecklists() {
    const params = {}
    if (store.getters['navbar/selectedCompany']) {
      params.company_id = store.getters['navbar/selectedCompany'].id
    }

    await Checklist.$all({ ...params })

  },
  async cancelActiveChecklist({ getters }) {
    const user = getters.currentUser
    await getters.currentUser.sync(null, { active_checklist_id: null })

  },
  setOpenCreateDocumentsInFolderModal({ commit }, {openAddFolderModal, templateUuid}) {
    commit('SET_OPEN_CREATE_DOCUMENTS_IN_FOLDER_MODAL', openAddFolderModal)
    commit('SET_TEMPLATE_UUID', templateUuid)
  },
  setFoldersLoaded({ commit }, {foldersLoaded}) {
    commit('SET_FOLDERS_LOADED', foldersLoaded)
  },
  loadRightDrawerChecklists() {
    const params = {}
    if (store.getters['navbar/selectedCompany']) {
      params.company_id = store.getters['navbar/selectedCompany'].id
      params.list_mode = true

      Checklist.$all({ ...params })
    }
  }
}
