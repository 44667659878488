import {
  sortBy,
  orderBy,
} from 'lodash'

import {
  ChecklistStepStatus,
  ChecklistStepType,
} from '@enums'

import store from '@state/store'

import PlanitModel from './planit-model'
import Checklist from './checklist'
import Folder from './folder'

import replaceBrand from '@utils/brand-placeholder-filter'


export default class ChecklistStep extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      name_translations: this.attr(),
      name: this.attr(),

      description_translations: this.attr(),
      description: this.attr(),

      position: this.attr(),
      optional: this.attr(),

      type: this.attr(),
      data: this.attr(),

      checklist_id: this.attr(),
      checklist: this.belongsTo(Checklist, 'checklist_id'),

      parent_checklist_step_id: this.attr(),
      parent_checklist_step: this.belongsTo(ChecklistStep, 'parent_checklist_step_id'),

      status: this.attr(),

      // local fields

      active: this.attr(false),
      expanded: this.attr(false),

    }
  }

  async toggleExpanded() {
    await this.updateAttr({ expanded: !this.expanded })
  }

  getIcon() {
    if (this.type === ChecklistStepType.QUESTION) {
      return ''
    }
    return this.active && this.type !== ChecklistStepType.GROUP ?
      'far fa-fw fa-long-arrow-right' :
      this.status === ChecklistStepStatus.STARTED ?
      '' :
      this.status === ChecklistStepStatus.FINISHED ?
      'far fa-fw fa-check' :
      ''
  }

  getSortedChildChecklistSteps() {
    return sortBy(
      ChecklistStep.query().where((cs) => cs.parent_checklist_step_id === this.id).all(),
      (cs) => cs.position,
    )
  }

  getChildChecklistStepsFast() {
    return ChecklistStep.allFast().filter((cs) => cs.parent_checklist_step_id === this.id)
  }

  allChildrenFinished() {
    return this.getChildChecklistStepsFast().every((cs) => cs.status === ChecklistStepStatus.FINISHED)
  }

  getMenuFolderByCategoryUUID(uuid) {
    return Folder.allFast().find((f) => f.menu_category_uuid === uuid)
  }

  getRouterLinkTo() {
    let stepTo

    if (this.type === ChecklistStepType.USER_DATA) {
      stepTo = {
        name: 'profile.edit',
      }

    } else if (this.type === ChecklistStepType.COMPANY_PERSON_SET) {
      stepTo = {
        name: 'company.settings.organization',
      }

    } else if (
      this.type === ChecklistStepType.DOCUMENT_CREATED ||
      this.type === ChecklistStepType.DOCUMENT_FINISHED
    ) {
      // get only first blueprint for primary link
      const linked_folder_id = this.data.linked_folder_id_per_blueprint_id[
        this.data.document_blueprint_ids[0].toString()
      ]
      stepTo = {
        name: 'company.folders',
        params: {
          folderId: linked_folder_id,
        },
      }

    } else if (this.type === ChecklistStepType.TRAINING_FINISHED) {
      stepTo = {
        name: 'companyScormFile.detail',
        params: {
          scormFileId: this.data.scorm_file_id,
        },
      }

    } else if (!!this.data.to?.name) {
      stepTo = { ...this.data.to }

    } else if (!!this.data.to?.folder) {
      const folder = this.getMenuFolderByCategoryUUID(this.data.to.folder)
      if (!folder) {
        return null
      }
      stepTo = {
        name: 'company.folders',
        params: {
          folderId: folder.id,
        },
      }

    } else {
      return null

    }

    if (stepTo.params && typeof stepTo.params === 'string') {
      stepTo.params = JSON.parse(stepTo.params)
    }

    stepTo.params = {
      ...(stepTo.params || {})
    }

    if (store.getters['navbar/selectedCompany']) {
      stepTo.params.companyId = store.getters['navbar/selectedCompany'].id
    }

    return stepTo
  }

  async checkLinkStep({ includeCompanyContext } = {}) {
    const params = {}
    if (includeCompanyContext) {
      params.company_id = store.getters['navbar/selectedCompany']?.id
    }

    if (
      this.type === ChecklistStepType.LINK &&
      this.status !== ChecklistStepStatus.FINISHED
    ) {
      await this.sync(null, { visited: true }, { params })
      await store.dispatch('checklist/refreshActiveChecklist')

    } else if (this.type === ChecklistStepType.GUIDE) {
      const guideUUID = this.data.start_guide

      const checklist = Checklist.query().where(c => c.uuid === guideUUID).first()
      if (checklist) {
        await checklist.start()

      }
    }
  }

  async updateStatus({ includeCompanyContext } = {}) {
    if (this.type === ChecklistStepType.QUESTION || this.type === ChecklistStepType.HEADLINE) {
      const params = {}
      if (includeCompanyContext) {
        params.company_id = store.getters['navbar/selectedCompany']?.id
      }

      const result = await this.sync(null, { status: this.status }, { params })

      await ChecklistStep.insertOrUpdate({
        data: result.response.data.updated_checklist_steps,
      })

      if (result.response.data.checklist_progress) {
        await Checklist.update({
          where: this.checklist_id,
          data: {
            progress: result.response.data.checklist_progress,
          }
        })
      }

    }
  }
  getNextStep() {
    const children = orderBy(
      ChecklistStep.allFast().filter(
        (cs) => cs.parent_checklist_step_id === this.id
      ),
      'position',
    )
    if (children.length) {
      const childStep = ChecklistStep.find(children[0].id)
      return childStep
    }

    const nextStepThisLevel = ChecklistStep.query().where((cs) => {
      return cs.parent_checklist_step_id === this.parent_checklist_step_id &&
        cs.checklist_id === this.checklist_id &&
        cs.position === this.position + 1
    }).first()

    if (nextStepThisLevel) {
      return nextStepThisLevel
    }

    if (this.parent_checklist_step_id) {
      const parentStep = ChecklistStep.find(this.parent_checklist_step_id)
      return parentStep.getNextStep()
    }

    return null

  }

  getName(locale) {
    return replaceBrand(this.name_translations[locale])
  }

  getDescription(locale) {
    return replaceBrand(this.description_translations[locale])
  }

}

ChecklistStep.entity = 'checklist-steps'
