import mitt from 'mitt'

const emitter = mitt()

const emitterEventMixin  = {
  beforeUnmount() {
    if (this._emittedEvents) {
      this._emittedEvents.forEach(([eventName, handler]) => {
        emitter.off(eventName, handler)
      })
    }
  },
  methods: {
    $onEvent(eventName, handler) {
      emitter.on(eventName, handler)
      if (!this._emittedEvents) {
        this._emittedEvents = []
      }
      this._emittedEvents.push([eventName, handler])
    },
    $offEvent(eventName, handler) {
      emitter.off(eventName, handler)
      this._emittedEvents = this._emittedEvents.filter(
        (entry) => entry[0] !== eventName || entry[1] !== handler
      )
    },
  },
}

export { emitter, emitterEventMixin  }
