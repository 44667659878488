<script>
export default {
  props: {
    /**
     * The icon name to use, typically from a fontaweseome icons set.
     * @type {String}
     * @default ''
     */
    icon: {
      type: String,
      default: ''
    },
    /**
     * The size of the icon. Can be a predefined string ('small', 'default', 'large')
     * or a specific size like '24px'.
     * @type {String|Number}
     * @default 'large'
     */
    size: {
      type: [String, Number],
      default: 'large'
    },
    /**
     * The color of the icon, specified as a CSS color string (e.g., 'red', '#ff0000').
     * @type {String}
     * @default ''
     */
    color: {
      type: String,
      default: ''
    },
    /**
     * The degree to rotate the icon. Can be any number, representing degrees.
     * @type {String|Number}
     * @default 0
     */
    rotate: {
      type: [String, Number],
      default: 0
    },
    /**
     * Whether the icon should spin, useful for loading indicators.
     * @type {Boolean}
     * @default false
     */
    spin: {
      type: Boolean,
      default: false
    },
    /**
     * If true, the icon will appear disabled.
     * @type {Boolean}
     * @default false
     */
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localIcon: ''
    }
  },
  watch: {
    icon(newIcon) {
      this.updateLocalIcon(newIcon)
    }
  },
  created() {
    this.localIcon = this.addIconPrefix(this.icon)
  },
  methods: {
    addIconPrefix(icon) {
      if (!icon) return ''
      const knownPrefixes = ['fal', 'fas', 'far', 'fab', 'fad']
      const hasPrefix = knownPrefixes.some(prefix => icon.startsWith(prefix))
      return hasPrefix ? icon : `fa ${icon}`
    },
    updateLocalIcon(icon) {
      this.localIcon = this.addIconPrefix(icon)
    },
  }
}
</script>

<template>
  <v-icon
    v-bind="$attrs"
    :icon="localIcon"
    :size="size"
    :color="color"
    :rotate="rotate"
    :spin="spin"
    :disabled="disabled"
  >
    <template v-if="$slots.default">
      <slot></slot>
    </template>
  </v-icon>
</template>
