import PlanitModel from './planit-model'
import CompanyPerson from './company-person'

export default class CompanyPersonType extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      name: this.attr(),
      type: this.attr(),

      company_id: this.attr(),
    }
  }

  async delete() {
    await CompanyPerson.delete((cp) => cp.company_person_type_id === this.id)
    return super.delete()
  }
}

CompanyPersonType.entity = 'company-person-types'
