<script>
import { debounce } from 'lodash'

import ProfilePicture from '@components/profile-picture'
import IconButton from '@components/icon-button'

import store from '@state/store'
import User from '@state/models/user'


export default {
  name: 'PlanitUserSearch',
  components: {
    ProfilePicture,
    IconButton,
  },
  props: {
    placeholder: { type: String, required: false, default: '' },
    company: { type: Object, required: false, default: null },
    value: { type: String, required: false, default: null },
    excludeUserIds: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },
    hideSearchInput: { type: Boolean, required: false, default: false },
    disableString: { type: Boolean, required: false, default: false },
    disableUsers: { type: Boolean, required: false, default: false },
    setUserTooltip: { type: String, required: false, default: null },
    textInsteadOfTooltip: { type: Boolean, required: false, default: false },
    showCurrentUser: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
  },
  emits: ['search', 'set-string', 'set-user'],
  data() {
    return {
      userSearchString: null,
      userSearchResultIds: [],
    }
  },
  computed: {
    searchResults() {
      const userIdsWithoutExcludes = (this.userSearchResultIds || []).filter(
        (userId) => this.excludeUserIds.indexOf(userId) === -1
      )
      const results = User.query().findIn(userIdsWithoutExcludes)
      return results
    },
    userSearchStringInSearchResults() {
      if (this.userSearchString.length && this.searchResults.length) {
        return !!this.searchResults.find(
          (u) => u.email === this.userSearchString
        )
      }
    },
    userAddTooltipText() {
      return this.setUserTooltip || this.$t('components.planit_user_search.add_without_account')
    },
    currentUser() {
      return store.getters['auth/currentUser']
    },
  },
  watch: {
    value(v) {
      this.userSearchString = v
      this.searchForUserDebounced()
    },
  },
  created() {
    this.searchForUserDebounced = debounce(this.searchForUser, 250)
    if (this.value) {
      this.userSearchString = this.value
    }
  },
  methods: {
    async searchForUser() {
      this.$emit('search', this.userSearchString)

      if (this.userSearchString?.length > 1 && !this.disableUsers) {
        if (this.company) {
          this.company.searchUsers(this.userSearchString).then((result) => {
            if (result.response.data.length) {
              this.userSearchResultIds = result.response.data.map((u) => u.id)
            }
          })
        } else {
          User.$all({
            search: this.userSearchString,
          }).then((result) => {
            if (result.response.data.length) {
              this.userSearchResultIds = result.response.data.map((u) => u.id)
            }
          })
        }
      } else {
        this.userSearchResultIds = []
      }
    },
    resetSearch() {
      this.userSearchString = null
      this.userSearchResultIds = []

      this.$emit('search', this.userSearchString)
    },
    async handleSetString(string) {
      await this.$emit('set-string', string)
      this.resetSearch()
    },
    async handleSetUser(user) {
      await this.$emit('set-user', user)
      this.resetSearch()
    },
  },
}
</script>

<template>
  <div>
    <form
      v-if="!hideSearchInput"
      @submit.prevent="handleSetString(userSearchString)"
    >
      <PlanitTextField
        v-model.trim="userSearchString"
        :label="placeholder"
        variant="outlined"
        density="compact"
        hide-details
        :disabled="disabled"
        prepend-inner-icon="fal fa-search"
        @update:model-value="searchForUserDebounced"
      ></PlanitTextField>
    </form>

    <v-list
      v-if="
        !disableString && userSearchString && !userSearchStringInSearchResults
      "
      class="ma-0 pa-0"
    >
      <v-list-item
        class="py-1 px-0 mb-2"
        :class="{ 'border-bottom': !!searchResults.length && !showCurrentUser }"
      >
        <div class="d-flex align-center justify-space-between">
          <div class="mx-2">
            <PlanitIcon class="px-2" icon="fal fa-user-edit"></PlanitIcon>
            <span class="pl-2">{{ userSearchString }}</span>
          </div>
          <v-tooltip v-if="!textInsteadOfTooltip" location="top">
            <template #activator="{ props }">
              <span v-bind="props">
                <IconButton
                  icon="fa-plus"
                  color="primary"
                  class="ml-4"
                  @click="handleSetString(userSearchString)"
                ></IconButton>
              </span>
            </template>
            <span v-text="userAddTooltipText"></span>
          </v-tooltip>
          <PlanitButton
            v-else
            size="small"
            variant="text"
            color="primary"
            class="ml-4"
            @click="handleSetString(userSearchString)"
          >
            <PlanitIcon size="small" class="mr-1" icon="fal fa-plus"></PlanitIcon>

            <span v-text="userAddTooltipText"></span>
          </PlanitButton>
        </div>
      </v-list-item>
    </v-list>

    <v-list
      v-if="showCurrentUser"
      class="ma-0 pa-0"
    >
      <v-list-item
        class="py-1 px-0 mb-2"
        :class="{ 'border-bottom': !!searchResults.length }"
      >
        <div class="d-flex align-center">
          <ProfilePicture
            :model="currentUser"
            width="30px"
            rounded
            class="mr-2"
          ></ProfilePicture>

          <div v-if="currentUser.name">
            <v-list-item-title>{{ currentUser.name }}</v-list-item-title>
            <v-list-item-subtitle>
              <span>{{ currentUser.email }}</span>
            </v-list-item-subtitle>
          </div>
          <div v-else>
            <v-list-item-title>{{ currentUser.email }}</v-list-item-title>
          </div>
          <div class="ml-auto">
            <v-tooltip v-if="!textInsteadOfTooltip" location="top">
              <template #activator="{ props }">
                <span v-bind="props">
                  <IconButton
                    icon="fa-plus"
                    color="primary"
                    class="ml-4"
                    @click="handleSetUser(currentUser)"
                  ></IconButton>
                </span>
              </template>

              <span v-text="userAddTooltipText"></span>
            </v-tooltip>
            <PlanitButton
              v-else
              size="small"
              variant="text"
              color="primary"
              class="ml-4"
              @click="handleSetUser(currentUser)"
            >
              <PlanitIcon size="small" class="mr-1" icon="fal fa-plus"></PlanitIcon>

              <span v-text="userAddTooltipText"></span>
            </PlanitButton>
          </div>
        </div>
      </v-list-item>
    </v-list>

    <v-list
      v-if="userSearchString && !disableUsers && searchResults.length"
      style="max-height: 25vh; overflow-y: auto;"
    >
      <v-list-item v-for="user in searchResults" :key="user.id" class="px-0 py-1">
        <div class="d-flex align-center justify-space-between flex-wrap">
          <ProfilePicture
            :model="user"
            width="30px"
            rounded
            class="mr-2"
          ></ProfilePicture>

          <div v-if="user.name">
            <v-list-item-title>{{ user.name }}</v-list-item-title>
            <v-list-item-subtitle>
              <span>{{ user.email }}</span>
            </v-list-item-subtitle>
          </div>
          <div v-else>
            <v-list-item-title>{{ user.email }}</v-list-item-title>
          </div>

          <div class="flex-grow-1"></div>

          <v-tooltip v-if="user.admin_visible" location="top">
            <template #activator="{ props }">
              <span v-bind="props">
                <PlanitChip color="error">
                  <PlanitIcon size="small" icon="fas fa-wand-magic"></PlanitIcon>
                </PlanitChip>
              </span>
            </template>
            <span>{{ $t('generic.superadmin_visibility_info') }}</span>
          </v-tooltip>

          <IconButton
            icon="fa-plus"
            color="primary"
            class="ml-2"
            @click="handleSetUser(user)"
          ></IconButton>
        </div>
      </v-list-item>
    </v-list>
  </div>
</template>
