<script>

import PlanitIcon from '@components/vuetify/planit-icon'


export default {
  page: {
    title: '404',
    meta: [{ name: 'description', content: '404' }],
  },
  components: {},
  props: {
    resource: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <div :style="$store.getters['global/containerStyles']">
    <div class="pa-6">
      <h1 :class="$style.title">
        <PlanitIcon size="96" icon="fa-ghost" color="info" />
        <br>
        <i18n-t keypath="generic.404_text">
          <template v-if="resource" #resource>{{ resource }}</template>
        </i18n-t>
      </h1>
    </div>
  </div>
</template>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
