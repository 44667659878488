<script>
export default {
  props: {
    modelValue: {
      type: [Object, Array, String, Number, Boolean],
      default: false
    },
    maxWidth: {
      type: [String, Number],
      default: 'auto'
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    persistent: {
      type: Boolean,
      default: false
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    hideOverlay: {
      type: Boolean,
      default: false
    },
    transition: {
      type: String,
      default: 'dialog-transition'
    },
    contentClass: {
      type: String,
      default: ''
    },
    scrollStrategy: {
      type: String,
      default: 'none'
    },
  },
  emits: ['update:model-value'],
  data() {
    return {
      internalValue: Boolean(this.modelValue)
    }
  },
  watch: {
    modelValue: {
      handler(newValue) {
        // Convert the modelValue to a boolean based on its truthiness
        this.internalValue = typeof newValue === 'boolean' ? newValue : Boolean(newValue)
      }
    },
    internalValue(value) {
      if (value !== this.modelValue) {
        this.$emit('update:model-value', value)
      }
    }
  },
  methods: {
    updateModelValue(value) {
      this.internalValue = typeof value === 'boolean' ? value : Boolean(value)
    }
  }
}
</script>

<template>
  <v-dialog
    v-bind="$attrs"
    :model-value="internalValue"
    @update:model-value="updateModelValue"
    :max-width="maxWidth"
    :fullscreen="fullscreen"
    :persistent="persistent"
    :scrollable="scrollable"
    :hide-overlay="hideOverlay"
    :transition="transition"
    :content-class="[contentClass]"
    :scroll-strategy="scrollStrategy"
  >
    <!-- Activator Slot -->
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }"></slot>
    </template>
    <!-- Default Slot for Dialog Content -->
    <slot></slot>
  </v-dialog>
</template>
