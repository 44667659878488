import {
  IMAGE_EXTENSIONS,
  UPLOAD_EXTENSIONS,
} from '@enums'

import PlanitModel from './planit-model'
import { DocumentVersion } from './document-version'
import Company from './company'
import Folder from './folder'
import FileFolderDocumentBlueprintAssociation from './file-folder-document-blueprint-association'
import DownloadResource from './download-resource'


export default class File extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      document_version_id: this.attr(),
      document_version: this.belongsTo(DocumentVersion, 'document_version_id'),

      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),

      folder_id: this.attr(),
      folder: this.belongsTo(Folder, 'folder_id'),

      document_blueprint_id: this.attr(),
      // document: this.hasOne(Document, 'document_version_id'),

      filename: this.attr(),
      filesize: this.attr(),
      document_name: this.attr(),
      file_type: this.attr(),
      export_status: this.attr(),
      export_format: this.attr(),
      locale: this.attr(),

      archived: this.attr(),

      link: this.attr(),

      data: this.attr(),

      file_folder_document_blueprint_associations: this.hasMany(
        FileFolderDocumentBlueprintAssociation,
        'file_id'
      ),

      name_sort: this.attr(),

      download_resource_id: this.attr(),
      download_resource: this.belongsTo(DownloadResource, 'download_resource_id'),

    }
  }

  static beforeCreate(model) {
    model.name_sort = (model.filename || model.document_name || '').toLowerCase()
  }

  static getIconByExtension(name, iconType = 'fal') {
    if (name.endsWith('.pdf')) {
      return iconType + ' fa-file-pdf'

    } else if (
      name.endsWith('.png') ||
      name.endsWith('.jpg') ||
      name.endsWith('.jpeg') ||
      name.endsWith('.tiff') ||
      name.endsWith('.svg')
    ) {
      return iconType + ' fa-file-image'

    } else if (name.endsWith('.xsl') || name.endsWith('.xslx') || name.endsWith('.ods')) {
      return iconType + ' fa-file-excel'

    } else if (name.endsWith('.doc') || name.endsWith('.docx') || name.endsWith('.odt')) {
      return iconType + ' fa-file-word'

    } else if (name.endsWith('.ppt') || name.endsWith('.pptx') || name.endsWith('.odp')) {
      return iconType + ' fa-file-powerpoint'

    } else if (
      name.endsWith('.mkv') ||
      name.endsWith('.mp4') ||
      name.endsWith('.avi') ||
      name.endsWith('.flv') ||
      name.endsWith('.mov') ||
      name.endsWith('.webm')
    ) {
      return iconType + ' fa-file-video'

    } else if (name.endsWith('.zip')) {
      return iconType + ' fa-file-archive'

    } else if (name.endsWith('.txt')) {
      return iconType + ' fa-file-alt'

    } else {
      return iconType + ' fa-file'

    }
  }

  static isFileAllowed(filename) {
    filename = filename.toLowerCase()
    return !UPLOAD_EXTENSIONS.some((ext) => filename.endsWith(ext))
  }

  getIcon(iconType = 'fal') {
    if (this.filename) {
      const filename = this.filename.toLowerCase()

      return File.getIconByExtension(filename, iconType)
    } else {
      return iconType + ' fa-file'
    }
  }

  isImage() {
    return IMAGE_EXTENSIONS.some((ext) => this.filename.endsWith(ext))
  }

  isPdf() {
    return this.filename.endsWith('.pdf')
  }

  isText() {
    return this.filename.endsWith('.txt')
  }

  isVideo() {
    return (
      this.filename.endsWith('.mkv') ||
      this.filename.endsWith('.mp4') ||
      this.filename.endsWith('.avi') ||
      this.filename.endsWith('.flv') ||
      this.filename.endsWith('.mov') ||
      this.filename.endsWith('.webm')
    )
  }

  postOrPatch(data, config) {
    const shouldSave = config?.save !== undefined ? config.save : true
    return super.postOrPatch(data, { ...config, save: false }).then((r) => {
      if (r.response.data) {
        r.response.data.name_sort = r.response.data.filename
      }

      if (shouldSave) {
        r.save()
      }

      return r
    })
  }

  getRolloutFileLink(rolloutCode) {
    return `${this.link}?rollout_code=${rolloutCode}`
  }

  getFormattedFileSize() {
    const bytes = this.filesize
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

    if (bytes === 0 || !bytes) {
      return '0 Byte'
    }

    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)

    if (i === 0) {
      return `${bytes} ${sizes[i]}`
    }

    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
  }

  getRouterTo(companyId) {
    return {
      name: 'company.folders',
      params: {
        companyId: this.company_id || companyId,
        folderId: this.folder_id,
      },
      query: {
        openFile: this.id,
      },
    }
  }
}

File.entity = 'files'
