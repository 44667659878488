import { Chart } from 'chart.js'

import defaultThemes from '@plugins/default-themes'

import store from '@state/store'
import PlanitModel from './planit-model'

import { AnswerSectionSelectGroupOptionResultUserDataStatusType } from '@enums'
import { rgbaColor } from '@/src/utils/rgbaColor'

/* import { DocumentVersion } from './document-version'
import {
  SectionSelectGroupOptionResult,
  // SectionSelectGroupOptionResultDocumentBlueprintVersionAssociation,
} from './section-select-group-option-result' */


/* export class AnswerSectionSelectGroupOptionResultUserDataDocumentVersionAssociation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      document_version_id: this.attr(),
      document_version: this.belongsTo(DocumentVersion, 'document_version_id'),

      section_select_group_option_result_id: this.attr(),
      section_select_group_option_result: this.belongsTo(
        SectionSelectGroupOptionResult,
        'section_select_group_option_result_id'
      ),

      answer_document_association_id: this.attr(),
      answer_document_association: this.belongsTo(
        AnswerDocumentAssociation,
        'answer_document_association_id'
      ),

      answer_section_select_group_option_result_user_data_id: this.attr(),
      answer_section_select_group_option_result_user_data: this.belongsTo(
        AnswerSectionSelectGroupOptionResultUserData,
        'answer_section_select_group_option_result_user_data_id'
      ),

      commit_id: this.attr(),
    }
  }
}

AnswerSectionSelectGroupOptionResultUserDataDocumentVersionAssociation.entity =
  'answer-section-select-group-option-result-user-data-document-version-associations' */

export class AnswerSectionSelectGroupOptionResultUserData extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      viid: this.attr(),

      text_translations: this.attr({}),
      status: this.attr(),

      is_pending: this.attr(),
      color: this.attr(),

      answer_viid: this.attr(),
      section_select_group_option_result_viid: this.attr(),

    }
  }

  async sync(attr, data, config, callback) {
    const result = await super.sync(attr, data, config, callback)
    const newAssgorud = result.entities['answer-section-select-group-option-result-user-data'].find(a => a.id === result.response.data.id)

    await store.dispatch('currentDocument/updateAssgorudInAllVersions', { assgorud: newAssgorud })

    return result

  }

  getChartColor(alpha = 0.5) {
    if (this.color === 'warning') {
      return rgbaColor(defaultThemes.light.colors.warning, alpha);
    } else if (this.color === 'primary') {
      return rgbaColor(defaultThemes.light.colors.primary, alpha);
    } else if (this.color === 'success') {
      return rgbaColor(defaultThemes.light.colors.success, alpha);
    } else {
      return rgbaColor('#e0e0e0', alpha);
    }
  }


  getStatusSortValue() {
    if (this.status === AnswerSectionSelectGroupOptionResultUserDataStatusType.OPEN) {
      return 1
    } else if (this.status === AnswerSectionSelectGroupOptionResultUserDataStatusType.IN_PROGRESS) {
      return 2
    } else if (this.status === AnswerSectionSelectGroupOptionResultUserDataStatusType.DONE) {
      return 3
    }
  }

}

AnswerSectionSelectGroupOptionResultUserData.entity = 'answer-section-select-group-option-result-user-data'
