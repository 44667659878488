import {
  orderBy,
  countBy,
} from 'lodash'

import {
  DocumentType,
  SectionType,
  ResultViewMode,
  SectionDisplayMode,
  SectionResultType,
} from '@enums'

import $router from '@router'

import store from '@state/store'

import Answer from '@state/models/answer'
import {
  AnswerSectionSelectGroupOptionResultUserData,
} from '@state/models/answer-section-select-group-option-result-user-data'
import Approval from '@state/models/approval'
import TaskCompanyRoleAssociation from '@state/models/task-company-role-association'
import ApprovalComment from '@state/models/approval-comment'
import Task from '@state/models/task'
import Timer from '@state/models/timer'
import Reminder from '@state/models/reminder'

import { DocumentVersion } from '@state/models/document-version'
import Company from '@state/models/company'
import CompanyPerson from '@state/models/company-person'
import { Document } from '@state/models/document'
import CompanyDocumentTemplateAllotment from '@state/models/company-document-template-allotment'
import {
  DocumentBlueprintVersion,
} from '@state/models/document-blueprint-version'
import Folder from '@state/models/folder'
import Vendor from '@state/models/vendor'

import { emitter } from '@utils/global-events'
import flattenByAttr from '@utils/flatten-by-attr'


export const state = {
  documentId: null,
  pageRouteLoading: true,
  mappedSelectGroups: null,

  documentVersion: null,
  documentBlueprint: null,

  selectedTopLevelAnswer: null,

  showAllPages: false,
  onePageMode: false,
  showEmptyOnly: null, // make sure watch triggers when initializing to false
  showRequiredOnly: null,
  allExpanded: true,

  currentPage: 1,

  answers: [],
  answerSectionSelectGroupOptionResultUserData: [],

  currentSectionTypes: [],

  initialRefreshDone: false,
  currentChecklistGuide: null,

  tsSourceLocale: null,
  showBlueprintTranslationSuggestions: false,

  rriCollapsed: {},

}

export const mutations = {
  SET_CURRENT_DOCUMENT_ID(state, { documentId }) {
    state.documentId = documentId
  },
  SET_PAGE_ROUTE_LOADING(state, { value }) {
    state.pageRouteLoading = value
  },
  SET_MAPPED_SELECT_GROUPS(state, { selectGroups }) {
    state.mappedSelectGroups = selectGroups
  },
  SET_EDIT_VERSION(state, { documentVersion }) {
    state.documentVersion = documentVersion
  },
  SET_SHOW_ALL_PAGES(state, showAllPages) {
    state.showAllPages = showAllPages
  },
  SET_ONE_PAGE_MODE(state, onePageMode) {
    state.onePageMode = onePageMode
  },
  SET_SHOW_EMPTY_ONLY(state, showEmptyOnly) {
    state.showEmptyOnly = showEmptyOnly
  },
  SET_SHOW_REQUIRED_ONLY(state, showRequiredOnly) {
    state.showRequiredOnly = showRequiredOnly
  },
  SET_ALL_EXPANDED(state, allExpanded) {
    state.allExpanded = allExpanded
  },
  SET_SELECTED_TOP_LEVEL_ANSWER(state, selectedTopLevelAnswer) {
    state.selectedTopLevelAnswer = selectedTopLevelAnswer
  },
  SET_CURRENT_PAGE(state, currentPage) {
    state.currentPage = currentPage
  },
  SET_ANSWERS_FOR_DOCUMENT_VERSION(state, { answers, documentVersion }) {
    const existingItem = state.answers.find(
      s => s.documentVersion === documentVersion
    )

    const newItem = { documentVersion, answers }

    if (existingItem) {
      state.answers.splice(state.answers.indexOf(existingItem), 1, newItem)
    } else {
      state.answers.push(newItem)
    }

  },
  SET_ANSWER_SECTION_SELECT_GROUP_OPTION_RESULT_USER_DATA_FOR_DOCUMENT_VERSION(
    state,
    {
      answerSectionSelectGroupOptionResultUserData,
      documentVersion,
    }
  ) {
    const existingItem = state.answerSectionSelectGroupOptionResultUserData.find(
      s => s.documentVersion === documentVersion
    )

    const newItem = { documentVersion, answerSectionSelectGroupOptionResultUserData }

    if (existingItem) {
      state.answerSectionSelectGroupOptionResultUserData.splice(
        state.answerSectionSelectGroupOptionResultUserData.indexOf(existingItem),
        1,
        newItem
      )
    } else {
      state.answerSectionSelectGroupOptionResultUserData.push(newItem)
    }

  },
  UPDATE_ANSWER(state, { answer, documentVersion }) {
    const existingItem = state.answers.find(
      s => s.documentVersion === documentVersion
    )

    const existingAnswer = existingItem.answers.find(s => s.viid === answer.viid)
    if (existingAnswer) {
      existingItem.answers.splice(existingItem.answers.indexOf(existingAnswer), 1, answer)

    } else {
      existingItem.answers.push(answer)

    }
  },
  DELETE_ANSWER(state, { answer, documentVersion }) {
    const existingItem = state.answers.find(
      s => s.documentVersion === documentVersion
    )

    const existingAnswer = existingItem.answers.find(s => s.viid === answer.viid)
    existingItem.answers.splice(existingItem.answers.indexOf(existingAnswer), 1)
  },
  UPDATE_ANSWER_SECTION_SELECT_GROUP_OPTION_RESULT_USER_DATA(state, { answerSectionSelectGroupOptionResultUserData, documentVersion }) {
    const existingItem = state.answerSectionSelectGroupOptionResultUserData.find(
      s => s.documentVersion === documentVersion
    )

    const existingAssgorud = existingItem.answerSectionSelectGroupOptionResultUserData.find(
      s => s.viid === answerSectionSelectGroupOptionResultUserData.viid
    )
    existingItem.answerSectionSelectGroupOptionResultUserData.splice(
      existingItem.answerSectionSelectGroupOptionResultUserData.indexOf(existingAssgorud), 1, answerSectionSelectGroupOptionResultUserData
    )

  },
  SET_CURRENT_SECTION_TYPES(state, currentSectionTypes) {
    state.currentSectionTypes = currentSectionTypes
  },
  SET_INITIAL_REFRESH_DONE(state, initialRefreshDone) {
    state.initialRefreshDone = initialRefreshDone
  },
  SET_CURRENT_CHECKLIST_GUIDE(state, currentChecklistGuide) {
    state.currentChecklistGuide = currentChecklistGuide
  },
  SET_TS_SOURCE_LOCALE(state, tsSourceLocale) {
    state.tsSourceLocale = tsSourceLocale
  },
  SET_BLUEPRINT_TRANSLATION_SUGGESTIONS(state, showBlueprintTranslationSuggestions) {
    state.showBlueprintTranslationSuggestions = showBlueprintTranslationSuggestions
  },
  SET_RRI_COLLAPSED(state, { key, value }) {
    state.rriCollapsed[key] = value
  },
}

export const getters = {
  document(state) {
    return (
      state.documentId &&
      Document.query()
        .whereId(state.documentId)
        .with('current_upgrade_document_blueprint_version')
        .with('edit_document_version')
        .with('document_blueprint')
        .first()
    )
  },
  company(state, getters) {
    const ret = (
      getters.document?.company_id &&
      Company.query()
        .whereId(getters.document.company_id)
        .with('company_roles')
        .with('company_roles.user')
        .with('address')
        .first()
    ) || null
    return ret
  },
  folderVendorAssociations(state, getters) {
    if (!getters.document?.primary_folder_id) {
      return []
    }

    const folder = Folder.query()
      .whereId(getters.document.primary_folder_id)
      .with('folder_vendor_associations')
      .first()
    if (!folder) {
      return []
    }
    return folder.folder_vendor_associations
  },
  showDocumentResults(state, getters) {
    return (
      getters.document &&
      (
        (
          getters.document.result_view_mode === ResultViewMode.PAGE &&
          !!getters.selectGroups?.length
        ) ||
        (
          getters.currentProgress === 100 &&
          getters.documentVersionBlueprintVersion.data &&
          !!getters.documentVersionBlueprintVersion.data.result_content
        ) ||
        getters.resultSections?.length ||
        !!getters.showInResultAnswerItems?.length
      )
    )
  },
  resultsTabDisabled(state, getters) {
    if (!getters.documentVersionBlueprintVersion) {
      return true
    }

    return !(
      !getters.documentVersionBlueprintVersion.data ||
      !getters.documentVersionBlueprintVersion.data.filled_in_required_for_results ||
      (
        getters.documentVersionBlueprintVersion.data &&
        getters.documentVersionBlueprintVersion.data.filled_in_required_for_results &&
        getters.currentProgress === 100
      )
    )
  },
  documentVersion(state) {
    return state.documentVersion
  },
  pageRouteLoading(state) {
    return state.pageRouteLoading
  },
  documentVersionBlueprintVersion(state) {
    return store.getters['blueprint/documentBlueprintVersion']
  },
  documentBlueprint() {
    return store.getters['blueprint/documentBlueprint']
  },
  answersDocumentVersion(state) {
    return (documentVersion) => {
      const answersItem = state.answers.find(answersItem => answersItem.documentVersion === documentVersion)
      return answersItem?.answers || []
    }
  },
  answerSectionSelectGroupOptionResultUserDataDocumentVersion(state) {
    return (documentVersion) => {
      const answerSectionSelectGroupOptionResultUserDataItem = state.answerSectionSelectGroupOptionResultUserData.find(
        si => si.documentVersion === documentVersion
      )
      return answerSectionSelectGroupOptionResultUserDataItem?.answerSectionSelectGroupOptionResultUserData || []
    }
  },
  showInResultAnswerSdbas(state) {
    return state.showInResultAnswerSdbas
  },
  topLevelSdbas(state) {
    return state.topLevelSdbas
  },
  topLevelAnswers(state, getters) {
    const topLevelSections = store.getters['blueprint/topLevelSectionItemsByBlueprintVersion'](
      getters.documentVersionBlueprintVersion.version
    )
    const answers = getters.answersDocumentVersion(getters.documentVersion.version)

    const topLevelAnswers = topLevelSections
    .map((sectionItem) => {
      const answer = answers.find(a => a.section_viid === sectionItem.section.viid)
      return {
        ...answer,
        section: {
          ...sectionItem.section,
          full_position: sectionItem.fullPosition,
        },
      }
    })
    .filter(a => !!a.result_visible)

    return topLevelAnswers

  },
  currentProgress(state, getters) {
    if (getters.documentVersion) {
      const documentVersion = DocumentVersion.allFast().find((dv) => dv.id === getters.documentVersion.id)
      if (documentVersion && documentVersion.current_progress) {
        return parseInt(documentVersion.current_progress)
      }
    }

    return 0
  },
  canEditDocument(state, getters) {
    return getters.document?.me_can_edit || false
  },
  canAssignDocument(state, getters) {
    return getters.document?.me_can_assign || false
  },
  showAllPages(state) {
    return state.showAllPages || store.getters['navbar/currentRoute'].name.indexOf('results') !== -1
  },
  onePageMode(state) {
    return state.onePageMode
  },
  showEmptyOnly(state) {
    return state.showEmptyOnly
  },
  showRequiredOnly(state) {
    return state.showRequiredOnly
  },
  allExpanded(state) {
    return state.allExpanded
  },
  selectedTopLevelAnswer(state) {
    return state.selectedTopLevelAnswer
  },
  currentPage(state) {
    return state.currentPage
  },
  selectGroups(_, getters) {
    if (!getters.documentVersionBlueprintVersion?.version) {
      debugger
    }
    return store.getters['blueprint/selectGroupsBlueprintVersion'](
      getters.documentVersionBlueprintVersion.version
    )
  },
  selectGroupOptions(_, getters) {
    return store.getters['blueprint/selectGroupOptionsBlueprintVersion'](
      getters.documentVersionBlueprintVersion.version
    )
  },
  sectionSelectGroupOptionResults(_, getters) {
    return store.getters['blueprint/sectionSelectGroupOptionResultsBlueprintVersion'](
      getters.documentVersionBlueprintVersion.version
    )
  },
  sections(state, getters) {
    return store.getters['blueprint/sectionsBlueprintVersion'](getters.documentVersionBlueprintVersion.version)
  },
  sectionTree(state, getters) {
    return store.getters['blueprint/sectionTreeByBlueprintVersion'](getters.documentVersionBlueprintVersion.version)
  },
  flattenedSectionItems(state, getters) {
    return store.getters['blueprint/sortedFlattenedSectionItems'](getters.documentVersionBlueprintVersion.version)
  },
  topLevelSectionItem(state, getters) {
    if (!getters.selectedTopLevelAnswer) {
      return null
    }

    const topLevelSection = getters.selectedTopLevelAnswer.section
    const topLevelSectionItem = getters.sectionTree.find(section => section.id === topLevelSection.id)

    return topLevelSectionItem

  },
  sectionItemsThisPage(state, getters) {
    if (getters.showAllPages) {
      return getters.flattenedSectionItems
    }

    return flattenByAttr([getters.topLevelSectionItem], 'children')

  },
  resultSections(state, getters) {
    return (getters.sections || []).filter(s => s.section_type === SectionType.RESULT)
  },
  sortedAndMappedAnswers(state, getters) {
    const showEmptyOnly = getters.showEmptyOnly
    const requiredOnly = getters.showRequiredOnly

    const answersThisDocumentVersion = getters.answersDocumentVersion(getters.documentVersion.version)

    const answers = []

    let parentSectionItems = []

    const mapSectionItem = ({
      sectionItem,
      level,
      parentAnswerViid,
      skipFirst,
      parentIsMultiAnswer,
      parentAnswerCounter,
      columnParentAnswerItem,
      parentStartedColumns,
    }) => {
      if (level === 1) {
        parentSectionItems = []
      }

      let inline = false
      let formView = false
      let containerStyles

      let resultColumns = false

      let disablePosition = false
      let isMultiAnswer

      if (sectionItem) {
        disablePosition = [sectionItem, ...parentSectionItems].some(
          (si) => si.section.data?.disable_position
        )

        // with the new logic, current sectionItem is actually the parent
        if (sectionItem && sectionItem.section) {
          // SectionDisplayMode.COLUMNS
          if (
            level > 1 &&
            (
              sectionItem?.section.display_mode === SectionDisplayMode.COLUMNS
            )
          ) {
            const siblings = sectionItem.children
            inline = true

            containerStyles = {
              // padding: '0 0.75rem',
              padding: '0',
              // display: showEmptyOnly || requiredOnly ? 'block' : 'inline-block',
              display: 'inline-block',
              'vertical-align': 'top',
              // width: showEmptyOnly || requiredOnly ? 'auto' : 100 / siblings.length + '%',
              width: (100 / siblings.length) + '%',
            }

            if (!showEmptyOnly && !requiredOnly) {
              containerStyles['min-height'] = '10rem'
            }


          // SectionDisplayMode.FORM
          } else if (
            [...parentSectionItems, sectionItem]
            // .filter(si => si.section.section_type === sectionItem.section.section_type)
            .some((si) => si.section.display_mode === SectionDisplayMode.FORM)
          ) {
            formView = true

          }
        }

      }

      const visibleAnswers = answersThisDocumentVersion
      .filter(
        answer => answer.parent_answer_viid === parentAnswerViid && answer.result_visible
      )

      // Show context for inline questions
      if (
        inline &&
        (requiredOnly || showEmptyOnly) &&
        !!visibleAnswers.some((answer) => {
          const section = getters.sections.find(s => s.viid === answer.section_viid)
          return !section.answer_optional && answer.isEmpty(getters.document.current_locale)
        })
      ) {
        const parentAnswerItem = answers.find((answerItem) => answerItem.answer.viid === parentAnswerViid)
        if (parentAnswerItem) {
          parentAnswerItem.childRequiredVisible = true
        }
      }

      const answersToBeSorted = []

      orderBy(
        visibleAnswers,
        a => a.data?.position || 0,
      ).forEach((answer) => {
        let childSectionItem
        // When top-level, get via sectionTree(s)
        if (!parentAnswerViid) {
          const allSectionTrees = store.getters['blueprint/sectionTreesByBlueprintVersion'](getters.documentVersionBlueprintVersion.version)
          Object.keys(allSectionTrees).some((topLevelSectionType) => {
            return allSectionTrees[topLevelSectionType].some((sectionItem) => {
              if (sectionItem.section.viid === answer.section_viid) {
                childSectionItem = sectionItem
                return true
              }

              return false

            })
          })

        // via current children otherwise
        } else {
          childSectionItem = sectionItem.children.find(si => si.section.viid === answer.section_viid)

        }

        if (!childSectionItem) {
          return
        }

        const containerStylesCopy = { ...containerStyles }

        // only skip first if duplicates exist
        const skip = (answer.data?.position || 0) > 1 ? false : skipFirst

        formView = formView || childSectionItem.section.display_mode === SectionDisplayMode.FORM
        const disablePositionThisChild = disablePosition || childSectionItem.section.data?.disable_position

        answersToBeSorted.push({
          answer,
          section: childSectionItem.section,
          fullPosition: childSectionItem.fullPosition,
          actualPosition: childSectionItem.actualPosition,
          position: childSectionItem.position,
          level: level + 1,
          inline,
          formView,
          containerStyles: containerStylesCopy,
          visible: true,
          disablePosition: disablePositionThisChild,
          skip,
          childSectionItem,
        })

      })

      if (sectionItem && answersToBeSorted.length) {
        parentSectionItems.push(sectionItem)
      }

      let previousSectionViid = null
      let answerCounter = null

      const answersSorted = orderBy(
        answersToBeSorted,
        [
          a => a.position,
        ],
      )

      answersSorted.forEach((answerItem) => {
        if ([...parentSectionItems, sectionItem, answerItem.childSectionItem].some(
          (si) => si?.section.allow_multiple_answers)
        ) {
          if (
            answerItem.section.viid === previousSectionViid
          ) {
            answerCounter++

          } else {
            answerCounter = 0

          }

        } else {
          answerCounter = null

        }

        previousSectionViid = answerItem.childSectionItem.section.viid

        isMultiAnswer = parentIsMultiAnswer || (answerCounter !== null && !!sectionItem)

        let maxAnswerCounter = 1
        if (isMultiAnswer) {
          maxAnswerCounter = answersSorted.filter(a => a.section.viid === answerItem.section.viid).length
        }

        const containerClasses = []

        // skip setting border for non-first children of column parents
        // also skip if answer has no children
        if (
          isMultiAnswer &&
          (
            answersThisDocumentVersion
            .filter(
              answer => answer.parent_answer_viid === answerItem.answer.viid
            )
            .length > 0 ||
            parentIsMultiAnswer
          )
        ) {
          const answerCounterOrParentAnswerCounter = parentAnswerCounter || answerCounter
          const classCounter = (answerCounterOrParentAnswerCounter % 5) + 1

          containerClasses.push('multi-answer-background-' + classCounter)

          if (
            (!sectionItem || sectionItem.section.display_mode !== SectionDisplayMode.COLUMNS) ||
            answersSorted.indexOf(answerItem) === 0
          ) {
            containerClasses.push('multi-answer-border-' + classCounter)
          }
        }

        answerItem = {
          ...answerItem,
          answerCounter,
          maxAnswerCounter,
          containerClasses,
        }

        if ([answerItem.childSectionItem, sectionItem, ...parentSectionItems].some(
          (si) => si?.section?.result_type === SectionResultType.COLUMNS
        )) {
          resultColumns = true

        } else {
          resultColumns = false
          parentStartedColumns = false
          columnParentAnswerItem = null

        }

        if (resultColumns || parentStartedColumns) {
          answerItem.childAnswerItems = []
        }

        // skip all columns + children
        if (columnParentAnswerItem) {
          columnParentAnswerItem.childAnswerItems.push(answerItem)
          answerItem.skip = true
        }

        if (!answerItem.skip) {
          answers.push(answerItem)
        }

        // only handle current page if not onePageMode etc
        if (!getters.showAllPages && !parentAnswerViid) {

          const page = getters.topLevelAnswers.findIndex((a) => a.viid === answerItem.answer.viid) + 1

          if (page !== getters.currentPage) {
            return
          }
        }

        mapSectionItem({
          sectionItem: answerItem.childSectionItem,
          level: level + 1,
          parentAnswerViid: answerItem.answer.viid,
          skipFirst: false,
          parentIsMultiAnswer: isMultiAnswer,
          parentAnswerCounter: parentAnswerCounter || answerCounter,
          columnParentAnswerItem: resultColumns || columnParentAnswerItem
            ? (parentStartedColumns ? answerItem : (columnParentAnswerItem || answerItem))
            : null,
          parentStartedColumns: resultColumns,
        })

      })

      const indexInParentSectionItems = parentSectionItems.indexOf(sectionItem)
      if (indexInParentSectionItems !== -1) {
        parentSectionItems.splice(indexInParentSectionItems, 1)
      }

    }

    const skipFirst = getters.currentSectionTypes.indexOf(SectionType.SUBSECTION) !== -1 && !getters.showAllPages

    mapSectionItem({
      sectionItem: null,
      level: 0,
      parentAnswerViid: null,
      skipFirst,
    })

    return answers

  },
  filteredAnswerItems(state, getters) {
    return getters.sortedAndMappedAnswers.filter((answerItem) => {
      return getters.currentSectionTypes.indexOf(answerItem.section.section_type) !== -1
    })
  },
  showInResultAnswerItems(state, getters) {
    const showInResultAnswerItems = []
    const addToShowInResultAnswerItems = (section, parentAnswerViid, parentAnswerItem, addToChildAnswerItems) => {
      const answerItems = getters.sortedAndMappedAnswers.filter(
        ai => ai.section.viid === section.viid && (parentAnswerViid ? (ai.answer.parent_answer_viid === parentAnswerViid) : true)
      )


      // no answerItem means answer.result_visible is false
      if (answerItems.length > 0) {
        for (const answerItem of answerItems) {
          if (addToChildAnswerItems) {
            parentAnswerItem.childAnswerItems.push(answerItem)

          } else if (showInResultAnswerItems.indexOf(answerItem) === -1) {
            showInResultAnswerItems.push(answerItem)

          }

          const childSections = getters.sections
            .filter((_section) => _section.parent_section_viid === section.viid && !_section.data?.exclude_from_results)

          if (answerItem.section.result_type === SectionResultType.COLUMNS || addToChildAnswerItems) {
            answerItem.childAnswerItems = []
            childSections.forEach(
              (childSection) => {
                addToChildAnswerItems(childSection, answerItem.answer.viid, answerItem, true)
              }
            )

          } else {
            childSections.forEach(
              (childSection) => addToShowInResultAnswerItems(childSection, answerItem.answer.viid, showInResultAnswerItems)
            )

          }
        }

      }
    }

    getters.sections
      .filter((section) => {
        return (
          (
            section.section_type === SectionType.QUESTION ||
            section.section_type === SectionType.SUBSECTION
          ) &&
          section.data?.show_in_results &&
          !section.data?.exclude_from_results
        )
      })
      .forEach((section) => addToShowInResultAnswerItems(section, null))

    return showInResultAnswerItems
  },
  mappedSelectGroups: state => state.mappedSelectGroups,
  currentSectionTypes: (state, getters) => {
    let currentSectionTypes

    if (store.getters['navbar/currentRoute'].name.indexOf('results') === -1) {
      currentSectionTypes = [SectionType.SUBSECTION, SectionType.QUESTION]

      if (getters.document.result_view_mode === ResultViewMode.INLINE) {
        currentSectionTypes.push(SectionType.RESULT)
      }

    } else {
      currentSectionTypes = [SectionType.RESULT]

    }
    // store.commit('currentDocument/SET_CURRENT_SECTION_TYPES', currentSectionTypes)
    return currentSectionTypes

  },
  routerLoading() {
    return store.getters['global/routerLoading']
  },
  initialRefreshDone: state => state.initialRefreshDone,
  currentChecklistGuide: state => state.currentChecklistGuide,
  documentId: state => state.documentId,
  tsSourceLocale: state => state.tsSourceLocale,
  showBlueprintTranslationSuggestions: state => state.showBlueprintTranslationSuggestions,
  rriCollapsed: state => state.rriCollapsed,
}

export const actions = {
  init({ state, dispatch }) {},

  setCurrentDocumentId({ commit }, documentId) {
    commit('SET_CURRENT_DOCUMENT_ID', { documentId })
  },

  async reloadDocument({ commit, dispatch, state, getters }, { documentVersion = 'edit' } = {}) {
    commit('SET_PAGE_ROUTE_LOADING', { value: true })

    await dispatch('resetData')

    if (!state.documentId) {
      return
    }

    if (!getters.document) {
      await Document.$find(state.documentId)
    }

    const promises = []

    const models = ['answers']
    const params = {
      section_types: [SectionType.QUESTION, SectionType.SUBSECTION],
    }

    if (getters.document.result_view_mode === ResultViewMode.INLINE) {
      params.section_types.push(SectionType.RESULT)
      params.include_assgoruddvas = true
      models.push('assgoruds')
    }

    let dv = await dispatch('refreshDocumentVersion', { documentVersion, params })

    promises.push(getters.document.fillFromLocalStorage())

    if (getters.document.company_id) {
      promises.push(
        Company.$find(getters.document.company_id).then((r) => {
          return getters.company.resolveSubResource(CompanyPerson, {
            params: { include_company_roles: true },
          })
        })
      )
    }

    promises.push(
      getters.document.resolveBlueprint()
      .then((r) => {
        if (r.response?.data?.current_blueprint_version) {
          store.commit(
            'blueprint/SET_CURRENT_BLUEPRINT_VERSION',
            {
              currentBlueprintVersion: r.entities['document-blueprint-versions'].find(
                dbv => dbv.id === r.response.data.current_blueprint_version.id
              )
            },
          )
        }

        return r

      })
    )
    /* promises.push(
      dv.refreshBlueprintVersion(
        { params: { exclude_ssgors: true } }
      )
    ) */

    if (getters.document && getters.document.document_type === DocumentType.TEMPLATE) {
      promises.push(getters.document.resolveSubResource(CompanyDocumentTemplateAllotment))
    }

    getters.folderVendorAssociations.forEach((folderVendorAssociation) => {
      promises.push(Vendor.$find(folderVendorAssociation.vendor_id))
    })

    await Promise.all(promises)

    store.commit(
      'blueprint/SET_DOCUMENT_BLUEPRINT_ID',
      { documentBlueprintId: getters.document.document_blueprint_id },
    )

    await getters.document.refreshBlueprint()

    await DocumentBlueprintVersion.$find(dv.document_blueprint_version_id)

    const documentBlueprintVersion = DocumentBlueprintVersion.find(dv.document_blueprint_version_id)
    store.commit('blueprint/SET_DOCUMENT_BLUEPRINT_VERSION', { documentBlueprintVersion })

    await store.dispatch(
      'blueprint/refreshBlueprintVersion',
      { documentBlueprintVersion },
    )

    // build both sections + results for now
    await store.dispatch(
      'blueprint/buildSectionTree',
      {
        blueprintVersion: documentBlueprintVersion.version,
        sectionTypes: [SectionType.SUBSECTION, SectionType.QUESTION],
      },
    )
    await store.dispatch(
      'blueprint/buildSectionTree',
      {
        blueprintVersion: documentBlueprintVersion.version,
        sectionTypes: [SectionType.RESULT],
      },
    )
    await store.dispatch(
      'blueprint/buildSectionTree',
      {
        blueprintVersion: documentBlueprintVersion.version,
        sectionTypes: [SectionType.CONDITION_GROUP],
      },
    )

    if (documentVersion === 'edit') {
      const documentVersion = getters.document.edit_document_version || dv
      commit('SET_EDIT_VERSION', { documentVersion })

    } else {
      commit('SET_EDIT_VERSION', { dv })
    }

    dispatch('setSelectedTopLevelAnswer')

    await dispatch('querySelectGroups')

    emitter.on('querySelectGroups', () => {
      dispatch('querySelectGroups')
    })

    const answersThisDocumentVersion = getters.answersDocumentVersion(getters.documentVersion.version)
    const referencedDocumentIds = []
    answersThisDocumentVersion.forEach((answer) => {
      if (
        answer.data?.repository?.document_id
        && !referencedDocumentIds.includes(answer.data.repository.document_id)
      ) {
        referencedDocumentIds.push(answer.data.repository.document_id)
      }
    })

    const referencedDocumentPromises = []

    referencedDocumentIds.forEach((documentId) => {
      referencedDocumentPromises.push(Document.$find(documentId, {
        params: {
          include_document_blueprint: true,
        },
      }))
    })
    await Promise.allSettled(referencedDocumentPromises)

    commit('SET_PAGE_ROUTE_LOADING', { value: false })

    // used to not double load results
    commit('SET_INITIAL_REFRESH_DONE', true)

  },

  async refreshDocumentVersion({ getters, commit, dispatch }, { documentVersion, params, models } = {}) {
    models = models || [
      'answers',
      'assgoruds',
    ]

    let dv

    if (documentVersion === 'edit') {
      dv = await getters.document.getEditVersion({ params })

    } else {
      dv = await getters.document.getDocumentVersionByVersion({ documentVersion })

    }

    const promises = []

    if (models.includes('answers')) {
      promises.push(dispatch('refreshAnswers', { documentVersion: dv }))
    }

    if (models.includes('assgoruds')) {
      promises.push(dispatch('refreshAnswerSectionSelectGroupOptionResultUserData', { documentVersion: dv }))
    }

    await Promise.allSettled(promises)

    return dv

  },

  async refreshAnswers({ commit }, { documentVersion }) {
    const currentRoute = store.getters['navbar/currentRoute']
    const answerParams  = {}
    if (
        currentRoute.name !== 'documents.detail.results' &&
        currentRoute.name !== 'admin.documentBlueprint.templates.detail.results' &&
        currentRoute.name !== 'deletion-concept.results'
    ) {
      answerParams.without_result_content = true
    }
    const results = await documentVersion.refreshSubResource(Answer, {
      params: answerParams,
    })
    const resultIds = results.response.data.map(d => d.id)

    commit('SET_ANSWERS_FOR_DOCUMENT_VERSION', {
      documentVersion: documentVersion.version,
      answers: resultIds.map(aId => results.entities['answers'].find(a => a.id === aId)),
    })

  },

  async refreshAnswerSectionSelectGroupOptionResultUserData({ commit }, { documentVersion }) {
    const results = await documentVersion.refreshSubResource(AnswerSectionSelectGroupOptionResultUserData)
    const resultIds = results.response.data.map(d => d.id)

    commit('SET_ANSWER_SECTION_SELECT_GROUP_OPTION_RESULT_USER_DATA_FOR_DOCUMENT_VERSION', {
      documentVersion: documentVersion.version,
      answerSectionSelectGroupOptionResultUserData: resultIds.map(aId => results.entities[
        'answer-section-select-group-option-result-user-data'
      ].find(a => a.id === aId)),
    })

  },

  setPageRouteLoading({ commit }, value) {
    commit('SET_PAGE_ROUTE_LOADING', { value: value || false })
  },

  querySelectGroups({ commit, getters, rootGetters }) {
    const selectGroups = getters.selectGroups
    const selectGroupOptions = getters.selectGroupOptions
    const sectionSelectGroupOptionResults = getters.sectionSelectGroupOptionResults
    const answers = getters.answersDocumentVersion(getters.documentVersion.version)

    const mappedSelectGroups = selectGroups.map((sg) => {
      const sgoSelectGroupOptions = selectGroupOptions
      .filter(sgo => sgo.select_group_viid === sg.viid)
      .map((sgo) => {
        const sgoAnswers = answers.filter(a => a.select_group_option_viid === sgo.viid)
        .map((answer) => {
          const sectionItem = getters.flattenedSectionItems.find(si => si.section.viid === answer.section_viid)
          const section = sectionItem.section

          return {
            ...answer,
            section,
            sectionItem,
            ssgors: sectionSelectGroupOptionResults.filter(
              ssgor => ssgor.select_group_option_viid === sgo.viid && ssgor.section_viid === section.viid
            )
          }
        })

        return {
          answers: sgoAnswers,
          sectionSelectGroupOptionResults,
          ...sgo,
        }
      })

      const ret = {
        selectGroupOptions: sgoSelectGroupOptions,
        ...sg,
      }

      return ret
    })

    commit('SET_MAPPED_SELECT_GROUPS', { selectGroups: mappedSelectGroups })
  },
  showAllPages({ commit }, showAllPages) {
    commit('SET_SHOW_ALL_PAGES', showAllPages)
  },
  showEmptyOnly({ commit }, showEmptyOnly) {
    commit('SET_SHOW_EMPTY_ONLY', showEmptyOnly)
  },
  showRequiredOnly({ commit }, showRequiredOnly) {
    commit('SET_SHOW_REQUIRED_ONLY', showRequiredOnly)
  },
  setSelectedTopLevelAnswer({ state, commit, getters }, selectedTopLevelAnswer) {
    if (!selectedTopLevelAnswer) {
      // set tla even if page param not available to avoid error on route change to page
      selectedTopLevelAnswer = getters.topLevelAnswers.find(
        (a) =>
          getters.topLevelAnswers.findIndex((_a) => a.id === _a.id) ===
            (store.getters['navbar/currentRoute'].params.page || 1) - 1
      )

    } else if (selectedTopLevelAnswer.id !== state.selectedTopLevelAnswer?.id) {
      // TODO
      // commit('SET_PAGE_ROUTE_LOADING', true)

      const page = getters.topLevelAnswers.findIndex((_a) => _a.id === selectedTopLevelAnswer.id) + 1
      const currentRoute = store.getters['navbar/currentRoute']

      $router.push({
        name: currentRoute.name,
        params: {
          ...currentRoute.params,
          page,
        },
      })

    }

    commit('SET_SELECTED_TOP_LEVEL_ANSWER', selectedTopLevelAnswer)

  },
  setCurrentPage({ commit }, currentPage) {
    commit('SET_CURRENT_PAGE', currentPage)
  },
  async resetData({ commit }) {
    const deletePromises = []

    /* deletePromises.push(SelectGroup.deleteAll())
    deletePromises.push(SelectGroupOption.deleteAll())
    deletePromises.push(Section.deleteAll())
    deletePromises.push(SectionSelectGroupOptionResult.deleteAll()) */

    // deletePromises.push(SelectGroupDocumentBlueprintVersionAssociation.deleteAll())
    // deletePromises.push(SelectGroupOptionDocumentBlueprintVersionAssociation.deleteAll())
    // deletePromises.push(SectionDocumentBlueprintAssociation.deleteAll())
    // deletePromises.push(SectionSelectGroupOptionResultDocumentBlueprintVersionAssociation.deleteAll())

    deletePromises.push(DocumentVersion.deleteAll())

    deletePromises.push(Approval.deleteAll())
    deletePromises.push(TaskCompanyRoleAssociation.deleteAll())
    deletePromises.push(Task.deleteAll())
    deletePromises.push(ApprovalComment.deleteAll())
    deletePromises.push(Reminder.deleteAll())
    deletePromises.push(Timer.deleteAll())

    deletePromises.push(Answer.deleteAll())
    // deletePromises.push(AnswerDocumentAssociation.deleteAll())

    deletePromises.push(AnswerSectionSelectGroupOptionResultUserData.deleteAll())
    // deletePromises.push(AnswerSectionSelectGroupOptionResultUserDataDocumentVersionAssociation.deleteAll())

    await Promise.all(deletePromises)

    commit('SET_SHOW_ALL_PAGES', false)
    commit('SET_SHOW_REQUIRED_ONLY', false)

  },

  updateAnswerInAllVersions({ commit, state }, { answer }) {
    state.answers.forEach((item) => {
      const documentVersion = item.documentVersion
      commit('UPDATE_ANSWER', { answer, documentVersion })
    })
  },

  updateAssgorudInAllVersions({ commit, state }, { assgorud }) {
    state.answerSectionSelectGroupOptionResultUserData.forEach((item) => {
      const documentVersion = item.documentVersion
      commit('UPDATE_ANSWER_SECTION_SELECT_GROUP_OPTION_RESULT_USER_DATA', {
        answerSectionSelectGroupOptionResultUserData: assgorud,
        documentVersion,
      })
    })
  },

  setCurrentChecklistGuide({ commit }, checklistGuide) {
    commit('SET_CURRENT_CHECKLIST_GUIDE', checklistGuide)
  },

  setShowBlueprintTranslationSuggestions({ commit }, showBlueprintTranslationSuggestions) {
    commit('SET_BLUEPRINT_TRANSLATION_SUGGESTIONS', showBlueprintTranslationSuggestions)
  },

  setRriCollapsed({ commit }, { key, value }) {
    commit('SET_RRI_COLLAPSED', { key, value })
  },

}
