import VuexORM from '@vuex-orm/core';
import VuexORMAxios from '@vuex-orm/plugin-axios';
import axios from 'axios';
import { createStore } from 'vuex';
import vuetify from '../plugins/vuetify';

import ScormFileCategory from '@state/models/scorm-file-category';
import ScormFileCategoryAssociation from '@state/models/scorm-file-category-association';
import Address from './models/address';
import Answer from './models/answer';
import {
  AnswerSectionSelectGroupOptionResultUserData,
} from './models/answer-section-select-group-option-result-user-data';
import Approval from './models/approval';
import ApprovalComment from './models/approval-comment';
import DocsAuditProject from './models/audit/docs-audit-project';
import DocsAuditProjectQuestion from './models/audit/docs-audit-project-question';
import Basket from './models/basket';
import BlogCategory from './models/blog-category';
import BlogCategoryAssociation from './models/blog-category-association';
import BrandSetting from './models/brand-setting';
import Changelog from './models/changelog';
import Checklist from './models/checklist';
import ChecklistStep from './models/checklist-step';
import ChecklistUserActivation from './models/checklist-user-activation';
import Commit from './models/commit';
import Company from './models/company';
import CompanyDocumentTemplateAllotment from './models/company-document-template-allotment';
import CompanyPerson from './models/company-person';
import CompanyPersonType from './models/company-person-type';
import CompanyRole from './models/company-role';
import CompanyRoleScormStatus from './models/company-role-scorm-status';
import Condition from './models/condition';
import { Document, DocumentDiffData } from './models/document';
import DocumentBlueprint from './models/document-blueprint';
import {
  DocumentBlueprintVersion,
} from './models/document-blueprint-version';
import { DocumentVersion } from './models/document-version';
import DownloadResource from './models/download-resource';
import Event from './models/event';
import File from './models/file';
import FileFolderDocumentBlueprintAssociation from './models/file-folder-document-blueprint-association';
import Folder from './models/folder';
import FolderLink from './models/folder-link';
import FolderTagAssociation from './models/folder-tag-association';
import FolderVendorAssociation from './models/folder-vendor-association';
import Invite from './models/invite';
import InviteCode from './models/invite-code';
import License from './models/license';
import MenuFolderSetting from './models/menu-folder-setting';
import ModelSearchResult from './models/model-search-result';
import Order from './models/order';
import PermissionRole from './models/permission-role';
import PermissionRoleCompanyAssociation from './models/permission-role-company-association';
import PermissionRoleCompanyRoleAssociation from './models/permission-role-company-role-association';
import PermissionRoleModelAssociation from './models/permission-role-model-association';
import PermissionRoleUserAssociation from './models/permission-role-user-association';
import PrimaFolderTemplate from './models/prima-folder-template';
import PrimaFolderTemplateAssociation from './models/prima-folder-template-association';
import Promotion from './models/promotion';
import Reminder from './models/reminder';
import ReminderUserAssociation from './models/reminder-user-association';
import RepositoryReferenceItem from './models/repository-reference-item';
import ScormFile from './models/scorm-file';
import ScormFileCompanyAssociation from './models/scorm-file-company-association';
import ScormFileCompanyBooking from './models/scorm-file-company-booking';
import Section from './models/section';
import SectionReferenceItem from './models/section-reference-item';
import {
  SectionSelectGroupOptionResult,
} from './models/section-select-group-option-result';
import {
  SelectGroup,
  SelectGroupOption,
} from './models/select-group';
import TableColumn from './models/table-column';
import TableColumnSettingAssociation from './models/table-column-setting-association';
import TableSetting from './models/table-setting';
import TableSettingActivation from './models/table-setting-activation';
import Tag from './models/tag';
import Task from './models/task';
import TaskCompanyRoleAssociation from './models/task-company-role-association';
import Timer from './models/timer';
import TosAcceptance from './models/tos-acceptance';
import User from './models/user';
import Vendor from './models/vendor';

import ChecklistStepFolderStatus from './models/checklist-step-folder-status';
import CompanyLink from './models/company-link';
import MailConfig from './models/mail-config';
import Recipient from './models/recipient';
import Rollout from './models/rollout';
import RolloutFileAssociation from './models/rollout-file-association';
import SessionScormLink from './models/session-scorm-link';
import StandardBlueprintFolderAssociation from './models/standard-blueprint-folder-association';
import TimerCompanyRoleAssociation from './models/timer-company-role-association';
import TrainingSession from './models/training-session';
import TrainingSessionCompanyRoleAssociation from './models/training-session-company-role-association';
import TrainingSessionInstance from './models/training-session-instance';
import modules from './modules';
const database = new VuexORM.Database()

database.register(User)
database.register(Address)

database.register(DocumentBlueprint)
database.register(DocumentBlueprintVersion)

database.register(Section)

database.register(Document)
database.register(DocumentDiffData)
database.register(DocumentVersion)
database.register(CompanyDocumentTemplateAllotment)

database.register(Answer)

database.register(Commit)

database.register(Company)
database.register(CompanyRole)
database.register(File)
database.register(Folder)

database.register(DocsAuditProject)
database.register(DocsAuditProjectQuestion)

database.register(ScormFile)
database.register(ScormFileCategory)
database.register(ScormFileCompanyAssociation)
database.register(ScormFileCategoryAssociation)
database.register(CompanyRoleScormStatus)
database.register(ScormFileCompanyBooking)

database.register(Invite)

database.register(SelectGroup)
database.register(SelectGroupOption)

database.register(FileFolderDocumentBlueprintAssociation)

database.register(SectionSelectGroupOptionResult)

database.register(AnswerSectionSelectGroupOptionResultUserData)

database.register(Changelog)
database.register(FolderLink)

database.register(Vendor)
database.register(FolderVendorAssociation)
database.register(BrandSetting)

database.register(CompanyPerson)
database.register(CompanyPersonType)

database.register(Condition)

database.register(License)
database.register(PermissionRole)
database.register(PermissionRoleUserAssociation)
database.register(PermissionRoleCompanyAssociation)
database.register(PermissionRoleCompanyRoleAssociation)
database.register(PermissionRoleModelAssociation)

database.register(ModelSearchResult)
database.register(Checklist)
database.register(ChecklistStep)

database.register(TosAcceptance)

database.register(ChecklistUserActivation)
database.register(InviteCode)

database.register(Order)
database.register(Basket)
database.register(Event)
database.register(Reminder)
database.register(ReminderUserAssociation)
database.register(SectionReferenceItem)

database.register(Approval)
database.register(ApprovalComment)
database.register(TaskCompanyRoleAssociation)
database.register(Task)
database.register(Timer)

database.register(Promotion)
database.register(DownloadResource)
database.register(RepositoryReferenceItem)

database.register(BlogCategory)
database.register(BlogCategoryAssociation)

database.register(TableColumn)
database.register(TableSetting)
database.register(TableColumnSettingAssociation)
database.register(TableSettingActivation)
database.register(Tag)
database.register(FolderTagAssociation)
database.register(PrimaFolderTemplate)
database.register(PrimaFolderTemplateAssociation)
database.register(MenuFolderSetting)

database.register(Rollout)
database.register(Recipient)
database.register(RolloutFileAssociation)
database.register(ChecklistStepFolderStatus)

database.register(SessionScormLink)
database.register(TrainingSession)
database.register(TrainingSessionCompanyRoleAssociation)
database.register(TrainingSessionInstance)

database.register(CompanyLink)
database.register(MailConfig)

database.register(StandardBlueprintFolderAssociation)

database.register(TimerCompanyRoleAssociation)

VuexORM.use(VuexORMAxios, {
  axios,
  database,
  baseURL: '/api',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const plugins = [
  VuexORM.install(database),
]

const store = createStore({
  namespaced: true,
  modules,
  plugins,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})
store.vuetify = vuetify

export default store
