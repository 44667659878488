<script>
import { matchLawPattern } from '@utils/match-law-pattern'

import store from '@state/store'

function spliceSlice(str, index, count, add) {
  // We cannot pass negative indexes directly to the 2nd slicing operation.
  if (index < 0) {
    index = str.length + index
    if (index < 0) {
      index = 0
    }
  }

  return str.slice(0, index) + (add || '') + str.slice(index + count)
}

function handleHtml(html) {
  if (!html) return

  const matches = matchLawPattern(html)

  if (matches) {
    let runningOffset = 0
    matches.forEach((match) => {
      const startIndex = match.index
      const replacedString = `<a class="law-link" href="javascript:void(0)">${match[0]}</a>`

      html = spliceSlice(html, startIndex + runningOffset, match[0].length, replacedString)

      runningOffset += replacedString.length - match[0].length
    })

    return html
  }
}

function addEventListeners(ele) {
  ele.querySelectorAll('.law-link').forEach((el) => {
    el.addEventListener('click', (e) => {
      const law = e.target.innerText
      store.dispatch('global/setInfoCenterSearchString', law)
    })
  })
}

export default {
  name: 'LawHtml',
  beforeMount(el, binding, vnode) {
    const html = binding.value

    const newHtml = handleHtml(html)
    if (newHtml) {
      el.innerHTML = newHtml
    } else {
      el.innerHTML = html
    }
  },
  updated(el, binding, vnode) {
    const html = binding.value

    const newHtml = handleHtml(html)
    if (newHtml) {
      el.innerHTML = newHtml
      addEventListeners(el)
    }
  },
  mounted(ele) {
    addEventListeners(ele)
  },
}
</script>
