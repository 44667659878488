
import CompanyRole from '@state/models/company-role'
import PlanitModel from '@state/models/planit-model'
import Timer from '@state/models/timer'

export default class TimerCompanyRoleAssociation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

        timer_id: this.attr(),
        timer: this.belongsTo(Timer, 'timer_id'),

        company_role_id: this.attr(),
        company_role: this.belongsTo(CompanyRole, 'company_role_id'),
    }
  }
}

TimerCompanyRoleAssociation.entity = 'timer-company-role-associations'