<script>
import { groupBy } from 'lodash'

import PlanitFile from '@components/planit-file'
import RolloutCard from '@components/rollouts/rollout-card'
import PlanitAlert from '@components/planit-alert'
import CompanyLinkEdit from '@components/company-link-edit'

import {
  ChecklistStepStatus,
  FileExportFormat,
  RolloutState,
  EventType,
  CompanyLinkType,
} from '@enums'

import store from '@state/store'

import { Document } from '@state/models/document'
import FolderLink from '@state/models/folder-link'
import Rollout from '@state/models/rollout'
import CompanyLink from '@state/models/company-link'

import downloadBlobResponse from '@utils/download-blob-response'

export default {
  name: 'CreateDocumentContent',
  components: {
    PlanitFile,
    RolloutCard,
    PlanitAlert,
    CompanyLinkEdit,
  },
  props: {
    status: Object,
  },
  emits: ['set-status'],
  data() {
    return {
      ChecklistStepStatus,
      RolloutState,
      EventType,

      rolloutId: null,
      showRollout: false,
      exportLoading: false,
      rolloutCreateLoading: false,
      showCompanyLinksModal: false,
    }
  },
  watch: {
    currentProgress(v, ov) {
      this.checkProgress(v, ov)
    },
  },
  async beforeMount() {
    if (!this.document) {
      await this.createDocument()
    }

    await this.document.refreshSubResource(Rollout).catch(() => {}) // might 404
  },
  computed: {
    showGuideChecklistDrawer() {
      return this.$store.getters['navbar/showGuideChecklistDrawer']
    },
    currentLocale() {
      return this.$store.getters['auth/currentLocale']
    },
    currentProgress() {
      return this.document?.current_progress
    },
    document() {
      return this.currentFolderDocuments.find(
        (d) =>
          d.document_blueprint.uuid === this.status.checklist_step.data['document_blueprint_uuid']
      )
    },
    activeRollout() {
      if (!this.document) {
        return null
      }
      return Rollout.query()
        .where((r) => r.document_id === this.document.id && r.state !== RolloutState.COMPLETED)
        .first()
    },
    completedRollouts() {
      if (!this.document) {
        return []
      }
      return Rollout.query()
        .where((r) => r.document_id === this.document.id && r.state === RolloutState.COMPLETED)
        .all()
    },
    company() {
      return this.$store.getters['navbar/selectedCompany']
    },
    currentFolder() {
      return this.$store.getters['files/currentFolder']
    },
    currentFolderDocuments() {
      const folderLinkDocumentIds = FolderLink.allFast()
        .filter((fl) => fl.folder_id === this.currentFolder.id)
        .map((fl) => fl.document_id)

      return Document.query()
        .where((d) => folderLinkDocumentIds.includes(d.id))
        .with('document_blueprint')
        .with('document_blueprint.folder_links')
        .with('last_modified_by')
        .all()
    },
    linkedDocumentBlueprints() {
      return this.$store.getters['files/linkedDocumentBlueprints']
    },
    imprintAndPrivacyPolicySet() {
      const companyLinksByType = groupBy(
        CompanyLink.query().where('company_id', this.company.id).all(),
        'company_link_type',
      )

      return companyLinksByType[CompanyLinkType.IMPRINT]?.length > 0 &&
        companyLinksByType[CompanyLinkType.PRIVACY_POLICY]?.length > 0
    },
    myCompanyRole() {
      return store.getters['navbar/myCompanyRole']
    },
    isAdmin() {
      return store.getters['auth/isAdmin']
    },
  },
  methods: {
    async createRollout(evt) {
      const data = {
        company_id: this.company.id,
        name: this.document.name,
        document_id: this.document.id,
        document_result_as_email_content:
          this.status.checklist_step.data.rollout.document_result_as_email_content,
        with_confirmation: this.status.checklist_step.data.rollout.with_confirmation,
        email_only_mode: this.status.checklist_step.data.rollout.email_only_mode,
        email_as_password: this.status.checklist_step.data.rollout.email_as_password || false,
      }

      if (
        this.status.checklist_step.data.import_email_from &&
        this.status.checklist_step.data.import_email_from.document_blueprint_uuid &&
        this.status.checklist_step.data.import_email_from.section_viid
      ) {
        data.import_email_from = this.status.checklist_step.data.import_email_from
      }

      if (this.status.checklist_step.data.rollout?.email_text_translations) {
        data.email_message = this.status.checklist_step.data.rollout.email_text_translations[this.currentLocale]
      }

      this.rolloutCreateLoading = true

      await Rollout.$new(data).then((r) => {
        this.rolloutCreateLoading = false
        this.rolloutId = r.response.data.id
        this.showRollout = true
      })
    },
    async createDocument() {
      const blueprint = this.linkedDocumentBlueprints.find(
        (b) => b.uuid === this.status.checklist_step.data['document_blueprint_uuid']
      )
      await this.company.createDocumentFromBlueprint(
        blueprint,
        null,
        null,
        this.currentFolder.id,
        true,
        null,
        null
      )
    },
    async onEmailSent() {
      this.showRollout = false
      this.$emit('set-status', {
        checklistStepStatus: ChecklistStepStatus.FINISHED,
        skipEmitTaskFinish: false,
      })
      await this.document.refreshSubResource(Rollout)
    },
    checkProgress(v, ov) {
      if ((!ov || ov < 100) && v == 100) {
        this.$emit('set-status', {
          checklistStepStatus: ChecklistStepStatus.FINISHED,
          skipEmitTaskFinish: true,
        })
      } else if (ov == 100 && v < 100) {
        this.$emit('set-status', {
          checklistStepStatus: ChecklistStepStatus.STARTED,
          skipEmitTaskFinish: true,
        })
      }
    },
    async downloadDocumentExport() {
      this.exportLoading = true
      const options = {
        includeComments: false,
        includeAllOptionItems: false,
        includeInfoTexts: false,
        locale: this.currentLocale,
        excludeHeader: true,
        resultsOnly: true,
        fileType: FileExportFormat.PDF,
        exportSync: true,
      }

      const r = await this.document.createExport(options, {
        responseType: 'blob',
      })

      downloadBlobResponse(r.response)

      this.exportLoading = false
    },
    deleteRollout(isOpen) {
      if (!isOpen && this.activeRollout && this.activeRollout.state !== RolloutState.COMPLETED) {
        this.activeRollout.delete()
      }
      this.rolloutId = null
    },
  },
}
</script>
<template>
  <div>
    <RolloutCard
      v-if="showRollout"
      v-model:show-dialog="showRollout"
      :rollout-id="rolloutId"
      :email-only-mode="status.checklist_step.data.rollout.email_only_mode"
      :rolloutTitle="document.name"
      :excludeTabs="status.checklist_step.data.rollout.excluded_tabs"
      :show-attachments="status.checklist_step.data.rollout.show_attachments"
      show-privacy-policy-select
      @update:show-dialog="deleteRollout"
      @email-sent="onEmailSent"
    ></RolloutCard>

    <PlanitDialog
      v-if="showCompanyLinksModal"
      :model-value="showCompanyLinksModal"
      width="40rem"
      content-class="bg-white"
    >
      <div class="pa-6">
        <div class="d-flex align-center">
          <h1>{{ $t('rollouts.manage_company_links') }}</h1>
          <PlanitButton
            variant="text"
            class="icon-btn ml-auto"
            @click="showCompanyLinksModal = false"
          >
            <PlanitIcon icon="fal fa-times"></PlanitIcon>
          </PlanitButton>
        </div>
        <CompanyLinkEdit></CompanyLinkEdit>
      </div>
    </PlanitDialog>

    <div
      v-if="
        !imprintAndPrivacyPolicySet
        && status.checklist_step.data.rollout.enabled
      "
      class="my-6"
    >
      <PlanitAlert
        color="info"
        :icon="null"
        border="start"
      >
        <div>
          <strong class="mb-2">{{ $t('rollouts.imprint_and_privacy_policy_not_set') }}</strong>
          <p class="mb-4">{{ $t('rollouts.imprint_and_privacy_policy_not_set_description') }}</p>

          <div class="d-flex align-center">
            <PlanitButton
              color="primary"
              variant="text"
              :disabled="
                !isAdmin && !myCompanyRole.is_company_admin && !myCompanyRole.is_training_admin
              "
              @click="showCompanyLinksModal = true"
            >
              <PlanitIcon
                size="small"
                class="mr-1"
                icon="fal fa-link"
              ></PlanitIcon>

              {{ $t('rollouts.manage_company_links') }}
            </PlanitButton>

            <PlanitButton
              color="primary"
              variant="text"
              class="ml-4"
              :disabled="!isAdmin && !myCompanyRole.is_company_admin"
              :to="{ name: 'company.settings.general' }"
            >
              <PlanitIcon
                size="small"
                class="mr-1"
                icon="fal fa-cog"
              ></PlanitIcon>

              {{ $t('rollouts.manage_company_email_config') }}
            </PlanitButton>
          </div>
        </div>
      </PlanitAlert>
    </div>

    <div class="d-flex align-top justify-between mt-4">
      <v-row>
        <v-col
          sm="12"
          :lg="showGuideChecklistDrawer ? '12' : '3'"
        >
          <div class="mb-2">
            <strong>{{ $t('rollouts.fill_out_document') }}</strong>
          </div>
          <PlanitFile
            :document="document"
            show-primary-folder-name
            show-blueprint-name
          ></PlanitFile>
        </v-col>
        <v-col
          v-if="status.checklist_step.data.rollout.enabled"
          sm="12"
          :lg="showGuideChecklistDrawer ? '12' : '3'"
        >
          <div class="mb-2">
            <strong>{{ $t('rollouts.send_mail_from_prima') }}</strong>
          </div>

          <PlanitButton
            :loading="rolloutCreateLoading"
            :disabled="!imprintAndPrivacyPolicySet"
            class="mr-2"
            color="primary"
            variant="text"
            @click="createRollout"
          >
            <PlanitIcon
              size="small"
              class="mr-1"
             icon="far fa-fw fa-envelope
            "/>
            {{ $t('rollouts.send_mail') }}
          </PlanitButton>
        </v-col>
        <v-col
          sm="12"
          :lg="showGuideChecklistDrawer ? '12' : '3'"
          v-if="status.checklist_step.data.rollout.enabled && completedRollouts.length > 0"
        >
          <div class="mb-2">
            <strong> {{ $t('rollouts.sent_emails') }}</strong>
          </div>
          <div
            v-for="rollout in completedRollouts"
            :key="rollout.id"
          >
            <PlanitButton
              class="mr-2"
              color="primary"
              variant="text"
              @click="
                () => {
                  showRollout = true
                  rolloutId = rollout.id
                }
              "
            >
              <PlanitIcon
                size="small"
                class="mr-1"
              >
                far fa-fw fa-envelope
              </PlanitIcon>
              {{ $filters.formatDate(rollout.sent_at, 'Pp') }}
            </PlanitButton>
          </div>
        </v-col>
        <v-col
          sm="12"
          :lg="showGuideChecklistDrawer ? '12' : '3'"
        >
          <div class="mb-2">
            <strong> {{ $t('rollouts.download_export') }}</strong>
          </div>
          <PlanitButton
            @click="downloadDocumentExport"
            class="mr-2"
            variant="text"
            color="primary"
          >
            <PlanitIcon
              size="small"
              class="mr-1"
            >
              far fa-fw fa-download
            </PlanitIcon>
            {{ $t('generic.download') }}
          </PlanitButton>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
