<script>
import {
  cloneDeep,
  merge,
  sortBy,
  minBy,
  max,
  debounce,
  get,
} from 'lodash'

import {
  parseISO,
} from 'date-fns'
import format from 'date-fns/format'

import {
  QuestionType,
} from '@enums'

import PlanitMarkdown from '@components/planit-markdown'
import AutoGrowTextarea from '@components/form/auto-grow-textarea'
import DateTimePicker from '@components/form/date-time-picker'
import ConfirmModal from '@components/confirm-modal'
import IconButton from '@components/icon-button'

import store from '@state/store'
import Section from '@state/models/section'
import PlanitModel from '@state/models/planit-model'

import { emitter } from '@utils/global-events'
import {
  dateToISOString,
} from '@utils/date-helpers'


export default {
  components: {
    DateTimePicker,
    PlanitMarkdown,
    AutoGrowTextarea,
    ConfirmModal,
    IconButton,
  },
  props: {
    type: { type: String, required: false, default: 'text' },
    inputType: { type: String, required: false, default: 'text' },
    checkboxType: { type: String, required: false, default: 'checkbox' }, // currently 'checkbox' or 'radio'
    checkboxItem: { type: Object, required: false, default: () => {} }, // currently 'checkbox' or 'radio'
    model: { type: Object, required: true },
    attr: { type: String, required: true },
    label: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' },
    required: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    dontValidate: { type: Boolean, required: false, default: false },
    dontAutosave: { type: Boolean, required: false, default: false },
    items: { type: Array, required: false, default: () => [] },
    itemValue: { type: String, required: false, default: 'value' },
    itemText: { type: String, required: false, default: 'text' },
    selectItems: { type: Array, required: false, default: () => [] },
    afterSave: { type: Function, required: false, default: () => {} },
    rows: { type: Number, required: false, default: 1 },
    lang: { type: String, required: false, default: null },
    documentBlueprintVersion: {
      type: Object,
      required: false,
      default: undefined,
    },
    documentVersion: { type: Object, required: false, default: undefined },
    params: { type: Object, required: false, default: () => {} },
    highlight: { type: Boolean, required: false, default: false },
    immediateSave: { type: Boolean, required: false, default: false },
    onChange: { type: Function, required: false, default: null },
    hideCheckboxOptions: { type: Boolean, require: false, default: false },
    color: { type: String, required: false, default: null },
    saveSingleDateAsString: { type: Boolean, required: false, default: false },
    timespan: { type: Boolean, required: false, default: false },
    isDateTime: { type: Boolean, required: false, default: false },
    startTimeMin: { type: String, required: false, default: undefined },
    startDateMin: { type: String, required: false, default: null },
    forceDark: { type: Boolean, required: false, default: false },
    singleSelectValue: { type: Boolean, required: false, default: false },
    searchInput: { type: String, required: false, default: undefined },
    markdown: { type: Boolean, required: false, default: false },
    reducedMarkdown: { type: Boolean, required: false, default: false },
    hideToolbar: { type: Boolean, required: false, default: true },
    dropdownMultiple: { type: Boolean, required: false, default: false },
    trim: { type: Boolean, required: false, default: false },
    icon: { type: String, required: false, default: null },
    density: { type: String, required: false, default: 'compact' },
    inset: { type: Boolean, required: false, default: false },
    focusOnCreation: { type: Boolean, required: false, default: false },
    toolbarBottom: { type: Boolean, required: false, default: false },
    triggerDialog: { type: Boolean, required: false, default: false },
    disableStickyToolbar: { type: Boolean, required: false, default: false },
    errorMessage: { type: String, required: false, default: null },
    section: { type: Object, required: false, default: null },
    saveOnBlur: { type: Boolean, required: false, default: false },
    dropdownListView: { type: Boolean, required: false, default: false },
    overrideExistingQueueItem: { type: Boolean, required: false, default: false },
    saveSingleSelectItemAsList: { type: Boolean, required: false, default: false },
    clearable: { type: Boolean, required: false, default: false },
    deletableChips: { type: Boolean, required: false, default: false },
    min: { type: Number, required: false, default: null },
    width: { type: String, required: false, default: null },
    comboboxAllowNewCreation: { type: Boolean, required: false, default: false },
    localAttr: { type: Boolean, required: false, default: false },
    backgroundLevel: { type: Number, required: false, default: null },
    reQueryModelDataBeforeSave: { type: Boolean, required: false, default: false },
    selectItemsSortBy: { type: [String, Array], required: false, default: 'value' },
    hideUnavailableTranslations: { type: Boolean, required: false, default: false },
    document: { type: Object, required: false, default: null },
    sunEditorExtendedButtons: { type: Boolean, required: false, default: false },
  },
  emits: ['update', 'update-start', 'update-end', 'update:searchInput', 'blur', 'focus', 'hasValidationError'],
  data: function () {
    return {
      loading: true,

      validationError: null,
      save: null,
      saving: false,
      itemValues: [],
      checkboxMode: 'single',
      checkboxValue: false,
      newCheckboxItem: {
        text_translations: {},
        custom_text: false,
        is_na_item: false,
      },
      additionalItem: { custom_input_text: {}, is_additional_item: true },
      showDatePickerMenu: false,
      showTimePickerMenu: false,
      showDatePicker2Menu: false,
      showTimePicker2Menu: false,
      timePicker1Value: {},
      timePicker2Value: {},

      itemTree: null,
      itemTreeOpen: [],

      showConfirmModal: false,
      dialogUpdateData: null,
      confirmText: '',
      previousData: null,
      oldItemValues: null,

      skipNextUpdate: false,

      debounceFunctionRegistered: false, // only register once when update is called

      comboboxSearch: null,
    }
  },
  computed: {
    value() {
      let ret = this.getValueDeep(this.model, this.attr)

      if (
        this.lang &&
        this.type !== 'select' &&
        this.type !== 'dropdown' &&
        this.type !== 'autocomplete' &&
        this.type !== 'combobox'
      ) {
        if (!ret) {
          ret = ''
        } else {
          ret = ret[this.lang]
        }
      }

      if (this.markdown && !ret?.length && (this.type === 'text' || this.type === 'textarea')) {
        ret = ''
      }

      if (this.type === 'date') {
        if (ret) {
          let tmpRet
          if (!this.timespan) {
            // handle single date depending on saveSingleDateAsString
            const dateValue = this.saveSingleDateAsString ? ret : ret.date
            tmpRet = { date: (dateValue && parseISO(dateValue)) || null }

            if (this.isDateTime && tmpRet.date) {
              const [hours, minutes] = (ret.time || '00:00').split(':')
              tmpRet.date.setHours(hours)
              tmpRet.date.setMinutes(minutes)

            }

          } else {
            tmpRet = {
              date1: (ret.date1 && parseISO(ret.date1)) || null,
              date2: (ret.date2 && parseISO(ret.date2)) || null,
            }

            if (this.isDateTime) {
              if (tmpRet.date1) {
                const [hours1, minutes1] = (ret.time1 || '00:00').split(':')
                tmpRet.date1.setHours(hours1)
                tmpRet.date1.setMinutes(minutes1)
              }

              if (tmpRet.date2) {
                const [hours2, minutes2] = (ret.time2 || '23:59').split(':')
                tmpRet.date2.setHours(hours2)
                tmpRet.date2.setMinutes(minutes2)
              }
            }

          }

          ret = tmpRet

        } else {
          if (!this.timespan) {
            ret = { date: null }
          } else {
            ret = { date1: null, date2: null }
          }

        }

      }

      if ((this.type === 'autocomplete' || this.type === 'combobox') && !ret) {
        // i.e. attribute path doesnt exist, will be created on first save
        ret = []
      }

      if (this.saveSingleSelectItemAsList && ret.length) {
        ret = ret[0]
      }

      return ret
    },
    currentLocale() {
      return store.getters['auth/currentLocale']
    },
    checkboxItemValue() {
      const lang = this.lang || window.lang
      return this.checkboxItem.text_translations[lang]
    },
    inputDisabled() {
      // return this.disabled || this.saving
      return (this.model && this.model.marked_for_deletion) || this.disabled || this.saving
    },
    additionalItems() {
      if (this.model?.data?.items) {
        return this.model.data.items.filter((i) => !!i.is_additional_item)
      } else {
        return []
      }
    },
    itemValuesComputed: {
      // differentiate between single- and multi-choice
      get() {
        let val

        if (
          this.type === 'checkbox' ||
          (this.type === 'combobox' &&
            (!this.saveSingleSelectItemAsList ||
              (this.comboboxAllowNewCreation && typeof this.itemValues === 'string'))) ||
          (this.type === 'dropdown' && this.dropdownMultiple)
        ) {
          val = this.itemValues
        } else if (
          this.type === 'radio' ||
          ((this.type === 'dropdown' ||
            (this.type === 'combobox' && this.saveSingleSelectItemAsList)) &&
            !this.dropdownMultiple)
        ) {
          val = this.itemValues?.[0]
        } else {
          console.error('itemValuesComputed wrong type')
        }

        return val
      },
      set(newValue) {
        this.oldItemValues = this.itemValues

        if (this.type === 'checkbox') {
          const oldValue = this.itemValues

          // first remove all children of de-selected items recursively
          const addToChildrenToBeDeselectedRecursive = (items) => {
            items.forEach((childItem) => {
              childrenToBeDeselected.push(childItem.value)
              addToChildrenToBeDeselectedRecursive(
                this.items.filter((i) => i.parent_value === childItem.value)
              )
            })
          }

          const childrenToBeDeselected = []
          oldValue.forEach((itemValue) => {
            if (newValue.indexOf(itemValue) === -1) {
              addToChildrenToBeDeselectedRecursive(
                this.items.filter((i) => i.parent_value === itemValue)
              )
            }
          })

          const newValueMinusChildren = newValue.filter(
            (nv) => childrenToBeDeselected.indexOf(nv) === -1
          )

          // then add not-selected parents of new selected items
          const newValuePlusParents = [...newValueMinusChildren]
          newValueMinusChildren.forEach((itemValue) => {
            const item = this.items.find((i) => i.value === itemValue)
            if (item.parent_value && newValuePlusParents.indexOf(item.parent_value) === -1) {
              newValuePlusParents.push(item.parent_value)
            }
          })

          this.itemValues = newValuePlusParents
        } else if (
          this.type === 'combobox' ||
          (this.type === 'dropdown' && this.dropdownMultiple)
        ) {
          this.itemValues = newValue
        } else if (
          this.type === 'radio' ||
          (this.type === 'dropdown' && !this.dropdownMultiple) ||
          this.type === 'combobox'
        ) {
          this.itemValues = [newValue]
        } else {
          console.error('itemValuesComputed wrong type')
        }

        this.update()
      },
    },
    itemsWithLangMappedText() {
      const lang = this.lang || this.currentLocale || window.lang
      const itemsWithLangMappedText = sortBy(
        this.items.map((item) => {
          let text
          if (item.text_translations[lang]) {
            text = item.text_translations[lang]
          } else {
            const companyContentLocale = store.getters['navbar/selectedCompany']?.locale
            const firstAvailableKey = Object.keys(item.text_translations).find(
              k => !!item.text_translations[k]
            )

            text =
              item.text_translations[companyContentLocale] ||
              (firstAvailableKey && item.text_translations[firstAvailableKey]) ||
              ''
          }

          const ret = {
            ...item,
            text,
          }

          // check for custom answer in e.g. model.data.checkbox.items
          const modelValue = this.getValueDeep(this.model, this.attr)
          const correspondingAnswerItem = modelValue?.items?.find((i) => i.value === item.value)
          if (correspondingAnswerItem && correspondingAnswerItem.custom_input_text) {
            ret.custom_input_text = correspondingAnswerItem.custom_input_text
          } else {
            ret.custom_input_text = {}
          }

          return ret
        }),
        (i) => i.position || i.value
      )

      return itemsWithLangMappedText
    },
    selectItemsWithLangMappedText() {
      if (!this.lang) {
        return this.selectItems
      }
      const lang = this.lang || this.currentLocale

      const itemTextAttr =
        this.selectItems.length && this.selectItems[0].name_translations !== undefined
          ? 'name_translations'
          : 'text_translations'

      const selectItemsWithLangMappedText = sortBy(
        this.selectItems
          .filter((item) => !this.hideUnavailableTranslations || item[itemTextAttr][lang])
          .map((item) => {
            const mappedItem = { ...item }

            const companyContentLocale = store.getters['navbar/selectedCompany']?.locale
            const firstAvailableKey = Object.keys(item[itemTextAttr]).find(
              k => !!item[itemTextAttr][k]
            )

            mappedItem[this.itemText] = item[itemTextAttr][lang]
              ? item[itemTextAttr][lang]
              : companyContentLocale && item[itemTextAttr][companyContentLocale]
                ? item[itemTextAttr][companyContentLocale]
                : (
                  (firstAvailableKey && item[itemTextAttr][firstAvailableKey])
                  || ''
                )

            return mappedItem
          }),
        typeof this.selectItemsSortBy === 'string'
          ? (i) => get(i, this.selectItemsSortBy)
          : this.selectItemsSortBy.map((sortByStr) => (i) => get(i, sortByStr))
      )

      if (this.comboboxAllowNewCreation) {
        selectItemsWithLangMappedText.unshift({
          text: this.$t('components.input.select_entry_or_create_new'),
          value: -1,
          disabled: true,
          icon: 'fal fa-edit',
        })
      }

      return selectItemsWithLangMappedText
    },
    selectedItemsWithLangMappedTextAndCustomText() {
      if (this.dropdownMultiple) {
        return this.itemsWithLangMappedText.filter(
          (i) => !!i.custom_text && this.itemValuesComputed.indexOf(i.value) !== -1
        )
      } else {
        return this.itemsWithLangMappedText.filter(
          (i) => !!i.custom_text && this.itemValuesComputed === i.value
        )
      }
    },
    dark() {
      // explicitely set dark and light mode
      return this.forceDark || store.getters['global/themeCSrgb'] < 0.33
    },
    light() {
      return !this.dark
    },
    getDateTimeValue() {
      const value = this.value

      if (this.value === null) {
        return null
      }

      if (this.type === 'date') {
        return value.split('_')[0]
      } else {
        return value.split('_')[1]
      }
    },
    searchInputComputed: {
      get() {
        return this.searchInput || this.comboboxSearch
      },
      set(value) {
        if (!value && !!this.searchInputComputed) {
          return
        }

        if (typeof value !== 'object') {
          this.$emit('update:searchInput', value)
        }

        if (!this.searchInput) {
          this.comboboxSearch = value
        }
      },
    },
    dateMin() {
      return this.getDateMin()
    },
    dateMax() {
      return this.getDateMax()
    },
    timeMin() {
      const dateMin = this.getDateMin()
      return (dateMin && format(dateMin, 'HH:mm')) || undefined
    },
    timeMax() {
      const dateMax = this.getDateMax()
      return (dateMax && format(dateMax, 'HH:mm')) || undefined
    },
    debug() {
      return store.getters['debug/debug']
    },
    sectionComputed() {
      if (this.section) {
        return Section.find(this.section.id)
      }
    },
    isPlanitModel() {
      return this.model instanceof PlanitModel
    },
    error() {
      return this.errorMessage || this.validationError || null
    },
  },
  watch: {
    model(newValue, oldValue) {
      if (this.items.length) {
        const value = this.getValueDeep(this.model, this.attr) || []
        if (value?.items) {
          // existence of normal item means it is selected
          // explicitely get selected attribute of additional items
          this.itemValues = value.items
            .filter((i) => !i.is_additional_item || i.selected === true)
            .map((i) => i.value)
        }
      } else if (this.type === 'checkbox' || this.type === 'switch') {
        this.checkboxValue = this.value
      } else if (this.type === 'combobox') {
        this.setComboboxItemValues()
      }
    },
  },
  async beforeMount() {
    if (this.section && (this.items.length || this.type === 'checkbox')) {
      await this.setInitialItemTreeOpen()
    }

    this.loading = false
  },
  async created() {
    if (this.type === 'checkbox-option') {
      this.initNewCheckboxItem()
    }

    if (
      this.items.length > 1 ||
      this.section?.question_type === QuestionType.CHECKBOX ||
      this.type === QuestionType.RADIO ||
      this.type === QuestionType.DROPDOWN
    ) {
      // if set this would for example be model.data.items
      if (this.model[this.attr]) {
        this.itemValues = (this.model[this.attr].items || [])
          .filter((i) => !i.is_additional_item || i.selected === true)
          .map((i) => i.value)
      }

      this.checkboxMode = 'multiple'

      if (this.type === 'checkbox') {
        this.setItemTree()
      }
    } else if (this.type === 'checkbox' || this.type === 'switch') {
      this.checkboxValue = this.value
    } else if (this.type === 'combobox') {
      this.setComboboxItemValues()
    }

    this.save = (data, options) => {
      const params = { ...this.params }

      const queueArgs = {
        model: this.model,
        method: 'patch',
        data,
        params,
        callback: this.afterSave,
        args: [],
        overrideExistingQueueItem: this.overrideExistingQueueItem,
        errorCallback: async () => {
          if (this.previousData) {
            await this.model.updateAttr({ ...this.previousData })
          }
        },
      }

      if (this.documentVersion) {
        queueArgs.groupSaveModel = this.documentVersion
      } else if (this.documentBlueprintVersion) {
        queueArgs.groupSaveModel = this.documentBlueprintVersion
      }

      return store.dispatch('syncQueue/queue', queueArgs).then((r) => {
        if (this.immediateSave || options?.immediate) {
          store.dispatch('syncQueue/handleQueue')
        }

        return r
      })
    }

    this.updateNow = async (e) => {
      if (this.disabled || this.saving) {
        return
      }

      this.$emit('update-start')

      let saveImmediately = e?.saveImmediately || false

      await store.dispatch('syncQueue/flushDebounceFns')

      if (typeof e === 'string' && this.trim) {
        e = e.trim()
      }

      let dontMerge = false

      const data = {}
      const saveData = null // in case data to be saved is different than the data that goes into the vuex store

      if (this.attr.slice(0, 4) === 'data') {
        const modelData = this.reQueryModelDataBeforeSave
          ? this.model.constructor.find(this.model.id).data
          : this.model.data
        data.data = cloneDeep(modelData)
      }

      if (
        this.lang &&
        this.type !== 'checkbox-option' &&
        this.type !== 'select' &&
        this.type !== 'checkbox' &&
        this.type !== 'radio' &&
        this.type !== 'dropdown' &&
        this.type !== 'switch' &&
        this.type !== 'autocomplete' &&
        this.type !== 'combobox'
      ) {
        const langObj = cloneDeep(this.model[this.attr]) || {}
        langObj[this.lang] = e
        e = langObj
      }

      if (
        this.type === 'checkbox' ||
        this.type === 'radio' ||
        this.type === 'dropdown' ||
        this.type === 'switch'
      ) {
        if (this.checkboxMode === 'multiple') {
          dontMerge = true

          let oldItemToCopyCustomInputTextFrom

          if (this.type === 'radio' || (this.type === 'dropdown' && !this.dropdownMultiple)) {
            let oldItem
            if (this.oldItemValues) {
              oldItem = this.model[this.attr].items.find((i) => i.value === this.oldItemValues[0])
            } else if (this.value) {
              oldItem = this.value.items[0]
            }

            if (oldItem && oldItem.custom_input_text) {
              oldItemToCopyCustomInputTextFrom = oldItem
            }
          }

          if (typeof e === 'object') {
            // assume e = { value: i, customInputText: 'string' }
            // or e = { additionalItemText: 'string' }
            // add or remove custom_text item to itemValues
            if (e && e.customInputText && e.customInputText.length) {
              if (this.itemValues.indexOf(e.value) === -1) {
                this.skipNextUpdate = true

                if (
                  this.type === 'checkbox' ||
                  (this.type === 'dropdown' && this.dropdownMultiple)
                ) {
                  this.itemValues.push(e.value)
                } else if (
                  this.type === 'radio' ||
                  (this.type === 'dropdown' && !this.dropdownMultiple)
                ) {
                  this.itemValues = [e.value]
                }
              }
            } else if (
              e.customInputText === undefined ||
              (e.customInputText && !e.customInputText.length)
            ) {
              // in case of null, dont remove
              if (this.itemValues.indexOf(e.value) !== -1) {
                this.itemValues.splice(this.itemValues.indexOf(e.value), 1)
              }
            }
          }

          const items = this.items
            .filter((i) => this.itemValues.indexOf(i.value) !== -1)
            .map((item) => {
              const ret = { value: item.value }

              const reQueriedModel = this.model.constructor.find(this.model.id)
              const answerItem = (reQueriedModel[this.attr] || { items: [] }).items.find(
                (i) => i.value === item.value
              )

              let customInputText

              if (typeof e === 'object' && item.value === e.value) {
                ret.custom_input_text = {
                  ...(answerItem?.custom_input_text || {}),
                }
                const lang = this.lang || this.currentLocale
                ret.custom_input_text[lang] = e.customInputText
              } else if (answerItem && answerItem.custom_input_text) {
                ret.custom_input_text = answerItem.custom_input_text
              } else if (oldItemToCopyCustomInputTextFrom) {
                ret.custom_input_text = oldItemToCopyCustomInputTextFrom.custom_input_text
              }

              return ret
            })

          data.data = {}
          if (this.additionalItems.length) {
            this.additionalItems.forEach((ai) => {
              let item
              if (e && e.value === ai.value) {
                item = {
                  value: ai.value,
                  custom_input_text: {},
                  is_additional_item: true,
                }
                item.custom_input_text[this.lang || this.currentLocale] = e.additionalItemText
              } else {
                item = {
                  value: ai.value,
                  custom_input_text: ai.custom_input_text,
                  is_additional_item: true,
                }
              }

              item.selected = this.itemValues.indexOf(ai.value) !== -1

              items.push(item)
            })
          }

          // Add additional item
          if (e && e.additionalItemText && e.additionalItemText.length && e.value === undefined) {
            const minValueItem = minBy(this.model.data.items, (i) => i.value)
            const minValue = minValueItem && minValueItem.value < 0 ? minValueItem.value : -1
            const item = {
              value: minValue - 1,
              custom_input_text: {},
              is_additional_item: true,
            }
            item.custom_input_text[this.lang || this.currentLocale] = e.additionalItemText
            items.push(item)
          }

          data.data.items = items
        } else if (this.checkboxMode === 'single') {
          this.setValueDeep(data, this.attr, this.checkboxValue)
        }

      } else if (this.type === 'select' && this.attr === 'data') {
        // TODO: still used?
        // dynamic questions
        // data.data = {}
        data.data[this.model.dynamic_question_type] = { section_id: e }

      } else if (this.type === 'select' && this.attr === 'data.company_data_import_type') {
        data.data = {
          company_data_import_type: e,
        }

      } else if (this.type === 'checkbox-option') {
        const lang = this.lang || 'en' // when lang is not set, always use default language

        // only set text if event is string, else it was called by custom_text checkbox
        if (typeof e === 'string') {
          this.newCheckboxItem.text_translations[lang] = e
        }

        // get all other items (not this item and not the always-created empty option)
        // let items = [...this.model.getSortedItems().filter(i => !itemEmpty(i) && i.value !== this.newCheckboxItem.value)]
        const items = []
        if (!this.itemEmpty(this.newCheckboxItem)) {
          if (!this.newCheckboxItem.position) {
            // init position
            this.newCheckboxItem.position =
              (max(this.model.data.items.filter((i) => !i.parent_value).map((i) => i.position)) ||
                0) + 1
          }

          delete this.newCheckboxItem.isNewItem

          items.push(cloneDeep(this.newCheckboxItem))
        }

        data.data.items = items

      } else if (this.type === 'combobox') {
        if (typeof this.itemValues === 'string' && !this.comboboxAllowNewCreation) {
          // search value was set, dont update
          return
        }

        let value
        if (this.singleSelectValue) {
          if (this.saveSingleSelectItemAsList) {
            // handle single select
            if (typeof this.itemValues === 'string') {
              value = { value: -1, text: this.itemValues, is_custom: true }
            } else if (typeof this.itemValues?.[0] === 'string') {
              value = { value: -1, text: this.itemValues?.[0], is_custom: true }
            } else {
              value = this.itemValues?.value
            }
          } else {
            if (typeof this.itemValues === 'string') {
              value = { value: -1, text: this.itemValues, is_custom: true }
            } else {
              value = this.itemValues?.value
            }
          }

          if (this.saveSingleSelectItemAsList) {
            if (value) {
              value = [value]
            } else {
              value = []
            }
          }
        } else {
          let negativeCounter = 0
          value = this.itemValues.map((iv) => {
            // aka new item as string
            if (!iv.value) {
              saveImmediately = true
              negativeCounter++
              return { value: -negativeCounter, text: iv, is_custom: true }
            }

            return iv.value
          })
        }

        this.setValueDeep(data, this.attr, value)

      } else if (this.type === 'date') {
        let value
        if (this.saveSingleDateAsString) {
          value = dateToISOString(e, { withTime: false })

        } else {
          value = {}

          if (!this.timespan) {
            if (e.date) {
              value.date = dateToISOString(e.date, { withTime: false })
            }

            if (this.isDateTime) {
              value.time = format(e.date, 'HH:mm')
            }

          } else {
            if (e.date1) {
              value.date1 = dateToISOString(e.date1, { withTime: false })
            }

            if (e.date2) {
              value.date2 = dateToISOString(e.date2, { withTime: false })
            }

            if (this.isDateTime && e.date1) {
              value.time1 = format(e.date1, 'HH:mm')
            }

            if (this.isDateTime && e.date2) {
              if (!data.data.time2) {
                value.time2 = '23:59'
              } else {
                value.time2 = format(e.date2, 'HH:mm')
              }
            }

          }

        }

        this.setValueDeep(data, this.attr, value)

      } else {
        let value
        if (typeof e === 'string' && e.length === 0) {
          value = null

        } else if (this.saveSingleSelectItemAsList) {
          if (e) {
            value = [e]
          } else {
            value = []
          }

        } else {
          value = e
        }

        this.setValueDeep(data, this.attr, value)
      }

      if (Object.keys(data).length === 0) {
        console.error('input update data empty')
      }

      // merge data.items with existing if type === 'checkbox-option'
      if (!dontMerge && data.data && data.data.items) {
        const reQueriedModel = this.model.constructor.find(this.model.id)
        // const thisModelItems = cloneDeep(this.model.getSortedItems())
        const existingItems = cloneDeep(reQueriedModel.getSortedItems())

        data.data.items.forEach((newItem) => {
          const existingItem = existingItems.find((i) => i.value === newItem.value)
          if (existingItem) {
            merge(existingItem, newItem)
          } else {
            existingItems.push(newItem)
          }
        })

        data.data.items = existingItems
      }

      // Save previous model
      this.previousData = this.isPlanitModel ? this.model.$toJson() : cloneDeep(this.model)

      let updatedModel
      if (this.isPlanitModel) {
        updatedModel = await this.model.updateAttr({ ...data })
      } else {
        updatedModel = Object.assign(this.model, data)
      }

      if (this.onChange) {
        // wait for re-query
        await this.onChange()
      }

      // after data has been synced to store, calc saveData for checkbox-option
      if (this.type === 'checkbox-option') {
        this.initNewCheckboxItem()
      }

      if (this.section && this.section.allow_adding_additional_options) {
        this.additionalItem.custom_input_text = {}
      }

      this.dialogUpdateData = saveData || data

      if (!this.localAttr) {
        if (this.triggerDialog) {
          this.showConfirmModal = true
          this.confirmText = this.$t('generic.are_you_sure')
        } else {
          await this.confirmUpdate(saveImmediately)
        }
      }
    }

    this.updateDebounced = debounce(this.updateNow, this.immediateSave ? 20 : 100, {
      leading: false,
    })
  },
  beforeDestroy() {
    store.commit('syncQueue/UNREGISTER_DEBOUNCE_FN', { debounceFn: this.updateDebounced })
  },
  methods: {
    update(e) {
      if (this.inputType === 'number') {
        e = parseFloat(e)
      }
      this.internalValue = e

      if (!this.debounceFunctionRegistered) {
        store.dispatch('syncQueue/registerDebounce', { debounceFn: this.updateDebounced })
        this.debounceFunctionRegistered = true
      }

      store.dispatch('syncQueue/cancel')
      if (e && (e.saveImmediately || this.immediateSave)) {
        this.updateDebounced.cancel()
        this.updateNow(e)
      } else {
        this.updateDebounced(e)
      }
    },
    validateAsync(data) {
      // patch validateonly
      return this.model
        .postOrPatch(data, { params: { validate: true }, save: false })
        .then((r) => {
          this.validationError = ''
          this.$emit('hasValidationError', false)
          // autosave
          if (!this.dontAutosave) {
            return this.save(data)
          }
        })
        .catch((error) => {
          if (error?.response?.data?.[this.attr]?.length) {
            const errorsThisAttr = error.response.data[this.attr]
            if (Array.isArray(errorsThisAttr)) {
              this.validationError = errorsThisAttr[0]

            } else {
              this.validationError = errorsThisAttr

            }

          } else if (error?.response?.data?.message) {
            emitter.emit('showGlobalAlert', {
              type: 'error',
              message: error.response.data.message,
            })
          } else {
            console.log('validateAsync unexpected error', error)
          }
          this.$emit('hasValidationError', true)
        })
    },
    async deleteOption() {
      const data = {}

      data.data = {
        items: this.model
          .getSortedItems()
          .filter(
            (i) =>
              i.value !== this.checkboxItem.value && Object.keys(i.text_translations).length !== 0
          ),
      }

      this.model.constructor
        .update({
          where: this.model.id,
          data,
        })
        .then((result) => {
          if (this.onChange) {
            this.onChange()
          }

          const saveData = {
            data: {
              items: result
                .getSortedItems()
                .filter((i) => (Object.keys(i.text_translations) !== 0) & !this.itemEmpty(i)),
            },
          }

          if (!this.dontAutosave) {
            return this.save(saveData, { immediate: true })
          }
        })
    },
    initNewCheckboxItem() {
      this.newCheckboxItem = {
        text_translations: {},
        value: this.checkboxItem.value,
        text_translations: cloneDeep(this.checkboxItem.text_translations),
        custom_text: this.checkboxItem.custom_text,
        is_na_item: this.checkboxItem.is_na_item,
        position: this.checkboxItem.position,
        isNewItem: true,
      }
    },
    initAdditionalItem() {
      this.additionalItem = { custom_input_text: {}, is_additional_item: true }
    },
    onBlur(event) {
      if (this.saveOnBlur) {
        store.dispatch('syncQueue/handleQueueNow')
      } else {
        store.dispatch('syncQueue/unpause')
      }

      this.$emit('blur')
    },
    onFocus(event) {
      store.dispatch('syncQueue/pause')

      this.$emit('focus')
    },
    itemEmpty(i) {
      let isEmpty = true
      Object.keys(i.text_translations).forEach((k) => {
        if (i.text_translations[k].length > 0) {
          isEmpty = false
        }
      })
      return isEmpty
    },
    getValueDeep(data, attr) {
      if (attr.indexOf('.') !== -1) {
        const attrSplit = attr.split('.')
        let ret = data

        if (!ret) {
          return ret
        }

        attrSplit.forEach((a) => {
          if (attrSplit.indexOf(a) === attrSplit.length - 1) {
            ret = ret[a]
          } else {
            if (!ret[a]) {
              ret = {}
            } else {
              ret = ret[a]
            }
          }
        })

        return ret
      } else {
        return data[attr]
      }
    },
    setValueDeep(data, attr, value) {
      if (attr.indexOf('.') !== -1) {
        const attrSplit = attr.split('.')
        let ret = data
        attrSplit.forEach((a) => {
          if (attrSplit.indexOf(a) === attrSplit.length - 1) {
            ret[a] = value
          } else {
            if (!ret[a]) {
              ret[a] = {}
            }
            ret = ret[a]
          }
        })
      } else {
        data[attr] = value
      }
    },
    async deleteAdditionalItem(aItem) {
      const itemsCopy = [
        ...this.model.data.items.filter((i) => i.value !== aItem.value),
      ]

      const updatedModel = await this.model.updateAttr({
        data: {
          ...this.model.data,
          items: itemsCopy,
        },
      })

      if (this.onChange) {
        this.onChange()
      }

      if (!this.dontAutosave) {
        return this.save({ data: updatedModel.data }, { immediate: true })
      }
    },
    getDateMax() {
      if (!this.value.date2) {
        return new Date(8640000000000000)
      }

      let date2 = new Date(this.value.date2)

      if (!this.isDateTime) {
        date2.setMinutes(59)
        date2.setHours(23)
      }

      return date2

    },
    getDateMin() {
      if (!this.value.date1) {
        return new Date(0)
      }

      let date1 = new Date(this.value.date1)

      return date1
    },
    setItemTree() {
      this.itemTree = this.section.getItemTree(this.itemsWithLangMappedText)
    },
    async setInitialItemTreeOpen() {
      if (this.itemTreeOpen?.length) {
        return
      }

      const itemValuesOpen = []

      this.sectionComputed?.data.items.forEach((sectionItem) => {
        if (
          sectionItem.parent_value !== null &&
          this.itemValues.indexOf(sectionItem.value) !== -1 &&
          itemValuesOpen.indexOf(sectionItem.parent_value) === -1
        ) {
          itemValuesOpen.push(sectionItem.parent_value)
        }
      })

      // TODO: move to better spot for initialization
      if (itemValuesOpen.length) {
        this.itemTreeOpen = itemValuesOpen
      }
    },
    async onConfirmModalClose(confirm) {
      if (confirm) {
        await this.confirmUpdate()
      } else {
        await this.model.updateAttr({ ...this.previousData })
      }
      this.showConfirmModal = false
    },
    async confirmUpdate(saveImmediately) {
      if (this.isPlanitModel) {
        const options = {}
        if (saveImmediately) {
          options.immediate = true
        }

        if (this.dontValidate || this.immediateSave || saveImmediately) {
          return this.save(this.dialogUpdateData, options)
        } else if (!this.dontAutosave) {
          await this.validateAsync(this.dialogUpdateData)
        }
      }
    },
    setComboboxItemValues() {
      let value = this.getValueDeep(this.model, this.attr)

      if (!value || (Array.isArray(value) && value.length === 0)) {
        value = []
      } else if (this.saveSingleSelectItemAsList && !Array.isArray(value)) {
        value = [value]
      }

      if (this.singleSelectValue && !Array.isArray(value)) {
        return value
      }

      this.itemValues = value.map((i) =>
        this.selectItems.find((si) => {
          return si.value === (i[this.itemValue] || i)
        })
      )
    },
    getItemTranslationSuggestion(item) {
      const suggestion = this.model.data.translation_suggestion?.[this.lang]?.items?.find(
        (i) => i.value === item.value
      )?.text
      if (suggestion) {
        return '<i>' + suggestion + '</i>'
      }
    },
    getTranslation(key) {
      return this.document ? this.document.getTranslation(key) : this.$t(key)
    },
    getObjectTranslation(obj) {
      return this.document
        ? this.document.getObjectTranslation(obj)
        : obj[this.lang || this.currentLocale]
    },
    handleUpdateCombobox(value) {
      this.itemValuesComputed = value
      if (!!this.searchInput) {
        this.update(value)
      }
    },
    handleKeydown(event) {
      if (event.key === ' ') {
        event.stopPropagation()
      }
    },
  },
}
</script>

<template>
  <div v-if="!loading">
    <ConfirmModal
      v-if="showConfirmModal"
      :text="confirmText"
      @confirm="onConfirmModalClose"
      @cancel="onConfirmModalClose"
    ></ConfirmModal>
    <PlanitTextField
      v-if="type === 'text' && !markdown"
      :model-value="value"
      :error-messages="error"
      :label="label"
      :placeholder="placeholder"
      :required="required"
      :disabled="inputDisabled"
      :type="inputType"
      :min="min"
      :hide-details="!error"
      variant="outlined"
      :theme="dark ? 'dark' : light ? 'light' : ''"
      :density="density ? 'compact' : null"
      :style="!!width ? { width } : {}"
      @update:model-value="update"
      @blur="onBlur"
      @focus="onFocus"
    ></PlanitTextField>

    <div v-if="type === 'textarea' && !markdown">
      <AutoGrowTextarea
        :model-value="value"
        :error-messages="error"
        :label="label"
        :placeholder="placeholder"
        :required="required"
        :disabled="inputDisabled"
        :density="density"
        variant="outlined"
        color="primary"
        :theme="dark ? 'dark' : light ? 'light' : ''"
        :rows="rows"
        :hide-details="!error"
        @update:model-value="update"
        @blur="onBlur"
        @focus="onFocus"
      ></AutoGrowTextarea>
    </div>
    <div v-if="(type === 'text' || type == 'textarea') && markdown">
      <PlanitMarkdown
        :value="value"
        :label="label"
        :disabled="inputDisabled"
        :reduced-markdown="reducedMarkdown"
        :placeholder="placeholder"
        html
        :focus-on-creation="focusOnCreation"
        :sticky-toolbar="disableStickyToolbar ? -1 : 0"
        :extended-buttons="sunEditorExtendedButtons"
        @input="update"
        @blur="onBlur"
        @focus="onFocus"
      ></PlanitMarkdown>
    </div>

    <div v-if="(type === 'checkbox' || type === 'radio') && checkboxMode === 'multiple'">
      <div v-if="type === 'checkbox'">
        <v-treeview
          v-model="itemValuesComputed"
          :items="itemTree"
          v-model:opened="itemTreeOpen"
          :disabled="inputDisabled"
          item-value="value"
          selectable
          :select-strategy="
            inputDisabled ? 'leaf' : 'independent' /* independent doesnt use parentIsDisabled.... */
          "
          selected-color="primary"
          class="my-0 py-0 no-hover"
          density="compact"
          :item-children="(item) => (item.children.length ? item.children : null)"
        >
          <template #title="{ item }">
            <div
              @click.stop
              class="py-1 pl-4 d-flex align-center"
            >
              <label
                style="white-space: normal; hyphens: auto; min-width: 8rem"
                class="markdown-container mr-4"
                v-html="getObjectTranslation(item.text_translations)"
              ></label>

              <div
                v-if="item.custom_text"
                class="flex-grow-1"
              >
                <PlanitMarkdown
                  :value="
                    item.custom_input_text ? getObjectTranslation(item.custom_input_text) : ''
                  "
                  :disabled="inputDisabled"
                  :reduced-markdown="reducedMarkdown"
                  style="min-width: 50%"
                  class="flex-grow-1"
                  html
                  :focus-on-creation="focusOnCreation"
                  :sticky-toolbar="disableStickyToolbar ? -1 : 0"
                  :placeholder="getItemTranslationSuggestion(item)"
                  @input="update({ value: item.value, customInputText: $event })"
                  @blur="onBlur"
                  @focus="onFocus"
                  @keydown="handleKeydown"
                ></PlanitMarkdown>
              </div>

              <span v-if="debug">value: {{ item.value }}</span>
            </div>
          </template>
        </v-treeview>
      </div>

      <v-radio-group
        v-if="type === 'radio'"
        v-model="itemValuesComputed"
        class="d-flex flex-wrap mt-0 pt-0"
        hide-details
      >
        <div
          v-for="item in itemsWithLangMappedText"
          :key="item.value"
          class="d-flex align-center justify-space-between"
          :class="{ 'flex-grow-1': item.custom_text }"
          :style="item.custom_text ? { width: '100%' } : {}"
        >
          <div
            :style="item.custom_text ? { width: '45%' } : { width: '100%' }"
            :class="item.custom_text ? 'flex-shrink-0 pr-2' : ''"
          >
            <v-radio
              :error-messages="error"
              :value="item.value"
              :required="required"
              :disabled="inputDisabled"
              color="primary-lighten-1"
              style="min-width: 10rem"
              hide-details
              :class="!item.custom_text ? 'pb-2' : 'py-0'"
            >
              <template #label>
                <span
                  class="markdown-container"
                  v-html="item.text"
                ></span>
              </template>
            </v-radio>
          </div>
          <div
            v-if="item.custom_text"
            :style="item.custom_text ? { width: '55%' } : { width: '100%' }"
            :class="item.custom_text ? 'pl-2' : ''"
          >
            <PlanitMarkdown
              v-if="markdown"
              :value="item.custom_input_text[lang || currentLocale]"
              :disabled="inputDisabled"
              :reduced-markdown="reducedMarkdown"
              html
              :focus-on-creation="focusOnCreation"
              :sticky-toolbar="disableStickyToolbar ? -1 : 0"
              :placeholder="getItemTranslationSuggestion(item)"
              @input="update({ value: item.value, customInputText: $event })"
              @blur="onBlur"
              @focus="onFocus"
            ></PlanitMarkdown>

            <AutoGrowTextarea
              v-else
              :model-value="item.custom_input_text[lang || currentLocale]"
              :error-messages="error"
              :required="true"
              :disabled="inputDisabled"
              :hide-details="!error"
              :placeholder="getItemTranslationSuggestion(item)"
              variant="outlined"
              :theme="dark ? 'dark' : light ? 'light' : ''"
              :density="density"
              rows="1"
              @update:model-value="update({ value: item.value, customInputText: $event })"
              @blur="onBlur"
              @focus="onFocus"
            ></AutoGrowTextarea>
          </div>
        </div>
      </v-radio-group>
    </div>

    <div v-if="type === 'checkbox' && checkboxMode === 'single'">
      <PlanitCheckbox
        v-model="checkboxValue"
        :error-messages="error"
        :hide-details="!error"
        :label="label"
        :required="required"
        :disabled="inputDisabled"
        color="primary-lighten-1"
        @update:model-value="update"
      ></PlanitCheckbox>
    </div>

    <div v-if="type === 'switch' && checkboxMode === 'single'">
      <PlanitSwitch
        v-if="!icon"
        v-model="checkboxValue"
        :error-messages="error"
        :hide-details="!error"
        :label="label"
        :required="required"
        :disabled="inputDisabled"
        :density="density ? 'default' : null"
        :inset="inset"
        color="primary-lighten-1"
        @update:model-value="update"
      ></PlanitSwitch>

      <v-tooltip
        v-if="!!icon"
        location="top"
      >
        <template #activator="{ props }">
          <PlanitButton
            variant="text"
            class="icon-btn"
            :disabled="inputDisabled"
            v-bind="props"
            @click="
              () => {
                checkboxValue = !checkboxValue
                update($event)
              }
            "
          >
            <PlanitIcon
              size="small"
              :color="!!checkboxValue ? 'primary' : 'grey-lighten-1'"
              :icon="icon"
            ></PlanitIcon>
          </PlanitButton>
        </template>
        <span>{{ label }}</span>
      </v-tooltip>
    </div>

    <div v-if="type === 'checkbox-option'">
      <div class="d-flex align-center">
        <div v-if="!hideCheckboxOptions" class="flex-shrink-0">
          <div v-if="checkboxType === 'checkbox'">
            <PlanitCheckbox
              :disabled="true"
              color="primary-lighten-1"
              hide-details
            ></PlanitCheckbox>
          </div>

          <div v-if="checkboxType === 'radio' || checkboxType === 'dropdown'">
            <v-radio
              :disabled="true"
              color="primary-lighten-1"
              hide-details
            ></v-radio>
          </div>
        </div>

        <PlanitTextField
          v-if="!markdown"
          :model-value="checkboxItemValue"
          :error-messages="error"
          :placeholder="placeholder || $t('components.input.new_option')"
          :required="true"
          :disabled="inputDisabled"
          :hide-details="!error"
          variant="outlined"
          :theme="dark ? 'dark' : light ? 'light' : ''"
          :density="density ? 'compact' : null"
          @update:model-value="update"
          @blur="onBlur"
          @focus="onFocus"
        ></PlanitTextField>

        <PlanitMarkdown
          v-else
          :value="checkboxItemValue"
          :placeholder="placeholder || $t('components.input.new_option')"
          :disabled="inputDisabled"
          :reduced-markdown="reducedMarkdown"
          html
          class="flex-grow-1"
          :focus-on-creation="focusOnCreation"
          :sticky-toolbar="disableStickyToolbar ? -1 : 0"
          :toolbar-bottom="toolbarBottom"
          @input="update"
          @blur="onBlur"
          @focus="onFocus"
        ></PlanitMarkdown>

        <!-- separete v-if divs are intentional so that flexbox handles them as separate block elements -->
        <div v-if="!hideCheckboxOptions" class="flex-shrink-0">
          <div class="px-2">
            <PlanitCheckbox
              v-model="newCheckboxItem.custom_text"
              color="primary-lighten-1"
              :disabled="!checkboxItemValue"
              :label="$t('components.input.custom_text')"
              hide-details
              class="pt-0"
              tabindex="-1"
              @mouseup="update"
            ></PlanitCheckbox>
          </div>
        </div>

        <div v-if="!hideCheckboxOptions && checkboxType == 'radio'">
          <div class="px-2">
            <v-tooltip location="top">
              <template #activator="{ props }">
                <span v-bind="props">
                  <PlanitCheckbox
                    v-model="newCheckboxItem.is_na_item"
                    color="primary-lighten-1"
                    :disabled="!checkboxItem.value"
                    :label="$t('components.input.is_na_item')"
                    hide-details
                    class="pt-0"
                    tabindex="-1"
                    @mouseup="update"
                  ></PlanitCheckbox>
                </span>
              </template>

              <span>{{ $t('components.input.is_na_item_info_text') }}</span>
            </v-tooltip>
          </div>
        </div>

        <div
          v-if="!hideCheckboxOptions"
          :style="!checkboxItemValue ? { visibility: 'hidden' } : {}"
        >
          <div class="px-2">
            <IconButton
              icon="fa-trash-alt"
              tabindex="-1"
              @click="deleteOption"
            ></IconButton>
          </div>
        </div>
      </div>
    </div>

    <PlanitSelect
      v-if="type === 'select'"
      :model-value="value"
      :error-messages="error"
      :label="label"
      :placeholder="placeholder"
      :required="required"
      :disabled="inputDisabled"
      :items="selectItemsWithLangMappedText"
      :item-value="itemValue"
      :item-title="itemText"
      variant="outlined"
      :bg-color="color || null"
      :theme="dark ? 'dark' : light ? 'light' : ''"
      :hide-details="!error"
      :density="highlight || density ? 'compact' : null"
      :style="!!width ? { width } : {}"
      @blur="onBlur"
      @update:model-value="update"
    >
      <template #selection="{ item }">
        <span
          class="html-container"
          v-html="item.raw[itemText]"
        ></span>
      </template>
      <template #item="{ item, props }">
        <v-list-item
          v-bind="props"
          class="html-container"
          v-html="item.raw[itemText]"
        ></v-list-item>
      </template>
    </PlanitSelect>

    <PlanitAutocomplete
      v-if="type === 'dropdown'"
      v-model="itemValuesComputed"
      :error-messages="error"
      :label="label"
      :items="itemsWithLangMappedText"
      :placeholder="placeholder"
      :required="required"
      :disabled="inputDisabled"
      :item-value="itemValue"
      :item-title="itemText"
      variant="outlined"
      :theme="dark ? 'dark' : light ? 'light' : ''"
      :hide-details="!error"
      :multiple="dropdownMultiple"
      :chips="dropdownMultiple"
      :density="!itemValuesComputed || !itemValuesComputed.length ? 'compact' : 'comfortable'"
      item-color="primary"
      @update:model-value="update"
      @blur="onBlur"
      @focus="onFocus"
    >
      <template v-if="!dropdownMultiple" #selection="{ item, index }">
        <span
          class="html-container"
          v-html="item.raw[itemText]"
        ></span>
      </template>
      <template v-else #chip="{ item }">
        <PlanitChip
          color="primaryDesaturated"
          class="html-container text-white"
          variant="flat"
        >
        <span
          v-html="item.raw[itemText]"
          :class="item.raw[itemText].length > 40 ? 'ma-2 pa-1' : ''"
          >
        </span>
      </PlanitChip>
      </template>
      <template #item="{ item, props }">
        <v-list-item
          v-bind="props"
          density="default"
        >
          <template #title>
            <span
              class="html-container"
              v-html="item.raw[itemText]"
            ></span>
          </template>
        </v-list-item>
      </template>
    </PlanitAutocomplete>

    <div v-if="type === 'dropdown' && selectedItemsWithLangMappedTextAndCustomText.length">
      <div
        v-for="item in selectedItemsWithLangMappedTextAndCustomText"
        :key="item.value"
        class="d-flex align-center my-2"
      >
        <label class="mr-4 ml-2"
          ><div
            class="markdown-container"
            v-html="item.text"
          ></div
        ></label>
        <PlanitMarkdown
          :value="item.custom_input_text[lang || currentLocale]"
          :disabled="inputDisabled"
          :reduced-markdown="reducedMarkdown"
          html
          :focus-on-creation="focusOnCreation"
          :sticky-toolbar="disableStickyToolbar ? -1 : 0"
          :placeholder="getItemTranslationSuggestion(item)"
          class="flex-grow-1"
          @input="update({ value: item.value, customInputText: $event })"
          @blur="onBlur"
          @focus="onFocus"
        ></PlanitMarkdown>
      </div>
    </div>

    <PlanitAutocomplete
      v-if="type === 'autocomplete'"
      :model-value="value"
      :error-messages="error"
      :label="label"
      :placeholder="placeholder"
      :required="required"
      :disabled="inputDisabled"
      :items="selectItemsWithLangMappedText"
      :item-value="itemValue"
      :item-title="itemText"
      :multiple="dropdownMultiple"
      :chips="dropdownMultiple"
      variant="outlined"
      :bg-color="color || null"
      :theme="dark ? 'dark' : light ? 'light' : ''"
      :hide-details="!error"
      :density="density || (!value.length ? 'default' : 'compact')"
      :clearable="clearable"
      :closable-chips="deletableChips"
      :class="dropdownListView ? 'dropdown-list-view' : ''"
      @update:model-value="update"
      @update:search="(v) => searchInputComputed = v"
      @blur="onBlur"
      @focus="onFocus"
    >
      <template v-if="!dropdownMultiple" #selection="{ item, index }">
        <div class="text-overflow-ellipsis">
          <span
            class="markdown-container text-overflow-ellipsis"
            v-html="item.raw[itemText]"
          ></span>
        </div>
      </template>
      <template v-else #chip="{ item, index }">
        <PlanitChip
          color="primaryDesaturated"
          label
          class="markdown-container flex-shrink-0"
          v-html="item.raw[itemText]"
          variant="flat"
        ></PlanitChip>
        <div
          v-if="dropdownMultiple && dropdownListView"
          style="flex-basis: 100%; height: 0"
        ></div>
      </template>
      <template #item="{ item, props }">
        <v-list-item
          v-bind="props"
        >
          <template #title>
            <span
              class="html-container"
              v-html="item.raw[itemText]"
            ></span>
          </template>
        </v-list-item>
      </template>
    </PlanitAutocomplete>

    <v-combobox
      v-if="type === 'combobox'"
      v-model="itemValuesComputed"
      :items="selectItemsWithLangMappedText"
      :item-value="itemValue"
      :item-title="itemText"
      :label="label"
      :placeholder="placeholder"
      :required="required"
      :disabled="inputDisabled"
      :multiple="dropdownMultiple"
      :error-messages="error"
      variant="outlined"
      :theme="dark ? 'dark' : light ? 'light' : ''"
      :chips="!singleSelectValue || dropdownMultiple"
      :closable-chips="deletableChips"
      :clearable="clearable"
      :hide-details="!error"
      :density="!itemValuesComputed?.length ? 'compact' : null"
      :bg-color="color || null"
      @update:search="(v) => searchInputComputed = v"
      return-object
      :class="dropdownListView ? 'dropdown-list-view' : ''"
      clear-icon="fal fa-times-circle"
      persistent-placeholder
      @update:model-value="handleUpdateCombobox"
      @blur="onBlur"
      @focus="onFocus"
    >
      <template
        v-if="!singleSelectValue || dropdownMultiple"
        #chip="{ item, index }"
      >
        <div
          v-if="item?.length === undefined || item.length > 0"
          class="text-overflow-ellipsis"
        >
          <PlanitChip
            :color="
              item?.raw?.is_custom
                ? !disabled
                  ? backgroundLevel < 1
                    ? 'primary'
                    : 'multi-answer-background-full-' + backgroundLevel
                  : backgroundLevel
                    ? 'grey-darken-1'
                    : 'multi-answer-background-' + backgroundLevel
                : !disabled
                  ? 'primaryDesaturated'
                  : 'primaryDesaturatedDisabled'
            "
            variant="flat"
            label
            :class="'markdown-container text-overflow-ellipsis'"
          >
            <template #default>
              <div
                class="text-white"
                v-html="
                  item?.raw?.[itemText] ||
                  (item?.raw?.[itemValue] &&
                    selectItemsWithLangMappedText.find(
                      (si) => si && si[itemValue] && si[itemValue] === item?.raw?.[itemValue]
                    )?.[itemText]) ||
                  item?.raw
                "
              ></div>
            </template>
          </PlanitChip>
        </div>
      </template>
      <!-- eslint-disable-next-line vuetify/no-deprecated-slots -->
      <template v-else #selection="{ item }">
        <component
          :is="item?.raw?.is_custom ? 'PlanitChip' : 'span'"
          label
          :color="
            item?.raw?.is_custom
              ? backgroundLevel < 1
                ? 'primary'
                : 'multi-answer-background-full-' + backgroundLevel
              : ''
          "
          :class="'markdown-container text-overflow-ellipsis'"
          variant="flat"
        >
          <template #default>
            <div v-html="item?.raw?.[itemText] ||
              (item?.raw?.[itemValue] &&
                selectItemsWithLangMappedText.find(
                  (si) => si && si[itemValue] && si[itemValue] === item?.raw?.[itemValue]
                )?.[itemText]) ||
              item?.raw
            "></div>
          </template>
        </component>
      </template>
      <template #item="{ item, props }">
        <v-list-item v-bind="props" :disabled="item.raw.disabled">
          <template v-if="item.raw.icon" #prepend>
            <PlanitIcon
              size="small"
              color="primary"
              :icon="item.raw.icon"
            />
          </template>
        </v-list-item>
      </template>
      <template
        v-if="comboboxAllowNewCreation"
        #no-data
      >
        <v-list-item>
          <span class="text-grey text-darken-2 mr-1">{{ $t('components.input.new_entry') }}:</span>
          <PlanitChip
            color="primaryDesaturated"
            variant="flat"
            label
          >
            {{ comboboxSearch }}
          </PlanitChip>
          <PlanitButton
            variant="text"
            class="icon-btn ml-4"
            size="small"
            @click="
              () => {
                itemValuesComputed.push(searchInputComputed)
                searchInputComputed = ''
                update(searchInputComputed)
              }
            "
          >
            <span class="text-grey text-darken-2">
              <PlanitIcon
                size="x-small"
                class="mr-1"
                icon="fas fa-level-down-alt"
              ></PlanitIcon>

              {{ $t('components.input.new_entry') }}
            </span>
          </PlanitButton>
        </v-list-item>
      </template>
    </v-combobox>

    <div
      v-if="
        section &&
        section.allow_adding_additional_options &&
        (((type === 'checkbox' || type === 'radio') && checkboxMode === 'multiple') ||
          type === 'dropdown')
      "
      class="mt-4"
    >
      <!-- Additional options -->
      <div>
        <div
          v-for="aItem in additionalItems"
          :key="aItem.value"
          class="d-flex align-center justify-space-between mb-4"
        >
          <PlanitMarkdown
            v-if="markdown"
            :value="aItem.custom_input_text[lang || currentLocale]"
            :disabled="inputDisabled"
            :reduced-markdown="reducedMarkdown"
            class="flex-grow-1"
            html
            :focus-on-creation="focusOnCreation"
            :sticky-toolbar="disableStickyToolbar ? -1 : 0"
            :placeholder="getItemTranslationSuggestion(aItem)"
            @input="update({ additionalItemText: $event, value: aItem.value })"
            @blur="onBlur"
            @focus="onFocus"
          ></PlanitMarkdown>

          <AutoGrowTextarea
            v-else
            :model-value="aItem.custom_input_text[lang || currentLocale]"
            :disabled="inputDisabled"
            :hide-details="!error"
            variant="outlined"
            :theme="dark ? 'dark' : light ? 'light' : ''"
            :placeholder="getItemTranslationSuggestion(aItem)"
            :density="density"
            rows="1"
            class="flex-grow-1"
            @update:model-value="update({ additionalItemText: $event, value: aItem.value })"
            @blur="onBlur"
            @focus="onFocus"
          ></AutoGrowTextarea>

          <PlanitButton
            variant="text"
            class="icon-btn ml-2"
            @click="deleteAdditionalItem(aItem)"
          >
            <PlanitIcon
              size="small"
              icon="fal fa-trash-alt"
            ></PlanitIcon>
          </PlanitButton>
        </div>

        <div
          v-if="!disabled"
          class="d-flex align-center justify-space-between"
        >
          <v-form
            class="flex-grow-1 d-flex align-center justify-space-between"
            @submit.prevent="
              update({
                additionalItemText: additionalItem.custom_input_text[lang || currentLocale],
                saveImmediately: true,
              })
            "
          >
            <PlanitMarkdown
              v-if="markdown"
              :value="additionalItem.custom_input_text[lang || currentLocale]"
              :disabled="inputDisabled"
              :placeholder="$t('components.input.additional_option')"
              :reduced-markdown="reducedMarkdown"
              html
              :focus-on-creation="focusOnCreation"
              :sticky-toolbar="disableStickyToolbar ? -1 : 0"
              class="flex-grow-1 mr-4"
              @blur="onBlur"
              @focus="onFocus"
              @input="(v) => additionalItem.custom_input_text[lang || currentLocale] = v"
            ></PlanitMarkdown>

            <AutoGrowTextarea
              v-else
              v-model="additionalItem.custom_input_text[lang || currentLocale]"
              :disabled="inputDisabled"
              :hide-details="!error"
              :placeholder="$t('components.input.additional_option')"
              variant="outlined"
              :theme="dark ? 'dark' : light ? 'light' : ''"
              html
              :density="density"
              rows="1"
              class="flex-grow-1 mr-4"
              @blur="onBlur"
              @focus="onFocus"
            ></AutoGrowTextarea>

            <PlanitButton
              :disabled="!additionalItem.custom_input_text[lang || currentLocale]"
              variant="text"
              color="primary"
              type="submit"
            >
              <PlanitIcon
                size="small"
                class="mr-1"
                icon="fal fa-plus"
              ></PlanitIcon>

              <span>{{ $t('components.input.add') }}</span>
            </PlanitButton>
          </v-form>
        </div>
      </div>
    </div>

    <div v-if="type === 'date' || type === 'time'">
      <div v-if="!timespan">
        <div class="d-flex">
          <div class="flex-grow-1">
            <DateTimePicker
              type="date"
              :value="value.date"
              :label="label || $t('components.input.date')"
              :placeholder="placeholder"
              :required="required"
              :disabled="disabled"
              :lang="lang"
              :theme="dark ? 'dark' : light ? 'light' : ''"
              :error="error"
              :density="density"
              @update:value="
                (v) =>
                  update(
                    !isDateTime && saveSingleDateAsString
                      ? v
                      : { date: v }
                  )
              "
            ></DateTimePicker>
          </div>
          <div
            v-if="isDateTime"
            class="mx-2 flex-grow-1"
          >
            <DateTimePicker
              type="time"
              :value="value.date"
              :label="$t('components.input.time')"
              :placeholder="placeholder"
              :required="required"
              :disabled="disabled"
              :lang="lang"
              :theme="dark ? 'dark' : light ? 'light' : ''"
              :error="error"
              :density="density"
              @update:value="(v) => update({ date: v })"
            ></DateTimePicker>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="pb-2">
          <div class="d-flex align-center">
            <div class="flex-grow-1">
              <DateTimePicker
                type="date"
                :value="value.date1"
                :label="$t('components.input.start_date')"
                :placeholder="placeholder"
                :required="required"
                :disabled="disabled"
                :lang="lang"
                :min="startDateMin"
                :max="dateMax"
                :theme="dark ? 'dark' : light ? 'light' : ''"
                :error="error"
                @update:value="
                  (v) =>
                    update({
                      date1: v,
                      date2: value.date2,
                    })
                "
              ></DateTimePicker>
            </div>

            <div
              v-if="isDateTime"
              class="ml-2 flex-grow-1"
            >
              <DateTimePicker
                type="time"
                :value="value.date1"
                :label="$t('components.input.time')"
                :placeholder="placeholder"
                :required="required"
                :disabled="disabled"
                :lang="lang"
                :min="startTimeMin"
                :max="timeMax"
                :theme="dark ? 'dark' : light ? 'light' : ''"
                :error="error"
                @update:value="
                  (v) =>
                    update({
                      date1: v,
                      date2: value.date2,
                    })
                "
              ></DateTimePicker>
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex align-center">
            <div class="flex-grow-1">
              <DateTimePicker
                type="date"
                :value="value.date2"
                :label="$t('components.input.end_date')"
                :placeholder="placeholder"
                :required="required"
                :disabled="inputDisabled || !value.date1"
                :lang="lang"
                :min="dateMin"
                :theme="dark ? 'dark' : light ? 'light' : ''"
                :error="error"
                @update:value="
                  (v) =>
                    update({
                      date1: value.date1,
                      date2: v,
                    })
                "
              ></DateTimePicker>
            </div>

            <div
              v-if="isDateTime"
              class="ml-2 flex-grow-1"
            >
              <DateTimePicker
                type="time"
                :value="value.date2"
                :label="$t('components.input.time')"
                :placeholder="placeholder"
                :required="required"
                :disabled="inputDisabled || !value.date1"
                :lang="lang"
                :min="timeMin"
                :theme="dark ? 'dark' : light ? 'light' : ''"
                :error="error"
                @update:value="
                  (v) =>
                    update({
                      date1: value.date1,
                      date2: v,
                    })
                "
              ></DateTimePicker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// .combobox-single-select-custom-item {
//   background-color: #f5f5f5;
//   color: #333;
//   border-radius: 4px;
//   padding: 0 4px;
//   margin-right: 4px;
//   font-size: 0.8rem;
//   line-height: 1.5rem;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }
:deep(.v-combobox__selection){
  margin:8px 0px 4px 0px;
  background-color:#333;
  color: #f5f5f5;
  border-radius: 4px;
}
:deep(.v-radio-group .v-input__control) {
  flex-grow: 1;
}
</style>
