import PlanitModel from './planit-model'
import Company from './company'


export default class CompanyLink extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      href: this.attr(),
      company_link_type: this.attr(),

      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),

    }
  }

  postUrl() {
    return Company.$url() + '/' + this.company_id + '/' + this.constructor.entity
  }

  getPostData() {
    return {
      href: this.href,
      company_link_type: this.company_link_type,
    }
  }

}

CompanyLink.entity = 'company-links'
