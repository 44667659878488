<script>
import Rollout from '@/src/state/models/rollout'
import PlanitInput from '@components/form/input'
import { debounce } from 'lodash'
import { RolloutState, RolloutMailType } from '@enums'

export default {
  name: 'RolloutMail',
  components: {
    PlanitInput,
  },
  props: {
    rollout: { type: Object, required: true },
  },
  data() {
    return {
      emailSuccess: false,
      filesAsAttachment: false,
      testEmail: '',
      emailPreviewLoading: false,
      RolloutState,
      RolloutMailType,
    }
  },
  computed:  {
    isValidEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.testEmail);
    },
  },
  methods: {
    sendTestMail() {
      const button = document.querySelector('.hover-disabled')
      button.classList.add('disable-hover')

      if (!this.emailSuccess) {
        this.rollout.sendMail(RolloutMailType.TEST, this.testEmail)
      }
      this.emailSuccess = true

      setTimeout(() => {
        this.emailSuccess = false
      }, 4000)
    },
    reloadPreview: debounce(async function () {
      await Rollout.$find(this.rollout.id)
      this.$refs.myIframe.src = `/api/rollouts/${this.rollout.id}/preview-email`
    }, 500),

  },
}
</script>

<template>
  <div>
    <v-row>
      <v-col cols="6">
        <div class="">
          {{ $t('rollouts.files_as_attachment_info') }}
          <PlanitInput
            :model="rollout"
            attr="files_as_attachments"
            :disabled="rollout.state === RolloutState.COMPLETED"
            type="checkbox"
            class="mb-4 mr-2"
            :label="$t('rollouts.files_as_attachment')"
          ></PlanitInput>
          <PlanitInput
            :model="rollout"
            attr="with_custom_email"
            :disabled="rollout.state === RolloutState.COMPLETED"
            :label="$t('rollouts.with_custom_email')"
            class="mb-4"
            type="checkbox"
            highlight
            hide-details
            :on-change="reloadPreview"
          >
          </PlanitInput>
          <PlanitInput
            :model="rollout"
            :disabled="!rollout.with_custom_email || rollout.state === RolloutState.COMPLETED"
            attr="email_subject"
            :label="$t('rollouts.subject')"
            class="mb-4"
            highlight
            hide-details
          >
          </PlanitInput>
          <PlanitInput
            :model="rollout"
            :disabled="!rollout.with_custom_email || rollout.state === RolloutState.COMPLETED"
            attr="email_sender_name"
            :label="$t('rollouts.sender_title')"
            class="mb-4"
            highlight
            hide-details
            :on-change="reloadPreview"
          >
          </PlanitInput>
          <PlanitInput
            :model="rollout"
            :disabled="!rollout.with_custom_email || rollout.state === RolloutState.COMPLETED"
            attr="email_message"
            :label="$t('rollouts.body')"
            class="mb-4"
            type="textarea"
            :rows="2"
            variant="outlined"
            density="compact"
            markdown
            highlight
            hide-details
            :on-change="reloadPreview"
          ></PlanitInput>
        </div>
      </v-col>
      <v-col cols="6">
        <h3 class="mb-2">
          {{ $t('generic.preview') }}
        </h3>
        <iframe
          id="custom-email-preview-iframe"
          ref="myIframe"
          :src="`/api/rollouts/${rollout.id}/preview-email`"
          width="100%"
          style="min-height: 30rem; border: 1px solid rgba(0, 0, 0, 0.12)"
        ></iframe>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <PlanitTextField
          v-model="testEmail"
          :disabled="rollout.state === RolloutState.COMPLETED"
          variant="outlined"
          density="compact"
          :label="$t('rollouts.recipient')"
          type="email"
          required
        ></PlanitTextField>
      </v-col>
      <v-col cols="6">
        <PlanitButton
          :disabled="!isValidEmail || rollout.state === RolloutState.COMPLETED"
          class="hover-disabled"
          :color="emailSuccess ? 'success' : 'primary'"
          @click="sendTestMail"
        >
          <PlanitIcon
            v-if="emailSuccess"
            class="mr-1"
            >fal fa-check</PlanitIcon
          >
          {{ emailSuccess ? $t('rollouts.mail_success') : $t('rollouts.send_testmail') }}
        </PlanitButton>
      </v-col>
    </v-row>
  </div>
</template>
