<script>
export default {
  props: {
    modelValue: {
      type: [Object, Array, String, Number, Boolean],
      default: undefined,
    },
    label: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    density: {
      type: String,
      default: 'default',
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    trueValue: {
      type: [Boolean, String, Number],
      default: undefined,
    },
    falseValue: {
      type: [Boolean, String, Number],
      default: undefined,
    },
    value: {
      type: [Object, Array, String, Number, Boolean],
      default: undefined,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      internalModelValue: this.modelValue,
    }
  },
  watch: {
    modelValue: {
      handler(value) {
        this.internalModelValue = value
      },
      immediate: true,
    },
  },
  methods: {
    updateModelValue(value) {
      this.internalModelValue = value
      this.$emit('update:modelValue', value)
    },
  },
}
</script>

<template>
  <v-checkbox
    :model-value="internalModelValue"
    :value="value"
    :label="label"
    :color="color"
    :density="density"
    :hide-details="hideDetails"
    :true-value="trueValue"
    :false-value="falseValue"
    v-bind="$attrs"
    @update:modelValue="updateModelValue"
  >
    <template
      v-for="(slot, name) in $slots"
      v-slot:[name]="slotProps"
    >
      <slot
        :name="name"
        v-bind="slotProps"
      ></slot>
    </template>
  </v-checkbox>
</template>
