import { createRouter, createWebHistory } from 'vue-router'

// https://github.com/declandewet/vue-meta
// import VueMeta from 'vue-meta'
// Adds a loading bar at the top during page loads.
// import NProgress from 'nprogress/nprogress'

import store from '@state/store'
import Company from '@state/models/company'
import CompanyRole from '@state/models/company-role'
import { Document } from '@state/models/document'
import PermissionRole from '@state/models/permission-role'
import PermissionRoleUserAssociation from '@state/models/permission-role-user-association'

import { emitter } from '@utils/global-events'

import routes from './routes'

import dispatchActionForAllModules from '@utils/dispatch-action-for-all-modules'

//app.use(VueRouter)
/* Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
}) */

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.afterEach((routeTo, routeFrom) => {
  store.commit('navbar/SET_CURRENT_ROUTE', routeTo)
  if (!store.getters['navbar/skipSetNavTreeOpenInAfterEach']) {
    store.dispatch('navbar/setNavTreeOpen')
  } else {
    store.commit('navbar/SET_SKIP_SET_NAVTREE_OPEN_IN_AFTER_EACH', false)
  }

  store.dispatch('navbar/setNavTreeActive')

  // Initial check is done from navbar created, after setSelectedCompany
  if (routeFrom.name || store.getters['global/initDone']) {
    store.dispatch('navbar/checkSelectedApp')
  }

  // reset fullwidth override or set to true if set in route meta
  if (routeTo.matched.some((route) => route.meta?.enableFullWidth)) {
    store.commit('global/SET_LAYOUT_FULL_WIDTH', true)
  } else {
    store.commit('global/SET_LAYOUT_FULL_WIDTH', null)
  }

  store.commit('global/SET_ROUTER_LOADING', false)

})

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  if (store.getters['navbar/navbarInitPromise']) {
    await store.getters['navbar/navbarInitPromise']
  }

  store.commit('global/SET_ROUTER_LOADING', true)

  /* we need to wait for the initial setting of the current route
   * this is kind of the new "entry point".
   * Previously dispatchActionForAllModules was called on store initialization
   * which happened sometimes before the current route could initially be set.
   */

  // Special case for privacy policy where consent modal cannot be shown
  if (['dataProtectionLandingPage', 'dataProtection', 'imprint', 'brand.terms_of_use'].includes(routeTo.name)) {
    document.querySelector('body').classList.add('klaro-privacy-policy-exception')
  } else {
    document.querySelector('body').classList.remove('klaro-privacy-policy-exception')
  }

  let initAlreadyValidated = false

  if (routeTo?.name && !routeFrom?.name && !store.getters['global/initDone']) {
    store.commit('navbar/SET_CURRENT_ROUTE', routeTo)

    await dispatchActionForAllModules('init')

    initAlreadyValidated = true

    store.commit('global/SET_INIT_DONE', true)

  }

  if (
    routeTo?.name
    && routeFrom?.name
    && !routeTo?.name.includes('documents.detail')
    && routeFrom?.name.includes('documents.detail')
    && store.getters['navbar/showGuideChecklistDrawer']
  ) {
    store.dispatch('navbar/setShowGuideChecklistDrawer', false)
  }

  /* // special case where we need to wait for document detail to be destroyed before a new instance is loaded
  if (
    routeTo?.name
    && routeFrom?.name
    && routeTo?.name.includes('documents.detail')
    && routeFrom?.name.includes('documents.detail')
  ) {
    // console.log('hmm', routeTo)

    const findComponentRecursive = (vueComponent) => {
      if (vueComponent.$vnode?.tag && vueComponent.$vnode.tag.includes('DocumentDetail')) {
        // console.log(vueComponent.$vnode.tag)
        return vueComponent
      }

      if (vueComponent.$children) {
        const matchedChild = vueComponent.$children.find(child => {
          return findComponentRecursive(child)
        })

        if (matchedChild) {
          return matchedChild
        }
      }

      return false
    }

    const matchedComponent = findComponentRecursive(router.app)
    console.log('matchedComponent', matchedComponent, 'awaiting destroy')
    if (matchedComponent) {
      await new Promise((resolve) => {
        matchedComponent.$once('hook:destroyed', () => {
          console.log('destroyed')
          resolve()
        })

        matchedComponent.$destroy()

      })
      console.log('after await')
    }

  } */

  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)
  const adminRequired = routeTo.matched.some((route) => route.meta.adminRequired)
  const managerRequired = routeTo.matched.some((route) => route.meta.managerRequired)
  const primaryInstanceRequired = routeTo.matched.some(
    (route) => route.meta.primaryInstanceRequired
  )

  if (primaryInstanceRequired && !window.primary_instance) {
    return next(routeFrom)
  }

  // If auth isn't required for the route, just continue.
  if (!authRequired) {
    return next()
  }

  // If auth is required and the user is logged in...
  if (store.getters['auth/loggedIn']) {
    // Validate the local user token...
    let validUser

    if (!store.getters['auth/currentUser']) {
      validUser = await store.dispatch('auth/validate', { routeTo })

    } else {
      validUser = store.getters['auth/currentUser']

      // validate async
      if (!initAlreadyValidated) {
        store.dispatch('auth/validate', { routeTo })
      }
    }
    // Then continue if the token still represents a valid user,
    // otherwise redirect to login.
    if (validUser) {
      if (routeFrom.name && routeFrom.name.indexOf('documents.detail') !== -1) {
        store.dispatch('syncQueue/handleQueueNow')
      }

      // check company permissions
      if (!store.getters['auth/isAdmin']) {
        const userPermissionRoleTypeRequiredMatchedRoute = routeTo.matched.find(
          (route) => route.meta.userPermissionRoleTypeRequired
        )
        if (userPermissionRoleTypeRequiredMatchedRoute) {
          const userPermissionRoleTypeRequired = userPermissionRoleTypeRequiredMatchedRoute.meta.userPermissionRoleTypeRequired

          const hasUserPermission = !!PermissionRoleUserAssociation.allFast()
          .filter(prua => prua.user_id === validUser.id)
          .find((prua) => {
            const pr = PermissionRole.allFast().find(pr => pr.id === prua.permission_role_id)

            return pr.permission_role_type === userPermissionRoleTypeRequired
          })


          if (!hasUserPermission) {
            store.dispatch('global/setShowUnauthorizedError', true)
            return next({ name: 'profile.dashboard' })

          }

        }

        const matchedRoute = routeTo.matched.find(
          (route) => route.meta.permissionRoleTypeRequired
            || route.meta.trainingAdminRequired
            || route.meta.documentsAdminRequired
        )
        if (matchedRoute) {
          if (!routeTo.params?.companyId) {
            console.error('No company_id in route with permissionRoleTypeRequired', routeTo.name)
          }

          const companyId = parseInt(routeTo.params.companyId)
          const permissionRoleTypeRequired = matchedRoute.meta.permissionRoleTypeRequired
          const trainingAdminRequired = matchedRoute.meta.trainingAdminRequired
          const documentsAdminRequired = matchedRoute.meta.documentsAdminRequired

          const myCompanyRole = CompanyRole.allFast().find(
            (cr) => cr.company_id === companyId && cr.user_id === validUser.id
          )

          if (permissionRoleTypeRequired) {
            if (!myCompanyRole) {
              store.dispatch('global/setShowUnauthorizedError', true)
              return next({ name: 'profile.dashboard' })

            } else if (
              myCompanyRole.permission_role_types.indexOf(permissionRoleTypeRequired) === -1
            ) {
              store.dispatch('global/setShowUnauthorizedError', true)
              store.dispatch('global/setUnauthorizedPermissionRole', true)
              return next({ name: 'profile.dashboard' })
            }
          }

          if (
            trainingAdminRequired && (
              !myCompanyRole
              || !myCompanyRole.is_training_admin
            )
          ) {
              store.dispatch('global/setShowUnauthorizedError', true)
              return next({ name: 'profile.dashboard' })

          }

          if (
            documentsAdminRequired && (
              !myCompanyRole
              || !myCompanyRole.is_documents_admin
            )
          ) {
              store.dispatch('global/setShowUnauthorizedError', true)
              return next({ name: 'profile.dashboard' })

          }

        }
      }

      if (routeTo.params?.companyId && !store.getters['navbar/selectedCompany']) {
        let company = Company.find(routeTo.params.companyId)

        if (
          validUser && (
            !company ||
            !CompanyRole.allFast().find(cr => cr.company_id === company.id && cr.user_id === validUser.id)
          )
        ) {
          // this is important for admins/resellers to get all company data (e.g. custom colors) which is usually sent via company roles
          const r = await Company.$find(routeTo.params.companyId)
          company = r.entities.companies.find(c => c.id === r.response.data.id)
        }

        await store.dispatch('navbar/setSelectedCompany', {
          selectedCompany: company,
          dontRedirect: true,
          skipSetNavTreeItems: true,
        })
      }

      if (routeTo.meta?.redirectWithCompanyId) {
        const firstAvailableCompany = store.getters['navbar/selectedCompany']
          || store.getters['navbar/allCompanies']?.[0]

        if (firstAvailableCompany) {
          const newRouteTo = routeTo.meta.redirectWithCompanyId
          newRouteTo.params = {
            ...(newRouteTo.params || {}),
            companyId: firstAvailableCompany.id,
          }

          if (newRouteTo.name === 'documents.detail.page') {
            const result = await firstAvailableCompany.refreshSubResource(Document)
            const firstDocument = result.response.data.find(d => d.document_blueprint?.id === 1)

            newRouteTo.params.documentId = firstDocument.id
          }

          return next(newRouteTo)
        }
      }

      if (!adminRequired && !managerRequired) {
        return next()
      }

      if (adminRequired && store.getters['auth/isAdmin']) {
        return next()

      } else if (managerRequired && validUser.is_manager) {
        return next()

      } else {
        return next(routeFrom)

      }

    } else {
      redirectToLogin()

    }
  }

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  redirectToLogin()

  function redirectToLogin() {
    // Pass the original route to the login component
    next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
  }
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  store.commit('navbar/SET_CURRENT_ROUTE', routeTo)

  next()
})

export default router
