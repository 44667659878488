  import PlanitModel from './planit-model'
  import TrainingSession from './training-session'

  export default class TrainingSessionInstance extends PlanitModel {
    static fields() {
      return {
        ...super.fields(),

        training_session_id: this.attr(),
        training_session: this.belongsTo(TrainingSession, 'training_session_id'),

        start_date: this.attr(),
        end_date: this.attr(),

      }
    }
}

TrainingSessionInstance.entity = 'training-session-instances'