
import {
  groupBy,
  forEach,
  merge,
  debounce,
  delay,
  cloneDeep,
} from 'lodash'

import PQueue from 'p-queue'

import { Document } from '@state/models/document'
import store from '@state/store'

import { emitter } from '@utils/global-events'


export const state = {
  syncQueue: [],
  loading: false,
  paused: false,
  showQueueInfoText: false,

  debounceFns: [],
}

export const mutations = {
  QUEUE(state, { model, method, data, params, callback, args, groupSaveModel, overrideExistingQueueItem = false, errorCallback }) {
    // console.log('QQ', ...data.data.items.map(i => i.custom_text))
    // console.log('Q', model.id, groupSaveModel?.id, overrideExistingQueueItem)
    const existingQueueItem = state.syncQueue.find((qi) => {
      if (groupSaveModel) {
        return (
          qi.model.id === model.id &&
          qi.model.constructor.entity === model.constructor.entity &&
          qi.method === method &&
          qi.groupSaveModel &&
          qi.groupSaveModel.id === groupSaveModel.id &&
          qi.groupSaveModel.constructor.entity === groupSaveModel.constructor.entity
        )
      } else {
        return (
          qi.model.id === model.id &&
          qi.model.constructor.entity === model.constructor.entity &&
          qi.method === method &&
          !qi.groupSaveModel
        )
      }
    })

    data = cloneDeep(data)

    if (existingQueueItem) {
      // Overwrite items as they will always be up-to-date
      if (data.data && data.data.items && data.data.items.length) {
        if (!existingQueueItem.data.data) {
          existingQueueItem.data.data = {}
        }
        existingQueueItem.data.data.items = [...data.data.items]
        delete data.data.items
      }

      if (overrideExistingQueueItem) {
        existingQueueItem.data = data

      } else {
        merge(existingQueueItem.data, data)

      }

    } else {
      state.syncQueue.push({
        model,
        method,
        data,
        params,
        callback,
        args,
        groupSaveModel,
        errorCallback,
      })

    }
  },
  REMOVE(state, value) {
    state.syncQueue.splice(state.syncQueue.indexOf(value), 1)
  },
  CLEAR_QUEUE(state) {
    state.syncQueue = []
  },
  REPLACE_MODEL(state, { oldModel, newModel, groupSaveModel }) {
    if (!newModel) {
      return
    }

    if (!groupSaveModel) {
      state.syncQueue
        .filter(
          (qi) =>
            qi.model.id === oldModel.id &&
            qi.model.constructor.entity === oldModel.constructor.entity
        )
        .forEach((qi) => {
          qi.model = newModel
        })

      state.syncQueue
        .filter(
          (qi) =>
            qi.groupSaveModel &&
            qi.groupSaveModel.id === oldModel.id &&
            qi.groupSaveModel.constructor.entity === oldModel.constructor.entity
        )
        .forEach((qi) => {
          qi.groupSaveModel = newModel
        })
    } else {
      console.warn('Not implemented')
    }
  },
  LOADING(state, loading) {
    state.loading = loading
  },
  SET_PAUSED(state, paused) {
    state.paused = paused
  },
  SET_SHOW_QUEUE_INFO_TEXT(state, showQueueInfoText) {
    state.showQueueInfoText = showQueueInfoText
  },
  REGISTER_DEBOUNCE_FN(state, { debounceFn }) {
    state.debounceFns.push(debounceFn)
  },
  UNREGISTER_DEBOUNCE_FN(state, { debounceFn }) {
    state.debounceFns = state.debounceFns.filter((fn) => fn !== debounceFn)
  },
}

export const getters = {
  getQueue(state) {
    return state.syncQueue
  },
  nextItem(state, index) {
    return state.syncQueue[0]
  },
  queueLength(state) {
    return state.syncQueue.length
  },
  isLoading(state) {
    return state.loading
  },
  isPaused(state) {
    return state.paused
  },
  groupSaveQueueItems(state) {
    return state.syncQueue.filter((i) => !!i.groupSaveModel)
  },
  showQueueInfoText(state) {
    return state.showQueueInfoText
  },
  debounceFns(state) {
    return state.debounceFns
  },
}

export const actions = {
  init({ state, dispatch, getters, commit }) {
    const saveListener = function(e) {
      if (e.key === 's' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault()
        syncNow(getters, commit, false, dispatch)
      }
    }

    document.addEventListener('keydown', saveListener)
  },

  queue({ commit, dispatch, getters }, data = {}) {
    commit('QUEUE', data)
    dispatch('sync')
  },

  async queueNow({ commit, dispatch, getters }, data = {}) {
    commit('QUEUE', data)
    return syncNow(getters, commit, false, dispatch)
  },

  sync({ commit, dispatch, getters }) {
    syncDebounced.cancel()

    window.onbeforeunload = unloadhandler
    if (!getters.isPaused && store.getters['auth/currentUser']?.enable_autosave) {
      return syncDebounced(getters, commit, false, dispatch)
    }
  },

  cancel() {
    syncDebounced.cancel()
  },

  pause({ commit, dispatch, getters }) {
    syncDebounced.cancel()

    if (getters.queueLength > 0) {
      window.onbeforeunload = unloadhandler
    }

    commit('SET_PAUSED', true)
  },

  unpause({ commit, dispatch }) {
    commit('SET_PAUSED', false)

    dispatch('sync')
  },

  replaceModel({ commit }, { oldModel, newModel, groupSaveModel }) {
    commit('REPLACE_MODEL', { oldModel, newModel, groupSaveModel })
  },

  handleQueue({ commit, getters, dispatch }) {
    return syncDebouncedShort(getters, commit, false, dispatch)
  },

  async handleQueueNow({ commit, getters, dispatch }, options) {
    const skipLoading = options ? options.skipLoading : false
    return syncNow(getters, commit, skipLoading, dispatch)
  },

  showQueueInfoTextForDuration({ commit }, { duration } = { duration: 1000 }) {
    commit('SET_SHOW_QUEUE_INFO_TEXT', true)
    delay(() => {
      commit('SET_SHOW_QUEUE_INFO_TEXT', false)
    }, duration)
  },

  registerDebounce({ commit }, { debounceFn }) {
    commit('REGISTER_DEBOUNCE_FN', { debounceFn })
  },

  async flushDebounceFns({ state }) {
    await Promise.allSettled(
      state.debounceFns.map((debounceFn) => {
        return debounceFn.flush()
      })
    )
  },
}

const pQueue = new PQueue({ concurrency: 1 })

const syncNow = async (getters, commit, skipLoading, dispatch) => {
  /* console.log({
    getters,
    commit,
    skipLoading,
    dispatch,
  }) */
  await dispatch('flushDebounceFns')

  if (!skipLoading) {
    commit('LOADING', true)
  }

  await pQueue.onIdle()

  while (getters.groupSaveQueueItems.length) {
    const groupedSaveQueueItems = groupBy(
      getters.groupSaveQueueItems,
      (gsqi) => gsqi.groupSaveModel.constructor.entity + gsqi.groupSaveModel.id
    )

    for (const i in groupedSaveQueueItems) {
      const items = groupedSaveQueueItems[i]

      const groupSaveModel = items[0].groupSaveModel
      const data = {}
      const callbacks = items.filter((i) => !!i.callback).map((i) => i.callback)
      const errorCallbacks = items.filter((i) => !!i.errorCallback).map((i) => i.errorCallback)

      if (groupSaveModel.constructor.entity === 'document-versions') {
        data.answers = items.map((i) => {
          return { ...i.data, viid: i.model.viid }
        })
      } else if (groupSaveModel.constructor.entity === 'document-blueprint-versions') {
        data.sections = items.map((i) => {
          return { ...i.data, viid: i.model.viid }
        })
      }

      await pQueue.add(() => {
        forEach(items, (i) => {
          commit('REMOVE', i)
        })

        const config = {
          save: true,
          params: {},
        }
        forEach(items, (i) => {
          if (i.params) {
            merge(config.params, i.params)
          }
        })

        if (config.params.include_answer_repository_reference_items) {
          config.save = false
        }

        return groupSaveModel.sync(null, data, config).then(
          (result) => {
            let rriData = {}
            if (config.params.include_answer_repository_reference_items) {
              result.response.data.answers.forEach((a) => {
                if (a.repository_reference_item) {
                  rriData[a.viid] = a.repository_reference_item
                  delete a.repository_reference_item
                }
              })
            }

            const p = result.isSaved ? Promise.resolve() : result.save()

            return p.then(() => {
              const r = result
              const promises = []

              if (groupSaveModel.constructor.entity === 'document-versions') {
                const currentDocument = store.getters['currentDocument/document']
                const savedDocumentId = r.response.data.document_id || r.response.data.is_edit_document_version_of_id

                if (currentDocument && currentDocument.id === savedDocumentId) {
                  if (r.response.data.answers) {
                    r.response.data.answers.forEach((answerData) => {
                      const answer = r.entities.answers.find((a) => a.id === answerData.id)
                      store.commit('currentDocument/UPDATE_ANSWER', { answer, documentVersion: groupSaveModel.version })

                    })
                  }

                  if (r.response.data.answer_section_select_group_option_result_user_data) {
                    r.response.data.answer_section_select_group_option_result_user_data.forEach((answerSectionSelectGroupOptionResultUserDataData) => {
                      const answerSectionSelectGroupOptionResultUserData = r.entities.answer_section_select_group_option_result_user_data.find(
                        (a) => a.id === answerSectionSelectGroupOptionResultUserDataData.id
                      )
                      store.commit(
                        'currentDocument/UPDATE_ANSWER_SECTION_SELECT_GROUP_OPTION_RESULT_USER_DATA', {
                          answerSectionSelectGroupOptionResultUserData,
                          documentVersion: groupSaveModel.version,
                        }
                      )

                    })
                  }
                }

                // requery rri or emit task requery
                if (r.response.data.answers) {
                  r.response.data.answers.forEach((answerData) => {
                    const answer = r.entities.answers.find((a) => a.id === answerData.id)

                    if (Object.keys(rriData).includes(answerData.viid)) {
                      promises.push(
                        answer.queryRepositoryReferenceItems({
                          data: rriData[answerData.viid],
                          documentVersion: groupSaveModel,
                          save: true,
                        })
                      )
                    }

                    if (answer.data?.task_id) {
                      emitter.emit('queryTask', answer.data.task_id)
                    }
                  })
                }

              } else if (groupSaveModel.constructor.entity === 'document-blueprint-versions') {
                if (groupSaveModel.id === store.getters['blueprint/documentBlueprintVersion']?.id) {
                  store.dispatch('blueprint/setLastEditedByUser', store.getters['auth/currentUser'])

                  r.response.data.sections.forEach((sectionData) => {
                    const section = r.entities.sections.find((s) => s.id === sectionData.id)
                    store.commit('blueprint/UPDATE_SECTION', { section, blueprintVersion: groupSaveModel.version })

                  })

                } else {
                  console.log('skipping update section because different blueprint version')

                }
              }

              return Promise.all(promises)
            })

          },
          (result) => {
            if (result.response?.data?.message) {
              const message = result.response.data.message
              emitter.emit('showGlobalAlert', { type: 'error', message })
            }
            errorCallbacks.forEach((c) => {
              c()
            })
          }
        )
      })

      callbacks.forEach((c) => {
        c()
      })
    }
  }

  let ql = getters.queueLength

  while (ql > 0) {
    await pQueue.add(() => {
      const qi = getters.nextItem
      const config = {}
      if (!qi) {
        console.log('WAT NO QI:', qi, pQueue, getters.queueLength)
        return
      }

      config.params = qi.params
      const args = qi.args || []

      return qi.model.sync(null, qi.data, config, null, ...args).then(
        async (resp) => {
          const model = qi.model

          commit('REMOVE', qi)

          if (model.constructor.entity === 'select-groups') {
            const documentBlueprintVersion = store.getters['blueprint/documentBlueprintVersion']
            for (const selectGroup of resp.entities['select-groups']) {
              store.commit('blueprint/UPDATE_SELECT_GROUP', {
                selectGroup,
                blueprintVersion: documentBlueprintVersion.version,
              })
            }

          } else if (model.constructor.entity === 'select-group-options') {
            const documentBlueprintVersion = store.getters['blueprint/documentBlueprintVersion']
            for (const selectGroupOption of resp.entities['select-group-options']) {
              store.commit('blueprint/UPDATE_SELECT_GROUP_OPTION', {
                selectGroupOption,
                blueprintVersion: documentBlueprintVersion.version,
              })
            }

          } else if (model.constructor.entity === 'section-select-group-option-results') {
            const documentBlueprintVersion = store.getters['blueprint/documentBlueprintVersion']
            for (const sectionSelectGroupOptionResult of resp.entities['section-select-group-option-results']) {
              store.commit('blueprint/UPDATE_SECTION_SELECT_GROUP_OPTION_RESULT', {
                sectionSelectGroupOptionResult,
                blueprintVersion: documentBlueprintVersion.version,
              })
            }

          } else if (model.constructor.entity === 'conditions') {
            const promises = []

            await Promise.all(promises)
          }

          if (qi.callback) {
            qi.callback(resp)
          }
          return resp

        }, (result) => {
          commit('REMOVE', qi)
          let error = null

          if (result?.response?.status === 400 && result?.response?.data?.message) {
            error = result.response.data.message
            emitter.emit('showGlobalAlert', { type: 'error', message: error })
          }

          if (qi.callback) {
            qi.callback(result)

          }

          if (qi.errorCallback) {
            qi.errorCallback(result)
          }

          return result

        }
      )
    })

    ql = getters.queueLength
  }

  window.onbeforeunload = null

  if (!skipLoading) {
    commit('LOADING', false)
  }

  commit('SET_SHOW_QUEUE_INFO_TEXT', true)
  delay(() => {
    commit('SET_SHOW_QUEUE_INFO_TEXT', false)
  }, 5000)
}

const syncDebounced = debounce(syncNow, 5000)
const syncDebouncedShort = debounce(syncNow, 200)

const unloadhandler = () => {
  if (!state.paused) {
    return confirm('You have unsaved content. Continue closing?')
  }
}
