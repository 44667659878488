import { createVuetify } from 'vuetify'

// import DateFnsAdapter from '@date-io/date-fns'
// import deDateLocale from 'date-fns/locale/de'

import '@fortawesome/fontawesome-pro/css/all.css' // Ensure you are using css-loader

import de from 'vuetify/lib/locale/de'

import { aliases, fa } from 'vuetify/iconsets/fa'
import { mdi } from 'vuetify/iconsets/mdi'

import { VTreeview } from 'vuetify/labs/VTreeview'
import { VStepperVertical, VStepperVerticalItem } from 'vuetify/labs/VStepperVertical'
import { VTimePicker } from 'vuetify/labs/VTimePicker'
import { VDateInput } from 'vuetify/labs/VDateInput'

import defaultThemes from './default-themes'
import '@src/sass/styles.scss'


const vuetify = createVuetify({
  components: {
    VTreeview,
    VStepperVertical,
    VStepperVerticalItem,
    VTimePicker,
    VDateInput,
  },
  display: {
    thresholds: {
      xs: 768,
      sm: 960,
      md: 1424,
      lg: 1904,
    },
    mobileBreakpoint: 'xs',
  },
  theme: {
    options: {
      customProperties: true,
    },
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: defaultThemes.light.colors,
      },
      dark: {
        dark: true,
        colors: defaultThemes.dark.colors,
      },
    },
    variations: {
      colors: ['primary', 'error', 'success', 'warning', 'info', 'primaryDesaturated'],
      lighten: 5,
      darken: 5,
    },
  },
  icons: {
    defaultSet: 'fa',
    aliases: {
      ...aliases,
      angleDown: 'fas fa-angle-down',
      angleRight: 'fas fa-angle-right',
    },
    sets: {
      mdi,
      fa,
    },
  },
  locale: {
    locale: window.lang || 'en',
    fallback: 'en',
    messages: { de },
  },
  /* date: {
    adapter: DateFnsAdapter,
    locale: {
      de: deDateLocale,
    },
  }, */
})

export default vuetify
