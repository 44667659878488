<script>
export default {
  props: {
    changelog: { required: true },
    currentLocale: { type: String, required: true },
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      showModal: true,
      noMoreUpdates: false,
    };
  },
  methods: {
    closeModal() {
      this.user.show_changelog_notification = !this.noMoreUpdates
      this.user.sync(['show_changelog_notification'])
      this.showModal = false

    },
  },
  watch: {
    showModal(val) {
      if (!val) {
        this.$emit('close')
      }
    },
  },
  created() {
    if (this.user.last_seen_changelog_id === this.changelog.id) {
      this.showModal = false
      return
    }
    this.user.last_seen_changelog_id = this.changelog.id
    this.user.sync(['last_seen_changelog_id'])
  },
};
</script>


<template>
  <PlanitDialog
    v-model="showModal"
    max-width="80rem"
    content-class="bg-white"
    :fullscreen="$vuetify.display.mobile"
  >
    <div class="d-flex flex-column pa-6" style="height: 100%; max-height: 60rem;">
      <div class="d-flex align-center justify-space-between mb-2 mb-sm-4">
        <h1>{{ changelog.getTitle(currentLocale) }}</h1>
        <PlanitButton
          variant="text"
          class="icon-btn"
          @click="closeModal"
        >
          <PlanitIcon icon="fal fa-times"></PlanitIcon>

        </PlanitButton>
      </div>

      <div class="markdown-container flex-grow-1" v-html="changelog.getText(currentLocale)" style="overflow-y: auto;">
      </div>
      <div class="d-flex justify-space-between mt-2 mt-sm-4">
        <div class="d-flex align-center">
          <PlanitCheckbox
            v-model="noMoreUpdates"
            :label="$t('changelog.modal_checkbox')"
          />
        </div>
        <PlanitButton
          color="primary"
          @click="closeModal"
        >
          <span>{{ $t('generic.close') }}</span>
        </PlanitButton>
      </div>
    </div>
  </PlanitDialog>
</template>

