import PlanitModel from './planit-model'
import { Document } from './document'
import Company from './company'

export default class CompanyDocumentTemplateAllotment extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      company: this.belongsTo(Company, 'company_id'),
      company_id: this.attr(),

      document: this.belongsTo(Document, 'document_id'),
      document_id: this.attr(),
    }
  }
}

CompanyDocumentTemplateAllotment.entity = 'company-document-template-allotments'
