import PlanitModel from './planit-model'
import Reminder from './reminder'
import TimerCompanyRoleAssociation from './timer-company-role-association'

export default class Timer extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      start_timestamp: this.attr(),
      end_timestamp: this.attr(),
      type: this.attr(),
      enabled: this.attr(),
      last_triggered: this.attr(),
      cron_expression: this.attr(),
      name: this.attr(),
      user_id: this.attr(),

      reminder_id: this.attr(),
      reminder: this.belongsTo(Reminder, 'reminder_id'),

      timer_company_role_associations: this.hasMany(TimerCompanyRoleAssociation, 'timer_id'),
    }
  }

}

Timer.entity = 'timers'
