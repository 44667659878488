import PlanitModel from '@state/models/planit-model'
import DocsAuditProject from '@state/models/audit/docs-audit-project'
import User from '@state/models/user'

export default class DocsAuditProjectQuestion extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      docs_audit_project: this.belongsTo(DocsAuditProject, 'docs_audit_project_id'),
      docs_audit_project_id: this.attr(),

      responsible_user: this.belongsTo(User, 'responsible_user_id'),
      responsible_user_id: this.attr(),

      project_question_uuid: this.attr(),

      todo_status: this.attr(),
      audit_priority: this.attr(),
      audit_status: this.attr(),
      responsible_text: this.attr(),
    }
  }

  /* constructor() {
        super()

        console.log('hmmm', this.responsible_user_id)
        if (this.responsible_user_id) {
            this.responsible = { value: this.responsible_user_id, text: this.responsible_user.email }
        } else {
            this.responsible = { value: -1, text: this.responsible_text }
        }
    } */
}

DocsAuditProjectQuestion.entity = 'audit/docs-audit-project-questions'
